// import node module libraries
import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row, Card, Form, Button, Image, Spinner } from "react-bootstrap";

// import media files
import Logo from "assets/images/brand/logo/logo.png";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { resendLinkAction } from "actions/auth";
import { useSelector } from "react-redux";

const MailConfirmation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);
  const email = localStorage.getItem("email");
  const handleResendLink = (e) => {
    e.preventDefault();
    dispatch(resendLinkAction(email));
  };

  return (
    <Fragment>
      <Row className="align-items-center justify-content-center g-0 min-vh-100">
        <Col lg={5} md={5} className="py-8 py-xl-0">
          <Card>
            <Card.Body className="p-6">
              <div className="mb-4">
                <Link to="/">
                  <Image
                    src={Logo}
                    style={{ maxWidth: "120px" }}
                    className="mb-4"
                    alt=""
                  />
                </Link>
                <h1 className="mb-1 fw-bold">Confirmation Link Sent</h1>
              </div>

              <Col lg={12} md={12} className="mb-0 d-grid gap-2">
                <p>
                  Thank you for signing up with IRead.tn! We've just sent a
                  confirmation link to the email address you provided during
                  registration.
                </p>
                {/* Button */}
                <Button
                  variant="primary"
                  type="submit"
                  onClick={(e) => handleResendLink(e)}
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner
                      animation="border"
                      variant="light"
                      className="me-2"
                      size="sm"
                    />
                  ) : (
                    "  Resend link"
                  )}
                </Button>

                <Button
                  variant="primary"
                  type="submit"
                  onClick={() => navigate("/authentication/sign-in")}
                >
                  Back to Login
                </Button>
              </Col>
              <hr className="my-4" />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </Fragment>
  );
};

export default MailConfirmation;
