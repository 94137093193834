// import node module libraries
import { Link } from "react-router-dom";
import { Card, Image } from "react-bootstrap";
//translate component
import {  useTranslation } from 'react-i18next';

 
const QuizEssayResult = () => {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Body className="text-center" style={{ paddingTop: "100px" }}>
        <div className="mb-4">
          <h2>
            {" "}
            {t("congratulations")}
          </h2>
          <p className="mb-0 px-lg-14">
            {t("you_doing_good")}
          </p>
        </div>
        <div
          className="mt-5"
          style={{ paddingTop: "70px", marginBottom: "140px" }}
        >
          <Link
            to="/student/dashboard"
            className="btn btn-primary"
          >
           {t("my_dashboard")}
          </Link>
        </div>
      </Card.Body>
    </Card>
  );
};

export default QuizEssayResult;
