
export const cefrData =[
    {
        level:"A0",
        title:"a0_title",
        subTitle:"a0_subtitle",
        desc :"a0_desc"
    },
    {
        level:"A1",
        title:"a1_title",
        subTitle:"a1_subtitle",
        desc :"a1_desc"
    },
    {
        level:"A2",
        title:"a2_title",
        subTitle:"a2_subtitle",
        desc :"a2_desc"
    },
    {
        level:"B1",
        title:"b1_title",
        subTitle:"b1_subtitle",
        desc :"b1_desc"
    },
    {
        level:"B2",
        title:"b2_title",
        subTitle:"b2_subtitle",
        desc :"b2_desc"
    },
    {
        level:"C1",
        title:"c1_title",
        subTitle:"c1_suctitle",
        desc :"c1_desc"
    },
    {
        level:"C2",
        title:"c2_title",
        subTitle:"c2_suctitle",
        desc :"c2_desc"
    }
]