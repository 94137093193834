// import node module libraries
import React, { Fragment, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Row, Col, Container, Nav, Navbar } from "react-bootstrap";

// import custom components
import ProfileCover from "components/marketing/common/headers/ProfileCover";

// import routes file
import {
  DashboardMenu,
  AccountSettingsMenu,
} from "routes/marketing/InstructorDashboard";

// import media files
import Avatar1 from "assets/images/avatar/avatar-1.jpg";
import BookCover from "../common/headers/BookCover";
//translate component
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { fetchSessionAction } from "actions/sessions";
import { useSelector } from "react-redux";


const BookLayout = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const {session} = useSelector((state) => state.packs);
	const searchParams = new URLSearchParams(location.search);
	const sessionID= searchParams.get("session_id");
  const itemData = location?.state?.quizData || [];
  useEffect(() => {
   dispatch(fetchSessionAction(sessionID))
  }, [dispatch, sessionID])
  const modifiedDashboardMenu = session?.location === 'online'
  ? DashboardMenu 
  : DashboardMenu.filter(item => item.title !== 'Online session');
  return (
    <Fragment>
      <section className="pt-5 pb-5">
        <Container>
          {/* User info */}
          <BookCover />

          {/* Content */}
          <Row className="mt-0 mt-md-4">
            <Col lg={3} md={4} sm={12}>
              <Navbar
                expand="lg"
                className="navbar navbar-expand-md navbar-light shadow-sm mb-4 mb-lg-0 sidenav"
              >
                <Link
                  className="d-xl-none d-lg-none d-md-none text-inherit fw-bold fs-5 float-start py-1"
                  to="#"
                >

                </Link>
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  className="p-0 focus-none border-0"
                  label="Responsive Menu"
                >
                  <span
                    className="navbar-toggler d-md-none icon-shape icon-sm rounded bg-primary p-0 text-white float-end"
                    data-bs-toggle="collapse"
                    data-bs-target="#sidenav"
                    aria-controls="sidenav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="fe fe-menu"></span>
                  </span>
                </Navbar.Toggle>

                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto flex-column" as="ul">
                    <Nav.Item className="navbar-header" as="li">
                    </Nav.Item>
                    {modifiedDashboardMenu.map((item, index) => (
                      <Nav.Item
                        as="li"
                        key={index}
                        className={`${item.link === location.pathname ? "active" : ""
                          }`}
                      >
                        <Link
                          className="nav-link"
                          to={
                            item.link === "/student/student-books/"
                              ? item.link
                              : { pathname: item.link + id ,search: "?session_id=" + sessionID, state: { quizData: itemData } }
                          }
                        >
                          <i className={`fe fe-${item.icon} nav-icon`}></i>
                          {t(item.title)}
                        </Link>
                      </Nav.Item>
                    ))}
                    {/* <Nav.Item className="navbar-header mt-4" as="li">
                      ACCOUNT SETTINGS
                    </Nav.Item> */}
                    {/* {AccountSettingsMenu.map((item, index) => (
                      <Nav.Item
                        as="li"
                        key={index}
                        className={`${
                          item.link === location.pathname ? "active" : ""
                        }`}
                      >
                        <Link className="nav-link" to={item.link}>
                          <i className={`fe fe-${item.icon} nav-icon`}></i>
                          {item.title}
                        </Link>
                      </Nav.Item>
                    ))} */}
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Col>

            <Col lg={9} md={8} sm={12}>
              {props.children}
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};
export default BookLayout;



