import { deleteImage } from "api";
import { getPublicIdFromUrl } from "helper/utils";
import React, { useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
//translate component
import {  useTranslation } from 'react-i18next';


function CloudinaryUploadWidget({ setImage }) {
  const { t } = useTranslation();
  const cloudinaryRef = useRef();
  const widgetRef = useRef();
  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;

    widgetRef.current = cloudinaryRef.current.createUploadWidget(
      {
        cloudName: "dtwkikmuy",
        uploadPreset: "wuraurta",
      },
      (error, result) => {
        if (result.info.secure_url) {
          setImage(result.info.secure_url);
        }
      }
    );
  }, []);

  const publicId = getPublicIdFromUrl(
    "https://res.cloudinary.com/dtwkikmuy/image/upload/v1691521165/tgkicnouq4imycybikub.png"
  );
  const handleDeleteImage = () => {
    setImage(
      "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png"
    );
  };
  return (
    <div>
      <Button
        variant="outline-secondary"
        size="sm"
        onClick={() => widgetRef.current.open()}
        style={{ marginRight: "20px" }}
      >
        {t("update_btn")}
      </Button>
      <Button
        variant="outline-danger"
        size="sm"
        onClick={(e) => {
          handleDeleteImage();
        }}
      >
        {t("delete_btn")}
      </Button>
    </div>
  );
}

export default CloudinaryUploadWidget;
