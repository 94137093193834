import {
  getAllBooksInPack,
  getAllPackDetails,
  getAllPacks,
  getPacksByShcool,
} from "api";
import {
  CLEAR_SELECTED,
  GET_ALL_PAKCS,
  GET_BOOKS_IN_PACK,
  GET_PACK_DETAILS,
  LOADING,
  SELECT_PACK,
} from "./constants";

export const fetchAllPacksAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await getAllPacks();
    dispatch({ type: GET_ALL_PAKCS, payload: data?.data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
export const fetchPacksBySchoolAction = (school) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await getPacksByShcool(school);

    dispatch({ type: GET_ALL_PAKCS, payload: data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
export const fetchAllPackDetailsAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await getAllPackDetails(id);

    dispatch({ type: GET_PACK_DETAILS, payload: data?.data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
export const fetchBooksInPackAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const data = await getAllBooksInPack(id);

    dispatch({ type: GET_BOOKS_IN_PACK, payload: data?.data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
    console.log(error);
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};

export const selectPackAction = (selected, navigate) => async (dispatch) => {
  dispatch({ type: SELECT_PACK, payload: selected });

  navigate("/reading-packs");
};

export const clearSelectPackAction = () => async (dispatch) => {
  dispatch({ type: CLEAR_SELECTED });
};
