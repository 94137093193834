// import node module libraries
import React, { useMemo, Fragment } from "react";
import { Link } from "react-router-dom";

import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import {
  Card,
  Row,
  Col,
  Image,
  Badge,
  Table,
  ListGroup,
} from "react-bootstrap";

// import MDI icons
// import Icon from "@mdi/react";
// import { mdiStar } from "@mdi/js";

// import custom components
import { FormSelect } from "components/elements/form-select/FormSelect";
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
// import LevelIcon from "components/marketing/common/miscellaneous/LevelIcon";

// import profile layout wrapper
import ProfileLayout from "./ProfileLayout";

// import data files

// import HTMLCourse from "assets/images/course/course-html.jpg";
// import utility file

import { useSelector } from "react-redux";
import SubsLayout from "./SubsLayout";

const MySessions = () => {
  const { followed_sessions } = useSelector((state) => state.auth);

  const columns = useMemo(
    () => [
      {
        accessor: "book_img",
        Header: "My Books",
        Cell: ({ value, row }) => {
          {
            return row.original.approved ? (
              <div className="d-lg-flex">
                <div>
                  <Link to={"/instructor/dashboard/" + row.original.book_id}>
                    <Image
                      src={row.original.book_img}
                      alt=""
                      className="rounded img-4by3-lg"
                    />
                  </Link>
                </div>
                <div className="ms-lg-3 mt-2 mt-lg-0">
                  <h4 className="mb-1 h5">
                    <Link
                      to={"/instructor/dashboard/" + row.original.book_id}
                      className="text-inherit "
                    >
                      {row.original.book_title}
                    </Link>
                  </h4>
                </div>
              </div>
            ) : (
              <div className="d-lg-flex">
                <div>
                  <Link to="#">
                    <Image
                      src={row.original.book_img}
                      alt=""
                      className="rounded img-4by3-lg"
                    />
                  </Link>
                </div>
                <div className="ms-lg-3 mt-2 mt-lg-0">
                  <h4 className="mb-1 h5">
                    <Link to="#" className="text-inherit">
                      {row.original.book_title}
                    </Link>
                  </h4>
                </div>
              </div>
            );
          }
        },
      },
      {
        accessor: "session_name",
        Header: "Session",
        Cell: ({ value }) => {
          return (
            <Fragment>
              <span>{value}</span>
            </Fragment>
          );
        },
      },

      {
        accessor: "location",
        Header: "Location",
        Cell: ({ value }) => {
          return (
            <Fragment>
              <span>{value}</span>
            </Fragment>
          );
        },
      },

      {
        accessor: "approved",
        Header: "Status",
        Cell: ({ value }) => {
          return (
            <Badge bg={`${value ? "success" : "warning"} `}>
              {value ? "Approved" : "Pending"}
            </Badge>
          );
        },
      },
    ],
    []
  );

  const data = useMemo(() => followed_sessions, [followed_sessions]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 6,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { pageIndex, globalFilter } = state;

  const sortby = [
    { value: "Date Created", label: "Date Created" },
    { value: "Newest", label: "Newest" },
    { value: "High Rated", label: "High Rated" },
    { value: "Law Rated", label: "Law Rated" },
    { value: "High Earned", label: "High Earned" },
  ];

  return (
    <ProfileLayout>
      <Card className="border-0">
        <Card.Header>
          <div className="mb-3 mb-lg-0">
            <h3 className="mb-0">My Books</h3>
            <p className="mb-0">My followed book list .</p>
          </div>
        </Card.Header>
        {followed_sessions.length === 0 ? (
            <div className="text-center mt-3" style={{ fontSize: '1.2rem', color: '#555' }}>
              <p>It looks like you haven't subscribed to any sessions yet.</p>
              </div>
          ) : (
            <>
        <Card.Body>
          <Row>
            <Col lg={9} md={7} sm={12} className="mb-lg-0 mb-2">
              <GlobalFilter
                filter={globalFilter}
                setFilter={setGlobalFilter}
                placeholder="Search Your Session"
              />
            </Col>
            {/* <Col lg={3} md={5} sm={12}>
              <FormSelect options={sortby} placeholder="Sort by" />
            </Col> */}
          </Row>
        </Card.Body>
        <Card.Body className="p-0 pb-5">
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Table
                hover
                responsive
                {...getTableProps()}
                className="text-nowrap table-centered"
              >
                <thead className="table-light">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
          {/* Pagination @ Footer */}
          <Pagination
            previousPage={previousPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            gotoPage={gotoPage}
            nextPage={nextPage}
          />
        </Card.Body>
        </>
          )}
      </Card>
    </ProfileLayout>
  );
};

export default MySessions;
