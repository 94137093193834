// import node module libraries
import React, { Fragment } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

// import custom components
import NavbarDefault from "layouts/marketing/navbars/NavbarDefault";
import FooterWithLinks from "layouts/marketing/footers/FooterWithLinks";

function PrivacyPolicy() {
  return (
    <Fragment>
     

      <main>
        {/* pageheader */}
        <section className="py-10 bg-white">
          <Container>
            <Row className="justify-content-center">
              <Col lg={8} md={12} sm={12}>
                {/* text center */}
                <div className="text-center">
                  <h1 className="display-3 fw-bold">
                    Privacy Policy for IRead:
                  </h1>
                  <p className="lead px-8">
                    Welcome to IRead, an educational platform dedicated to
                    encouraging students to read and discuss stories. At IRead,
                    we are committed to safeguarding your privacy and protecting
                    your personal information. This Privacy Policy explains how
                    we collect, use, disclose, and safeguard your data when you
                    use the IRead mobile application and related services.
                    Please take the time to read this Privacy Policy carefully.
                    By accessing or using IRead, you consent to the practices
                    described in this Privacy Policy.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* content */}
        <section className="pb-10 bg-white">
          <Container>
            <Row className="justify-content-center">
              <Col lg={8} md={12} sm={12}>
                {/* date */}
                <div className="py-3 mb-5 border-bottom">
                  <h3>
                    Effective date:{" "}
                    <span className="text-primary">30rd of October, 2023</span>
                  </h3>
                  <h3>
                    Last Updated:{" "}
                    <span className="text-primary">30rd of October, 2023</span>
                  </h3>
                </div>
                {/* heading */}
                <h1 className="fw-bold mb-3">Information We Collect:</h1>
                <h2 className="fw-bold">Personal Information:</h2>
                <p className="fs-4 mb-4">
                  Personal Information: To provide you with a tailored reading
                  experience, we collect certain personal information, including
                  but not limited to:
                </p>
                <ul>
                  <li>Full name</li>
                  <li>Email address</li>
                  <li>Date of birth</li>
                  <li>Profile picture</li>
                  <li>Username</li>
                </ul>

                <div className="mt-5">
                  <h2 className="fw-bold">Usage Information:</h2>
                  <p className="fs-4">
                    We may collect information about how you use the IRead app,
                    such as:
                  </p>
                  <ul>
                    <li>Your activity within the app</li>
                    <li>The stories you read</li>
                    <li>The sessions you join</li>
                    <li>Test and quiz results</li>
                  </ul>
                  <h2 className="fw-bold">Device Information:</h2>
                  <p className="fs-4">
                    We collect information about the devices you use to access
                    IRead, including:
                  </p>
                  <ul>
                    <li>Device type</li>
                    <li>Operating system</li>
                    <li>Device ID</li>
                    <li>IP address</li>
                  </ul>
                  <h1 className="fw-bold mb-3">How We Use Your Information:</h1>
                  <p>We use your information for the following purposes</p>

                  <h2 className="fw-bold">Personalization:</h2>
                  <p className="fs-4">
                    To tailor the app's content and features to your reading
                    preferences and learning needs.
                  </p>
                  {/* list */}
                </div>
                {/* para */}
                <div className="mt-5">
                  <h2 className="fw-bold">Communication:</h2>
                  <p className="fs-4">
                    To send you important updates, notifications, and
                    account-related information.
                  </p>

                  <h2 className="fw-bold">Analysis:</h2>
                  <p className="fs-4">
                    To analyze user trends, app performance, and user
                    engagement, allowing us to improve IRead.
                  </p>
                  <h2 className="fw-bold"> Support:</h2>
                  <p className="fs-4">
                    To provide you with customer support and address any issues
                    or inquiries you may have.
                  </p>
                  <h1>Disclosure of Your Information</h1>
                  <p>
                    We do not sell, trade, or otherwise transfer your personal
                    information to third parties. Your information may be shared
                    under the following circumstances:
                  </p>
                  <h2 className="fw-bold"> Administrators:</h2>
                  <p className="fs-4">
                    To verify accounts, administer story packs, and ensure
                    proper usage.
                  </p>

                  <h2 className="fw-bold"> Service Providers:</h2>
                  <p className="fs-4">
                    We may share your information with trusted service providers
                    who help us with app functionality, hosting, and
                    maintenance.
                  </p>

                  <h2 className="fw-bold"> Legal Obligations:</h2>
                  <p className="fs-4">
                    We may disclose your information to comply with legal
                    requirements, enforce our policies, respond to legal
                    requests, or protect the rights, property, or safety of
                    IRead, its users, or others.
                  </p>
                </div>
                <div className="mt-5">
                  <h1>Your Choices and Rights</h1>
                  <h2 className="fw-bold">You have the right to:</h2>
                  <p className="fs-4">
                    Access, modify, or delete your personal information.
                    Withdraw your consent to data processing at any time. Opt
                    out of receiving non-essential notifications. Security
                  </p>
                  <p>
                    We take data security seriously and implement reasonable
                    measures to protect your information from unauthorized
                    access, disclosure, alteration, or destruction.
                  </p>
                  <h1>Changes to this Privacy Policy</h1>
                  <p className="fs-4">
                    We may update this Privacy Policy as needed to reflect
                    changes in our practices and the app's features. We will
                    notify you of any significant changes through the app or by
                    other means.
                  </p>

                  <h1 className="fw-bold mb-3">Contact Us</h1>
                  <p>
                    If you have any questions or concerns regarding this Privacy
                    Policy or your personal information, please contact us at:
                  </p>
                  <a href="mailto:contact@intellect.education">
                    contact@intellect.education
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>

  
    </Fragment>
  );
}

export default PrivacyPolicy;
