import {
  GET_ALL_QUIZS,
  GET_FIRST_QUESTION,
  GET_QUIZS_IN_SESSION,
  GET_RESULT_IN_SESSION,
  GET_QUIZ,
  GET_RESULT,
  GET_ALL_RESULT_FOR_ONE_READER,
  GET_USER_DETAILS,
  GET_READER_RESULT,
} from "../actions/constants";

const quizsReducer = (
  state = {
    quizs: [],
    quiz: {},
    complete: {},
    results: [],
    question: {},
    result: {},
    resultsForOneReader:[],
    userDetails: {},
    resultsReader:[],
  },
  action
) => {
  switch (action.type) {
    case GET_ALL_QUIZS:
      return { ...state, quizs: action?.payload };
    case GET_QUIZS_IN_SESSION:
      return { ...state, quizs: action?.payload };
    case GET_FIRST_QUESTION:
      return { ...state, question: action?.payload };
    case GET_RESULT_IN_SESSION:
      return { ...state, results: action?.payload };
    case GET_QUIZ:
      return {
        ...state,
        quiz: action?.payload,
        question: action?.payload.questions,
      };
    case GET_RESULT:
      return {
        ...state,
        result: action?.payload,
      };
    case GET_ALL_RESULT_FOR_ONE_READER:
      return {
        ...state,
        resultsForOneReader: action?.payload,
      };
      case GET_USER_DETAILS:
        return {
          ...state,
          userDetails: action?.payload,
        };
        case GET_READER_RESULT:
          return {
            ...state,
            resultsReader: action?.payload,
          };
    default:
      return state;
  }
};

export default quizsReducer;
