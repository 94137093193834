import {
  AUTH,
  LOADING,
  LOGOUT,
  GET_READER_DASHBOARD,
  GET_FOLLOWED_PACKS,
  CHANGE_EMAIL,
  FOLLOW_PACK,
  CHANGE_IMAGE,
  SET_PROFILE,
  GET_PROFILE,
  CLEAR_CURRENT,
  GET_UNFOLLOWED_BOOKS,
  GET_USER_NOTIFICATION,
  UPDATE_NOTIFICATION,
  GET_ALL_NOTIFICATION,
  NEW_NOTIFICATION,
  FETCH_ACCOUNTS,
  CREATE_ACCOUNT,
} from "../actions/constants";

const authReducer = (
  state = {
    is_authenticated: false,
    loading: false,
    current_session_followed: [],
    pending_sessions: [],
    followed_pack_list: [],
    followed_sessions: [],
    user: {},
    profile: {},
    unflollwedBooks: [],
    notification: [],
    allNotification: [],
    accounts:[]
  },
  action
) => {
  switch (action.type) {
    case LOADING:
      return { ...state, loading: action.payload };

    case AUTH:
      localStorage.setItem("user", JSON.stringify({ ...action?.payload }));

      return { ...state, user: action?.payload, is_authenticated: true };
      case FETCH_ACCOUNTS:
  
        return { ...state, accounts: action?.payload };

        case CREATE_ACCOUNT:
     console.log(action?.payload)
        return { ...state, accounts: [...state.accounts,action?.payload] };
    case GET_READER_DASHBOARD:
      return {
        ...state,
        current_session_followed: action?.payload.current_session_followed,
        pending_sessions: action?.payload.pending_sessions,
        followed_sessions: action?.payload.followed_sessions,
      };

    case GET_FOLLOWED_PACKS:
      return {
        ...state,
        followed_pack_list: action?.payload.followed_pack_list,
      };

    case GET_UNFOLLOWED_BOOKS:
      return {
        ...state,
        unflollwedBooks: action?.payload,
      };
    case CHANGE_EMAIL:
      return { ...state, user: action.payload };
    case CHANGE_IMAGE:
      return { ...state, user: { ...state.user, img: action.payload } };
    case SET_PROFILE:
      return { ...state, profile: action.payload };
    case GET_PROFILE:
      return { ...state, profile: action.payload };
    case FOLLOW_PACK:
      return {
        ...state,
        followed_pack_list: [action.payload, ...state.followed_pack_list],
        unflollwedBooks: [],
      };

    case GET_USER_NOTIFICATION:
    
      return { ...state, notification: action.payload };

    case GET_ALL_NOTIFICATION:
      return { ...state, allNotification: action.payload };
    case NEW_NOTIFICATION:
      return {
        ...state,
        notification: [action.payload, ...state.notification],
      };
    case UPDATE_NOTIFICATION:
      return {
        ...state,
        notification: state.notification.map((noti) => {
          if (noti._id == action.payload._id) {
            return action.payload;
          }
          return noti;
        }),
      };
    case LOGOUT:
      localStorage.removeItem("user");
      localStorage.removeItem("account")

      return {
        ...state,
        user: null,
        is_authenticated: false,
        current_session_followed: [],
        pending_sessions: [],
        followed_pack_list: [],
        followed_sessions: [],
        unflollwedBooks: [],
        user: {},
        profile: {},
        notification: [],
        accounts:[]
        // allNotification: [],
        // notificationData: [],
      };
    case CLEAR_CURRENT:
      localStorage.removeItem("user");
      localStorage.removeItem("account")

      return {
        ...state,
        user: null,
        is_authenticated: false,
        current_session_followed: [],
        pending_sessions: [],
        followed_pack_list: [],
        followed_sessions: [],
        unflollwedBooks: [],
        user: {},
        profile: {},
        notification: [],
        accounts:[]
        // allNotification: [],
        // notificationData: [],
      };
    default:
      return state;
  }
};

export default authReducer;
