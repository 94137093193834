// import node module libraries
import { Fragment, useEffect, useState } from "react";
import { Col, Row, Nav, Tab, Card, Container, Modal } from "react-bootstrap";

// import custom components
import CourseCard from "components/marketing/pages/courses/CourseCard";
import ProfileCover from "components/marketing/common/headers/ProfileCover";

// import media files
import Avatar3 from "assets/images/avatar/avatar-3.jpg";

// import data files
import { AllCoursesData } from "data/slider/AllCoursesData";
import { useSelector } from "react-redux";
import PackCard from "../pages/courses/PackCard";
import { useDispatch } from "react-redux";
import CalendarBook from "components/dashboard/calendar/CalendarBook";
import { unFollowedBookAction } from "actions/auth";
import { fetchOneBook } from "actions/books";
import PackDashCard from "../pages/courses/PackDashCard";

const StudentDashboard = () => {
  const { followed_pack_list, is_authenticated, unflollwedBooks } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const { book } = useSelector((state) => state.books);
  const handleClose = () => setShow(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (is_authenticated) dispatch(unFollowedBookAction());
  }, [dispatch, is_authenticated]);

  useEffect(() => {
    if (unflollwedBooks) {
      if (unflollwedBooks.length > 0) {
        if (unflollwedBooks[0].book_id !== undefined)
          dispatch(fetchOneBook(unflollwedBooks[0]?.book_id));
      }
    }
  }, [dispatch]);

  const dashboardData = {
    avatar: Avatar3,
    name: "Stella Flores",
    username: "@stellaflores",
    linkname: "Account Setting",
    link: "/student/student-edit-profile",
    verified: false,
    outlinebutton: false,
    level: "Beginner",
  };

  return (
    <Fragment>
      <section className="pt-5 pb-5">
        <Container>
          {/* User info */}
          <ProfileCover dashboardData={dashboardData} />

          {/* Content */}
          <Row className="mt-0 mt-md-4">
            <Col lg={12} md={12} sm={12}>
              <Row className="mb-6">
                <Col md={12}>
                    <Card className="bg-transparent shadow-none ">
                      <Card.Body className="p-0">
                            {/* bookmarked started */}
                            <Row>
                              {followed_pack_list
                                .filter((e) => e.approved === true)
                                .map((item, index) => (
                                  <Col lg={4} md={6} sm={12} key={index}>
                                    {/* <CourseCard item={item} /> */}
                                    <PackDashCard item={item} />
                                  </Col>
                                ))}
                            </Row>
                      </Card.Body>
                    </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
               {unflollwedBooks[0]?.title !== undefined && ( 
               <Modal
          show={show  && unflollwedBooks?.length > 0  }
          onHide={handleClose}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Please follow one session of {unflollwedBooks[0]?.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CalendarBook sessions={book?.sessions} />
          </Modal.Body>
        </Modal>
       )} 
    </Fragment>
  );
};
export default StudentDashboard;
