// import node module libraries
import { Col, Row, Container } from 'react-bootstrap';
//translate component
import {  useTranslation } from 'react-i18next';



const PageHeadingBriefinfo = ({ pagetitle, briefinfo }) => {
	const { t } = useTranslation();
	return (
		<section className="bg-primary" >
			<Container>
				<Row className="align-items-center">
					<Col xl={12} lg={12} md={12} sm={12}>
						<div className="py-4 py-lg-6">
							<h1 className="mb-1 text-white display-4" id="reading-packs">{t(pagetitle)}</h1>
							<p className="text-white mb-0 lead">{t(briefinfo)}</p>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default PageHeadingBriefinfo;
