export const DashboardMenu = [
  {
    id: 1,
    title: "My Packs",
    link: "/student/dashboard",
    icon: "home",
  },
  {
    id: 2,
    title: "My Books",
    link: "/student/student-books/",
    icon: "book",
  },
  // {
  //   id: 3,
  //   title: "Available Sessions",
  //   link: "/student/student-sessions",
  //   icon: "clock",
  // },
  // {
  // 	id: 2,
  // 	title: 'Billing Info',
  // 	link: '/marketing/student/student-billing-info/',
  // 	icon: 'book'
  // },
  // {
  // 	id: 3,
  // 	title: 'Payment',
  // 	link: '/marketing/student/student-payment/',
  // 	icon: 'star'
  // },
  // {
  // 	id: 4,
  // 	title: 'Invoice',
  // 	link: '/marketing/student/student-invoice/',
  // 	icon: 'pie-chart'
  // },
  // {
  // 	id: 5,
  // 	title: 'My Quiz Attempt',
  // 	link: '/marketing/student/quiz/',
  // 	icon: 'help-circle'
  // }
];

export const AccountSettingsMenu = [
  {
    id: 1,
    title: "Edit Profile",
    link: "/student/student-edit-profile",
    icon: "settings",
  },
  {
    id: 2,
    title: "Security",
    link: "/student/student-security/",
    icon: "lock",
  },
  //   {
  //     id: 3,
  //     title: "Social Profiles",
  //     link: "/marketing/student/student-social-profiles/",
  //     icon: "refresh-cw",
  //   },
  // {
  //   id: 3,
  //   title: "Notifications",
  //   link: "/student/student-notifications/",
  //   icon: "bell",
  // },
    {
      id: 4,
      title: "Back To Home",
      link: "/",
      icon: "home",
    },
  //   {
  //     id: 6,
  //     title: "Delete Profile",
  //     link: "/marketing/student/student-delete-profile/",
  //     icon: "trash",
  //   },
  //   {
  //     id: 6,
  //     title: "Linked Accounts",
  //     link: "/marketing/student/student-linked-accounts/",
  //     icon: "user",
  //   },
  {
    id: 6,
    title: "Sign Out",
    link: "/",
    icon: "power",
  },
];

export const InstructorDashboardMenu = [DashboardMenu, AccountSettingsMenu];

export default InstructorDashboardMenu;
