// import node module libraries
import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { Image, Card, ListGroup, Modal, Badge } from "react-bootstrap";

// import custom components
import LevelIcon from "components/marketing/common/miscellaneous/LevelIcon";
import { unFollowedBookAction } from "actions/auth";
import { fetchOneBook } from "actions/books";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CalendarBook from "components/dashboard/calendar/CalendarBook";

// import utility file

const BookInPackCard = ({ item, free, extraclass, link, lg }) => {
  const url = "/instructor/dashboard/" + item.book_id;
  /** Used in Course Index, Course Category, Course Filter Page, Student Dashboard etc...  */
  const GridView = () => {
    const navigate = useNavigate();
    return item.approved ? (
      <Card
        className={`mb-4 card-hover ${extraclass}`}
        onClick={() => navigate(url)}
      >
        <Image
          src={item.book_img}
          alt={item?.book_title}
          className="card-img-top rounded-top-md"
        />

        {/* Card body  */}
        <Card.Body>
          <h3 className="h4 mb-2 text-truncate-line-2 ">
            <Link to={link} className="text-inherit">
              {item?.book_title}
            </Link>
          </h3>
          <h5 className="h6 mb-2 text-truncate-line-2 ">
            <i className="fas fa-user me-1"></i>
            {item?.author}
          </h5>
          <ListGroup as="ul" bsPrefix="list-inline" className="mb-3">
            <ListGroup.Item as="li" bsPrefix="list-inline-item" className="d-inline">
              <i className="fas fa-clipboard me-1"></i>
              Session :{item.session_name}
            </ListGroup.Item>
            <ListGroup.Item as="li" bsPrefix="list-inline-item" className="d-inline">
              <Badge bg={`${item.approved ? "success" : "warning"}`}>
                {item.approved ? "Approved" : "Pending"}
              </Badge>
            </ListGroup.Item>
          </ListGroup>


        </Card.Body>
      </Card>
    ):(
<Card
        className={`mb-4 card-hover ${extraclass}`}
      >
        <Image
          src={item.book_img}
          alt={item?.book_title}
          className="card-img-top rounded-top-md"
        />

        {/* Card body  */}
        <Card.Body>
          <h3 className="h4 mb-2 text-truncate-line-2 ">
            <Link to={link} className="text-inherit">
              {item?.book_title}
            </Link>
          </h3>
          <h5 className="h6 mb-2 text-truncate-line-2 ">
            <i className="fas fa-user me-1"></i>
            {item?.author}
          </h5>
          <ListGroup as="ul" bsPrefix="list-inline" className="mb-3">
            <ListGroup.Item as="li" bsPrefix="list-inline-item" className="d-inline">
              <i className="fas fa-clipboard me-1"></i>
              {item.session_name}
            </ListGroup.Item>
            <ListGroup.Item as="li" bsPrefix="list-inline-item" className="d-inline">
              <Badge bg={`${item.approved ? "success" : "warning"}`}>
                {item.approved ? "Approved" : "Pending"}
              </Badge>
            </ListGroup.Item>
          </ListGroup>


        </Card.Body>
      </Card>
    );
  };

  return (
    <Fragment>

      <GridView />
    </Fragment>
  );
};

// Specifies the default values for props
BookInPackCard.defaultProps = {
  free: false,
  viewby: "grid",
  showprogressbar: false,
  extraclass: "",
  link: "#",
};

// Typechecking With PropTypes
BookInPackCard.propTypes = {
  item: PropTypes.object.isRequired,
  free: PropTypes.bool,
  viewby: PropTypes.string,
  showprogressbar: PropTypes.bool,
  extraclass: PropTypes.string,
  link: PropTypes.string,
};

export default BookInPackCard;
