// import node module libraries

import PropTypes from "prop-types";

// import tippy tooltip
import Tippy from "@tippyjs/react";
import "tippy.js/themes/light.css";
import "tippy.js/animations/scale.css";
import { useSelector } from "react-redux";
// import context file

const GKTippy = ({ children, content, placement }) => {
  const { skin } = useSelector((state) => state.appConfig);


  return (
    <Tippy
      content={
        <small className={`fw-bold ${skin === "light" ? "text-dark" : ""}`}>
          {content}
        </small>
      }
      theme={skin === "light" ? "dark" : "light"}
      placement={placement}
      animation={"scale"}
    >
      {children}
    </Tippy>
  );
};
// ** PropTypes
GKTippy.propTypes = {
  placement: PropTypes.oneOf([
    "top",
    "top-start",
    "top-end",
    "right",
    "right-start",
    "right-end",
    "bottom",
    "bottom-start",
    "bottom-end",
    "left",
    "left-start",
    "left-end",
  ]),
};

// ** Default Props
GKTippy.defaultProps = {
  placement: "top",
  content: "Tool Tip Text",
};

export default GKTippy;
