// import node module libraries
import { Fragment } from "react";
import { Col, Row, Card, Container, Button } from "react-bootstrap";

// import custom components
import ProfileCover from "components/marketing/common/headers/ProfileCover";

// import media files
import Avatar3 from "assets/images/avatar/avatar-3.jpg";

// import data files
import { useSelector } from "react-redux";
import { Link} from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
//translate component
import {  useTranslation } from 'react-i18next';
import { BookwithResultAction, fetchAllResultsFromOneReaderAction} from "actions/quizs";
import BookInPackCard from "../pages/courses/BookInPackCard";
import BookToResultCard from "../pages/courses/BookToResultCard";

 

const MyResult = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
   const { resultsForOneReader } = useSelector((state) => state.quizs);
   const { user} = useSelector((state) => state.auth);
   const user_id = user.quiz_id;
   useEffect(() => {
       dispatch(
        BookwithResultAction(user_id)
       );
     }, [dispatch,user_id]);


  const dashboardData = {
    avatar: Avatar3,
    name: "Stella Flores",
    username: "@stellaflores",
    linkname: "Account Setting",
    link: "/student/student-edit-profile",
    verified: false,
    outlinebutton: false,
    level: "Beginner",
  };

  return (
    <Fragment>
      <section className="pt-5 pb-5">
        <Container>
          {/* User info */}
          <ProfileCover dashboardData={dashboardData} />
          {/* <Row className="mt-3">
            <Col className="text-end mt-2" >
            <Link to="/student/dashboard/" >
              <Button>
              <i className="fe fe-arrow-left me-2"></i>
                {t("back_to_pack")}
              </Button>
              </Link>
            </Col>
          </Row> */}
          {/* Content */}
          <Row className="mt-0 mt-md-4" >
            <Col lg={12} md={12} sm={12}>
            <Card className="bg-transparent shadow-none " style={{marginTop:"10px"}}>
                      <Card.Body className="p-0">
                            {/* bookmarked started */}
                            <Row>
                              {resultsForOneReader?.map((item, index) => (
                                  <Col lg={3} md={6} sm={12} key={index}>
                                    {/* <CourseCard item={item} /> */}
                                    <BookToResultCard item={item} />
                                  </Col>
                                ))}
                            </Row>
                      </Card.Body>
                    </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};
export default MyResult;
