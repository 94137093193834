// import MDI icons
import Icon from '@mdi/react';
import { mdiCheck } from '@mdi/js';
//translate component
import {  useTranslation } from 'react-i18next';

 
const FeatureBulletList = ({ item }) => {
	const { t } = useTranslation();
	return (
		<div className="text-dark fw-semi-bold lh-1 fs-4 mb-3 mb-lg-0">
			<span className="icon-shape icon-xs rounded-circle bg-light-warning text-center me-2">
				<Icon path={mdiCheck} size={0.7} className="text-dark-warning" />
			</span>
			<span className="align-middle">{t(item.title)}</span>
		</div>
	);
};
export default FeatureBulletList;
