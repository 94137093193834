// import node module libraries
import { Fragment, useEffect } from "react";
import { Element } from "react-scroll";

// import custom components

import CTA2Buttons from "components/marketing/common/call-to-action/CTA2Buttons";

// import sub components
import HeroFormRight from "./HeroFormRight";
import TestimonialSection from "./TestimonialSection";
import FeaturesWithBullets from "./FeaturesWithBullets";
import CourseDescriptionSection from "./CourseDescriptionSection";
import FAQsection from "./FAQsection";
import YourInstructor from "./YourInstructor";

// import layouts

import FooterWithLinks from "layouts/marketing/footers/FooterWithLinks";

// import data files

import { useDispatch } from "react-redux";
import { fetchOneBook } from "actions/books";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const CourseLead = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { book } = useSelector((state) => state.books);

  useEffect(() => {
    if (id) dispatch(fetchOneBook(id));
  }, [dispatch, id]);

  return (
    <Fragment>
      <main id="brand">
        {/* Hero section with right form */}
        {/* Books section  */}
        <HeroFormRight book={book} />

        {/* Feature section with bullet  */}
        <FeaturesWithBullets />

        {/* Course description section  */}
        {/* Calendar section  */}
        <Element name="Calendar">
          <CourseDescriptionSection
            sessions={book?.sessions}
            pastSessions={book.old_sessions}
            book={book}
          />
        </Element>

        {/* Your instructor section */}
        {/* <YourInstructor /> */}

        {/*  Logo section */}
        {/* <LogosTopHeading
					title="Trusted by top-tier product companies"
					logos={LogoList2}
				/> */}

        {/* Testimonial slider section */}
        <TestimonialSection />

        {/*  FAQs section */}
        {/* <FAQsection /> */}

        {/*  CTA section */}
        <CTA2Buttons
          title="start_your_reading"
          description="hello_book_lovers"
          btntext1="Start Learning "
          btnlink1="/authentication/sign-up"
          btntext2="Geeks for Business"
          btnlink2="/authentication/sign-up"
        />
      </main>

      {/* Footer section */}
      {/* <FooterWithLinks /> */}
    </Fragment>
  );
};
export default CourseLead;
