import { JaaSMeeting } from '@jitsi/react-sdk';
import { fetchSessionAction } from 'actions/sessions';
import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

function VideoMeeting() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const {session} = useSelector((state) => state.packs);
    useEffect(() => {
        dispatch(fetchSessionAction(id))
       }, [dispatch, id])
 


    return (
        <div>
            {/* <JaaSMeeting
                appId={appId}
                spinner={customSpinner}
                domain={domain}
                roomName={roomName}
                configOverwrite={{
                    disableThirdPartyRequests: true,
                    disableLocalVideoFlip: true,
                    backgroundAlpha: 0.5
                }}
                interfaceConfigOverwrite={{
                    VIDEO_LAYOUT_FIT: 'nocrop',
                    MOBILE_APP_PROMO: false,
                    TILE_VIEW_MAX_COLUMNS: 4
                }}
                userInfo={{
                    displayName: userId
                }}
               
                onApiReady={(externalApi) => { console.log(externalApi) }}
            /> */}
            <iframe
                title="Jitsi Meet"
                allow="camera; microphone; fullscreen; display-capture"
                src={`https://meet.jit.si/${session.meet_link}`}
                style={{ height: "95vh", width:"90%", display: "grid", flexDirection: "column",borderRadius:"40px", margin:"auto", padding:"1%" }}
            ></iframe>
        </div>
    );
}

export default VideoMeeting;