// import media files
import Avatar1 from "assets/images/avatar/avatar-1.jpg";
import Avatar2 from "assets/images/avatar/avatar-2.jpg";
import Avatar3 from "assets/images/avatar/avatar-3.jpg";
import Avatar4 from "assets/images/avatar/avatar-4.jpg";
import Avatar5 from "assets/images/avatar/avatar-5.jpg";
import Avatar6 from "assets/images/avatar/avatar-6.jpg";
import Avatar7 from "assets/images/avatar/avatar-7.jpg";
import Avatar8 from "assets/images/avatar/avatar-8.jpg";

export const TestimonialsList = [
  {
    id: 1,
    name: "Aulpha",
    designation: 'english_teacher',
    image: Avatar1,
    content:'as_a_seasoned',
    rating: 5.0,
    color: "primary",
  },
  {
    id: 2,
    name: "Saadia",
    designation: 'english_teacher',
    image: Avatar2,
    content:'ive_seen_various',
    rating: 5.0,
    color: "info",
  },
  {
    id: 3,
    name: "Wafa ",
    designation: 'educational_coordinator',
    image: Avatar3,
    content:'our_school_repuatation',
    rating: 5.0,
    color: "danger",
  },

  {
    id: 4,
    name: "Amine",
    designation: 'iread_project_man',
    image: Avatar4,
    content:'iread_embodies',
    rating: 5,
    color: "success",
  },
  {
    id: 5,
    name: "Manel",
    designation: 'school_director',
    image: Avatar5,
    content: 'our_educators_have',
    rating: 4.5,
    color: "secondary",
  },
  {
    id: 6,
    name: "Nizar",
    designation: 'head_of_english',
    image: Avatar6,
    content:'ive_led_our',
    rating: 4.5,
    color: "info",
  },
  {
    id: 7,
    name: "Imen ",
    designation: 'hr_manager',
    image: Avatar7,
    content: 'as_hr_manager',
    rating: 4.5,
    color: "warning",
  },
//   {
//     id: 8,
//     name: "Barry Watson",
//     designation: "Engineering Architect",
//     image: Avatar8,
//     content:
//       "Sed pretium risus magna, ac efficitur nunc rutrum imperdiet. Vivamus sed ante sed mi fermentum tempus. Nullam finibus augue eget felis efficitur semper.",
//     rating: 4.5,
//     color: "dark",
//   },
];

export default TestimonialsList;
