export const NewCourseData = [
	{
		id: 1,
		title: 'Introduction'
	},
	{
		id: 2,
		title: 'Installing Development Software'
	},
	{
		id: 3,
		title: 'Hello World Project from GitHub'
	},
	{
		id: 4,
		title: 'Our Sample Website'
	}
];

export default NewCourseData;
