
import { toast } from "react-toastify";
import { saveAs } from 'file-saver';
import { createInvoice, downloadInvoice, getInvoice, getInvoiceByClient, getInvoiceNumber, getPdf } from "api";
import {
    CREATE_INVOICE,
    DOWNLOAD_INVOICE,
    GET_INVOICE_BY_CLIENT,
    GET_INVOICE_NUMBER,
    GET_ONE_INVOICE,
    LOADING,
} from "./constants";

const INV_API ="https://invoicing-api.iread.tn"
export const getInvoiceByClientAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOADING, payload: true });
        const { data } = await getInvoiceByClient(id);
        dispatch({ type: GET_INVOICE_BY_CLIENT, payload: data });

        dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
            console.log(error.response.data.message);
            dispatch({ type: LOADING, payload: false });
        }
    }
};

export const getInvoiceAction = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOADING, payload: true });

        const { data } = await getInvoice(id);
        dispatch({ type: GET_ONE_INVOICE, payload: data });

        dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
            console.log(error.response.data.message);
            dispatch({ type: LOADING, payload: false });
        }
    }
};

export const getInvoiceNumberAction = () => async (dispatch) => {
    try {
        dispatch({ type: LOADING, payload: true });
        const { data } = await getInvoiceNumber();
        dispatch({ type: GET_INVOICE_NUMBER, payload: data });

        dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
            console.log(error.response.data.message);
            dispatch({ type: LOADING, payload: false });
        }
    }
};

export const createInvoiceAction = (fromData) => async (dispatch) => {
    try {
        dispatch({ type: LOADING, payload: true });

        const { data } = await createInvoice(fromData);
        dispatch({ type: CREATE_INVOICE, payload: data });

        dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
            console.log(error.response.data.message);
            dispatch({ type: LOADING, payload: false });
        }
    }
};

export const downloadInvoiceAction = (fromData) => async (dispatch) => {
    try {
        dispatch({ type: LOADING, payload: true });
         await downloadInvoice(fromData);
      
     
         
         return INV_API +"/pdf/fetch-pdf"
     
        // dispatch({ type: DOWNLOAD_INVOICE, payload: data });
        // toast.success(data.message, { autoClose: 1000 })
        // dispatch({ type: LOADING, payload: false });
    } catch (error) {
        console.log(error);
        if (error?.response?.data?.message) {
            console.log(error.response.data.message);
            dispatch({ type: LOADING, payload: false });
        }
    }
};