import { v4 as uuid } from 'uuid';
import { HelpCircle, Book, LifeBuoy } from 'react-feather';
const selectedLanguage = localStorage.getItem('selectedLanguage')
export const HelpCenterFeaturesData = [
	{
		id: uuid(),
		icon: <HelpCircle size="40" strokeWidth="1.5" className="text-primary" />,
		title: 'faq',
		link: `https://help-center.iread.tn/help-center/faq?lng=${selectedLanguage}`,
		description:
			'faq_desc',
		linkname: 'view_faq'
	},
	{
		id: uuid(),
		icon: <Book size="40" strokeWidth="1.5" className="text-primary" />,
		title: 'guide_resources',
		link: `https://help-center.iread.tn/help-center/guide?lng=${selectedLanguage}`,
		description:
			'guide_resou_desc',
		linkname: 'browse_guide'
	},
	{
		id: uuid(),
		icon: <LifeBuoy size="40" strokeWidth="1.5" className="text-primary" />,
		title: 'support',
		link: `https://help-center.iread.tn/help-center/support?lng=${selectedLanguage}`,
		description:
			'submit_description',
		linkname: 'submit_req'
	}
];

export default HelpCenterFeaturesData;
