import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col, Nav, Navbar, Offcanvas } from "react-bootstrap";
import {
  DashboardMenu,
  AccountSettingsMenu,
} from "routes/marketing/StudentDashboardMenu";
import ProfileCover from "components/marketing/common/headers/ProfileCover";
import Avatar3 from "assets/images/avatar/avatar-3.jpg";
import { useDispatch } from "react-redux";
import { logOutAction } from "actions/auth";

const ProfileLayout = (props) => {
  const location = useLocation();
  const [showSidebar, setShowSidebar] = useState(false);

  const dashboardData = {
    avatar: Avatar3,
    name: "Stella Flores",
    username: "@stellaflores",
    linkname: "Account Settings",
    link: "/student/student-edit-profile",
    verified: true,
    outlinebutton: false,
    level: "",
  };

  const dispatch = useDispatch();

  const handleLogOut = (id) => {
    if (id === 6) {
      dispatch(logOutAction());
    }
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <Fragment>
      <section className="pt-5 pb-5">
        <Container>
          {/* User info */}
          <ProfileCover dashboardData={dashboardData} />

          {/* Content */}
          <Row className="mt-0 mt-md-4">
            <Col lg={3} md={4} sm={12}>
              <Navbar expand="lg" className="navbar navbar-expand-md navbar-light shadow-sm mb-4 mb-lg-0 sidenav">
                <span
                  className="navbar-toggler d-md-none icon-shape icon-sm rounded bg-primary p-0 text-white float-end"
                  onClick={() => setShowSidebar(!showSidebar)}
                >
                  <span className="fe fe-menu"></span>
                </span>

                <Offcanvas show={showSidebar} onHide={() => setShowSidebar(false)} style={{  width: "60%", borderRight: "1px solid #dee2e6" }}>
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Menu</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body style={{ backgroundColor: 'rgba(255,255,255,0.5)' }} >
                    <Nav className="me-auto flex-column" as="ul" activeKey="0" >
                    {/* <Nav.Item className="navbar-header" as="li">
                       SUBSCRIPTION 
                    </Nav.Item>
                    {DashboardMenu.map((item, index) => (
                      <Nav.Item
                        as="li"
                        key={index}
                        className={`${
                          item.link === location.pathname ? "active" : ""
                        }`}
                      >
                        <Link className="nav-link" to={item.link}>
                          <i style={{paddingRight:"10px"}} className={`fe fe-${item.icon} nav-icon`}></i>
                          {item.title}
                        </Link>
                      </Nav.Item>
                    ))}  */}
                    <Nav.Item className="navbar-header mt-4" as="li">
                      ACCOUNT SETTINGS
                    </Nav.Item>
                    {AccountSettingsMenu.map((item, index) => (
                      <Nav.Item
                        onClick={() => handleLogOut(item.id)}
                        as="li"
                        key={index}
                        className={`${item.link === location.pathname ? "active" : ""
                          }`}
                      >
                        <Link className="nav-link" to={item.link}>
                          <i style={{paddingRight:"10px"}} className={`fe fe-${item.icon} nav-icon`}></i>
                          {item.title}
                        </Link>
                      </Nav.Item>
                    ))}
                    </Nav>
                  </Offcanvas.Body>
                </Offcanvas>
                {!isMobile && (
                <Nav >
                <Nav className="me-auto flex-column" as="ul" activeKey="0">
                     {/* <Nav.Item className="navbar-header" as="li">
                      SUBSCRIPTION
                    </Nav.Item>
                    {DashboardMenu.map((item, index) => (
                      <Nav.Item
                        as="li"
                        key={index}
                        className={`${
                          item.link === location.pathname ? "active" : ""
                        }`}
                      >
                        <Link className="nav-link" to={item.link}>
                          <i className={`fe fe-${item.icon} nav-icon`}></i>
                          {item.title}
                        </Link>
                      </Nav.Item>
                    ))}  */}
                    <Nav.Item className="navbar-header mt-4" as="li">
                      ACCOUNT SETTINGS
                    </Nav.Item>
                    {AccountSettingsMenu.map((item, index) => (
                      <Nav.Item
                        onClick={() => handleLogOut(item.id)}
                        as="li"
                        key={index}
                        className={`${item.link === location.pathname ? "active" : ""
                          }`}
                      >
                        <Link className="nav-link" to={item.link}>
                          <i className={`fe fe-${item.icon} nav-icon`}></i>
                          {item.title}
                        </Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  
                  </Nav>
                  )}
              </Navbar>
            </Col>

            <Col lg={9} md={8} sm={12}>
              {props.children}
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default ProfileLayout;
