// Section : Features
// Style : Three Columns Features Section

// import node module libraries
import { Col, Row, Container } from "react-bootstrap";

// import MDI icons
import { mdiSchoolOutline, mdiAccountGroup, mdiFinance } from "@mdi/js";

// import custom components
import FeatureTopIconCard from "components/marketing/common/features/FeatureTopIconCard";

// import sub components
import SectionHeading from "./SectionHeading";

const Features3Columns = () => {
  const title = 'our_important';
  const description = `here_at_iread`;

  const features = [
    {
      id: 1,
      icon: mdiSchoolOutline,
      title: 'bringing_learning',
      description: 'connecting_with_storie',
    },

    {
      id: 2,
      icon: mdiAccountGroup,
      title: 'learn_and_grow',
      description:'embark_ona_journey',
    },
    {
      id: 3,
      icon: mdiFinance,
      title: 'making_reading',
      description: 'bringing_engaging',
    },
  ];

  return (
    <section className="py-lg-16 py-10">
      <Container>
        <SectionHeading title={title} description={description} />
        <Row>
          {features.map((item, index) => {
            return (
              <Col md={4} sm={12} key={index}>
                <FeatureTopIconCard item={item} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
};

export default Features3Columns;
