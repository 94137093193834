// import media files

// import blog post images
import Blogpost1 from 'assets/images/blog/blogpost-1.jpg';
import Blogpost2 from 'assets/images/blog/blogpost-2.jpg';
import Blogpost3 from 'assets/images/blog/blogpost-3.jpg';
import Blogpost4 from 'assets/images/blog/blogpost-4.jpg';
import Blogpost5 from 'assets/images/blog/blogpost-5.jpg';
import Blogpost6 from 'assets/images/blog/blogpost-6.jpg';

// import avatar media files
import Amine from 'assets/images/avatar/amine.jpg';
import Avatar1 from 'assets/images/avatar/avatar-1.jpg';
import Avatar2 from 'assets/images/avatar/avatar-2.jpg';
import Avatar3 from 'assets/images/avatar/avatar-3.jpg';
import Avatar4 from 'assets/images/avatar/avatar-4.jpg';
import Avatar5 from 'assets/images/avatar/avatar-5.jpg';
import Avatar6 from 'assets/images/avatar/avatar-6.jpg';
import Avatar7 from 'assets/images/avatar/avatar-7.jpg';
import Avatar8 from 'assets/images/avatar/avatar-8.jpg';
import Avatar9 from 'assets/images/avatar/avatar-9.jpg';
import Avatar10 from 'assets/images/avatar/avatar-10.jpg';

const blogContent = `<p>
<h4>Dear Iread Family,</h4>
Hello to all of you! I am Amine Lamine, the Founder of Iread. I am very happy to welcome each one of you to our big family of readers. In Iread, we love books and we love helping you read and learn. We work hard to make reading fun and easy for you. Today, I want to talk to you about some special things in Iread that can help you read better and learn more.
</p>
<p>
Our team has made a place where you can find lots of books. These books are not just fun to read, but they also help you learn English better. Whether you are just starting to read English books or you have been reading them for some time, we have something for everyone. Let me tell you about some of the great things you can find in Iread:
</p>
<h4>Key Features for a Great Reading Experience:</h4>
<ul>
<li><strong>Books Just for You:</strong> Our smart system looks at what you like to read and suggests other books that you will enjoy.</li>
<li><strong>Talk About Books:</strong> Join groups where you can talk about your books with other readers and make new friends.</li>
<li><strong>See Your Progress:</strong> Keep track of how many books you've read and how your English is getting better.</li>
<li><strong>Fun Quizzes:</strong> After you read a book, take a quiz to see how much you remember and understand.</li>
<li><strong>Learn New Words:</strong> Find new English words in your books and learn what they mean.</li>
<li><strong>Practice English:</strong> Do activities that make you think about the story and use English in different ways.</li>
</ul>
<p>
But wait, there's more! We also have these extra tools to help you on your reading adventure:
</p>
<h4>Extra Tools for Your Learning:</h4>
<ul>
<li><strong>Set Your Reading Goals:</strong> Decide how many books you want to read and we will help you reach your goal.</li>
<li><strong>Join the Conversation:</strong> Talk about books, ask questions, and learn from others in our online forums.</li>
<li><strong>Learn from Experts:</strong> Watch videos and join online classes with people who are really good at teaching English.</li>
<li><strong>Read Anywhere:</strong> You can use Iread on your computer, tablet, or phone. Your books and progress are always with you.</li>
</ul>
<p>
We are excited to see you grow and learn with our Iread family. Remember, every book is a new adventure, and every page helps you get better in English. Start exploring and enjoy your reading journey!
</p>
<p>Happy reading!
<br>Warmly,
<br>Amine Lamine
</p>
<br>
<hr>
<br>
<blockquote style="text-align: center;">
<h2 style="color: rgb(117, 79, 254);">"Failure will never overtake me if my determination to succeed is strong enough."</h2>
<footer>
  <cite title="Source Title">Og Mandino</cite>
</footer>
</blockquote>
<br>
<hr>
<br>
<div>
<h2>Image </h2>
<p>
  Sint officia nulla deserunt voluptate non amet consequat ipsum tempor. Nulla id cupidatat ipsum occaecat. Aute ad et fugiat velit sunt qui veniam labore elit ipsum commodo proident. Sit tempor consectetur commodo laborum mollit. Enim sint nostrud nisi in ad aliqua laboris ad non.
</p>
<img src="https://codescandy.com/geeks-bootstrap-5/assets/images/blog/center-img.jpg" alt="" style="width:100%">
</div>
`;
const blogContent1 = `<p>
<h4>Dear Iread Family,</h4>
Hello to all of you! I am Amine Lamine, the Founder of Iread. I am very happy to welcome each one of you to our big family of readers. In Iread, we love books and we love helping you read and learn. We work hard to make reading fun and easy for you. Today, I want to talk to you about some special things in Iread that can help you read better and learn more.
</p>
<p>
Our team has made a place where you can find lots of books. These books are not just fun to read, but they also help you learn English better. Whether you are just starting to read English books or you have been reading them for some time, we have something for everyone. Let me tell you about some of the great things you can find in Iread:
</p>
<h4>Key Features for a Great Reading Experience:</h4>
<ul>
<li><strong>Books Just for You:</strong> Our smart system looks at what you like to read and suggests other books that you will enjoy.</li>
<li><strong>Talk About Books:</strong> Join groups where you can talk about your books with other readers and make new friends.</li>
<li><strong>See Your Progress:</strong> Keep track of how many books you've read and how your English is getting better.</li>
<li><strong>Fun Quizzes:</strong> After you read a book, take a quiz to see how much you remember and understand.</li>
<li><strong>Learn New Words:</strong> Find new English words in your books and learn what they mean.</li>
<li><strong>Practice English:</strong> Do activities that make you think about the story and use English in different ways.</li>
</ul>
<p>
But wait, there's more! We also have these extra tools to help you on your reading adventure:
</p>
<h4>Extra Tools for Your Learning:</h4>
<ul>
<li><strong>Set Your Reading Goals:</strong> Decide how many books you want to read and we will help you reach your goal.</li>
<li><strong>Join the Conversation:</strong> Talk about books, ask questions, and learn from others in our online forums.</li>
<li><strong>Learn from Experts:</strong> Watch videos and join online classes with people who are really good at teaching English.</li>
<li><strong>Read Anywhere:</strong> You can use Iread on your computer, tablet, or phone. Your books and progress are always with you.</li>
</ul>
<p>
We are excited to see you grow and learn with our Iread family. Remember, every book is a new adventure, and every page helps you get better in English. Start exploring and enjoy your reading journey!
</p>
<p>Happy reading!
<br>Warmly,
<br>Amine Lamine
</p>

</blockquote>
`;

const blogContent2 = `<p>
<h4>Hello Young Readers and Future Leaders,</h4>
Today, I want to share with you something very special – the magic of reading. I’m Amine Lamine, the Founder of Iread, and I’ve seen firsthand how books can open doors to new worlds, not just in stories, but in real life too. Let’s talk about why reading, especially physical books, is so important for you as kids and teens, and how it can help you in your everyday life and even in your future career.
</p>
<p>
<strong>Reading – A Window to New Adventures:</strong> Reading is like having a magic key. It opens up doors to places you’ve never been, introduces you to new friends (characters in books), and teaches you about different ways of life. Every book is a new adventure waiting to happen.
</p>
<p>
<strong>Why Physical Books Are Special:</strong>
</p>
<ul>
<li><strong>Feel and Touch:</strong> There’s something magical about holding a book. The feel of the pages between your fingers is a part of the reading experience that you can’t get from a screen.</li>
<li><strong>Focus and Imagination:</strong> Reading a physical book helps you concentrate better. With no pop-up notifications, it’s just you and the story, allowing your imagination to fly.</li>
<li><strong>Eye Health:</strong> Spending time away from screens is good for your eyes. Reading physical books is a healthy break for your eyes from phones and computers.</li>
</ul>
<p>
<strong>Reading for Your Future:</strong>
</p>
<ul>
<li><strong>Knowledge and Skills:</strong> The more you read, the more you know. Books are full of knowledge about the world, different subjects, and new ideas. They also improve your vocabulary and language skills, which are very important for any job you might want in the future.</li>
<li><strong>Creativity and Problem Solving:</strong> Reading stories can make you a more creative thinker. You learn to imagine solutions and think outside the box – a skill that is very valuable in any career.</li>
<li><strong>Empathy and Understanding:</strong> Books often put you in someone else’s shoes. This helps you understand and care about others, a key skill for working well with people in any job.</li>
</ul>
<p>
<strong>Reading in Daily Life:</strong>
</p>
<ul>
<li><strong>A Relaxing Hobby:</strong> Reading is a great way to relax and unwind. It’s like a mini-vacation without ever leaving your room.</li>
<li><strong>A Confidence Booster:</strong> The more you read, the more you learn, and the more confident you feel about your knowledge and skills.</li>
</ul>
<p>
So, dear readers, I encourage you to pick up a book, dive into its pages, and let the journey begin. Whether you’re exploring a fantasy land, solving a mystery, or learning about a real-life hero, every book has something new to teach you. And who knows? The stories you read today might inspire the amazing things you do tomorrow.
</p>
<p>
Keep Reading and Dreaming Big!
<br>Warm wishes,
<br>The Founder of Iread
</p>
</blockquote>

`;

const BlogArticles = [
	{
		id: 1,
		blogpostimage: Blogpost4,
		category: 'Company',
		title: 'enjoy_learn_more',
		details:
			'hello_to_all',
		authorname: 'amine_lamine',
		authorimage: Amine,
		aboutauthor: 'the_founder_of',
		postedon: 'january_18',
		content: blogContent1
	},
	{
		id: 2,
		blogpostimage: Blogpost3,
		category: 'Company',
		title: 'the_magic_of_reading',
		details:
			'today_want_share',
		authorname: 'amine_lamine',
		authorimage: Amine,
		aboutauthor: 'the_founder_of',
		postedon: 'january_18',
		content: blogContent2
	},
	{
		id: 3,
		blogpostimage: Blogpost1,
		category: 'Tutorial',
		title: 'What is PHP Function? For Beginner’s Guide',
		details:
			'Lorem ipsum dolor sit amet, accu msan in, tempor dictum nequrem ipsum',
		authorname: 'Lisa Menon',
		authorimage: Avatar8,
		aboutauthor: 'Marketing Manager',
		postedon: 'September 06, 2020',
		readinglength: 8,
		content: blogContent
	},
	{
		id: 4,
		blogpostimage: Blogpost4,
		category: 'Workshop',
		title: 'What is Cyber Security? A Beginner’s Guide',
		details:
			'Lorem ipsum dolor sit amet, accu msan in, tempor dictum nequrem ipsum',
		authorname: 'Maria Pinto',
		authorimage: Avatar9,
		aboutauthor: 'Marketing Manager',
		postedon: 'September 07, 2020',
		readinglength: 15,
		content: blogContent
	},
	{
		id: 5,
		blogpostimage: Blogpost5,
		category: 'Tutorial',
		title: 'What is machine learning and how does it work?',
		details:
			'Lorem ipsum dolor sit amet, accu msan in, tempor dictum nequrem ipsum',
		authorname: 'Loran Sipon',
		authorimage: Avatar10,
		aboutauthor: 'Marketing Manager',
		postedon: 'September 08, 2020',
		readinglength: 10,
		content: blogContent
	},
	{
		id: 6,
		blogpostimage: Blogpost3,
		category: 'Workshop',
		title: 'The Best DevOps Tools for Your Application Lifecycle',
		details:
			'Inventore pariatur veritatis maxime fugiat sint dolorem quas culpa officiis nemo quis!',
		authorname: 'Dustin Warren',
		authorimage: Avatar1,
		aboutauthor: 'Marketing Manager',
		postedon: 'September 09, 2020',
		readinglength: 12,
		content: blogContent
	},
	{
		id: 7,
		blogpostimage: Blogpost1,
		category: 'Company',
		title: 'How to become modern Stack Developer in 2020',
		details:
			'At beatae non sit dicta similique perspiciatis facilis veritatis quam. Recusandae, corrupti?',
		authorname: 'Reva Yokk',
		authorimage: Avatar2,
		aboutauthor: 'Marketing Manager',
		postedon: 'September 10, 2020',
		readinglength: 10,
		content: blogContent
	},
	{
		id: 8,
		blogpostimage: Blogpost6,
		category: 'Company',
		title: 'How to Become a Data Scientist?',
		details:
			'Nulla voluptate in facere saepe est alias et iste, accusantium sint enim!',
		authorname: 'Miron Sulla',
		authorimage: Avatar3,
		aboutauthor: 'Marketing Manager',
		postedon: 'September 05, 2020',
		readinglength: 20,
		content: blogContent
	},
	{
		id: 9,
		blogpostimage: Blogpost1,
		category: 'Workshop',
		title: 'How to become WebDesinger?',
		details:
			'Vero expedita voluptatibus cumque sunt ullam cum natus, vitae provident debitis pariatur?',
		authorname: 'Lucy Kolin',
		authorimage: Avatar4,
		postedon: 'September 05, 2020',
		readinglength: 20,
		content: blogContent
	},
	{
		id: 10,
		blogpostimage: Blogpost2,
		category: 'Tutorial',
		title: 'Developing Agile Leadership',
		details:
			'Debitis ipsam ratione molestias dolores qui asperiores consequatur facilis error.',
		authorname: 'Jerry Dom',
		authorimage: Avatar5,
		aboutauthor: 'Marketing Manager',
		postedon: 'September 13, 2020',
		readinglength: 12,
		content: blogContent
	},
	{
		id: 11,
		blogpostimage: Blogpost2,
		category: 'Tutorial',
		title: 'Getting started the Web App JavaScript in 2020',
		details:
			'Debitis ipsam ratione molestias dolores qui asperiores consequatur facilis error.',
		authorname: 'Dustin Warren',
		authorimage: Avatar6,
		aboutauthor: 'Marketing Manager',
		postedon: 'September 13, 2020',
		readinglength: 6,
		content: blogContent
	},
	{
		id: 12,
		blogpostimage: Blogpost3,
		category: 'Courses',
		title: 'How to become modern Stack Developer in 2020',
		details:
			'Lorem ipsum dolor sit amet, accu msan in, tempor dictum nequrem ipsum',
		authorname: 'Reva Yokk',
		authorimage: Avatar7,
		aboutauthor: 'Marketing Manager',
		postedon: 'September 05, 2020',
		readinglength: 20,
		content: blogContent
	},
	{
		id: 13,
		blogpostimage: Blogpost4,
		category: 'Tutorial',
		title: 'What is Cyber Security? A Beginner’s Guide',
		details:
			'Lorem ipsum dolor sit amet, accu msan in, tempor dictum nequrem ipsum',
		authorname: 'Maria Pinto',
		authorimage: Avatar9,
		aboutauthor: 'Marketing Manager',
		postedon: 'September 07, 2020',
		readinglength: 15,
		content: blogContent
	},
	{
		id: 14,
		blogpostimage: Blogpost3,
		category: 'Tutorial',
		title: 'The Best DevOps Tools for Your Application Lifecycle',
		details:
			'Inventore pariatur veritatis maxime fugiat sint dolorem quas culpa officiis nemo quis!',
		authorname: 'Dustin Warren',
		authorimage: Avatar1,
		aboutauthor: 'Marketing Manager',
		postedon: 'September 09, 2020',
		readinglength: 12,
		content: blogContent
	},
	{
		id: 15,
		blogpostimage: Blogpost5,
		category: 'Courses',
		title: 'How to become modern Stack Developer in 2020',
		details:
			'At beatae non sit dicta similique perspiciatis facilis veritatis quam. Recusandae, corrupti?',
		authorname: 'Reva Yokk',
		authorimage: Avatar2,
		aboutauthor: 'Marketing Manager',
		postedon: 'September 10, 2020',
		readinglength: 10,
		content: blogContent
	},
	{
		id: 16,
		blogpostimage: Blogpost6,
		category: 'Company',
		title: 'How to Become a Data Scientist?',
		details:
			'Nulla voluptate in facere saepe est alias et iste, accusantium sint enim!',
		authorname: 'Miron Sulla',
		authorimage: Avatar3,
		aboutauthor: 'Marketing Manager',
		postedon: 'September 05, 2020',
		readinglength: 20,
		content: blogContent
	},
	{
		id: 17,
		blogpostimage: Blogpost1,
		category: 'Tutorial',
		title: 'How to become WebDesinger?',
		details:
			'Vero expedita voluptatibus cumque sunt ullam cum natus, vitae provident debitis pariatur?',
		authorname: 'Lucy Kolin',
		authorimage: Avatar4,
		aboutauthor: 'Marketing Manager',
		postedon: 'September 05, 2020',
		readinglength: 20,
		content: blogContent
	},
	{
		id: 18,
		blogpostimage: Blogpost3,
		category: 'Courses',
		title: 'How to become modern Stack Developer in 2020',
		details:
			'Lorem ipsum dolor sit amet, accu msan in, tempor dictum nequrem ipsum',
		authorname: 'Reva Yokk',
		authorimage: Avatar7,
		aboutauthor: 'Marketing Manager',
		postedon: 'September 05, 2020',
		readinglength: 20,
		content: blogContent
	},
	{
		id: 19,
		blogpostimage: Blogpost6,
		category: 'Workshop',
		title: 'How to become modern Stack Developer in 2020',
		details:
			'At beatae non sit dicta similique perspiciatis facilis veritatis quam. Recusandae, corrupti?',
		authorname: 'Reva Yokk',
		authorimage: Avatar2,
		aboutauthor: 'Marketing Manager',
		postedon: 'September 10, 2020',
		readinglength: 10,
		content: blogContent
	},
	{
		id: 20,
		blogpostimage: Blogpost3,
		category: 'Tutorial',
		title: 'How to Become a Data Scientist?',
		details:
			'Nulla voluptate in facere saepe est alias et iste, accusantium sint enim!',
		authorname: 'Miron Sulla',
		authorimage: Avatar3,
		aboutauthor: 'Marketing Manager',
		postedon: 'September 05, 2020',
		readinglength: 20,
		content: blogContent
	},
	{
		id: 21,
		blogpostimage: Blogpost1,
		category: 'Courses',
		title: 'How to become WebDesinger?',
		details:
			'Vero expedita voluptatibus cumque sunt ullam cum natus, vitae provident debitis pariatur?',
		authorname: 'Lucy Kolin',
		authorimage: Avatar4,
		aboutauthor: 'Marketing Manager',
		postedon: 'September 05, 2020',
		readinglength: 20,
		content: blogContent
	},
	{
		id: 22,
		blogpostimage: Blogpost5,
		category: 'Tutorial',
		title: 'How to become modern Stack Developer in 2020',
		details:
			'Lorem ipsum dolor sit amet, accu msan in, tempor dictum nequrem ipsum',
		authorname: 'Reva Yokk',
		authorimage: Avatar7,
		aboutauthor: 'Marketing Manager',
		postedon: 'September 05, 2020',
		readinglength: 20,
		content: blogContent
	}
];

export default BlogArticles;
