import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import { store, persistor } from "./reducers/store";
import { PersistGate } from "redux-persist/integration/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
//Translate
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import ReactGA from "react-ga4";
import { ThemeProvider } from "react-bootstrap";

ReactGA.initialize("G-FXF42JZ8XS");
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

const container = document.getElementById("root");
const root = createRoot(container);
// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker
//     .register("/sw.js")
//     .then((registration) => {
//       console.log("Service Worker registered with scope:", registration.scope);
//     })
//     .catch((error) => {
//       console.error("Service Worker registration failed:", error);
//     });
// }
root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <PersistGate loading={null} persistor={persistor}>
        <GoogleOAuthProvider clientId="48688787863-9vtv1e1lmdkgeu5j9greud5rcjp1l5la.apps.googleusercontent.com">
          <ThemeProvider dir="rtl">
            <App />
          </ThemeProvider>
        </GoogleOAuthProvider>
      </PersistGate>
    </I18nextProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
