// import node module libraries
import React, { Fragment, useEffect, useState } from 'react';
import { Card, Table, Badge, Container, Modal, Dropdown, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// import data files
import britishFlag from "assets/images/flag/british.png";
import frenchFlag from "assets/images/flag/french.png";

// import profile layout wrapper
import ProfileLayout from 'components/marketing/student/ProfileLayout';
import ProfileCover from '../common/headers/ProfileCover';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { downloadInvoiceAction, getInvoiceByClientAction } from 'actions/invoicing';
import {  reformatSearchDateTime } from 'helper/utils';

const InvoiceReader = () => {
    const dispatch = useDispatch();
    const { invoices } = useSelector((state) => state.invoices);
    const {  user,  loading } = useSelector((state) => state.auth);
    const invoiceId = user.client_id_invoicing_api;
   
 
  
    useEffect(() => {
        dispatch(getInvoiceByClientAction(invoiceId))
    }, [])
    const handleDownloadInvoice = (id, lang) => {
      
        dispatch(downloadInvoiceAction({ id:id, lang:lang, name: user.username, email: user.email })).then(res=>{window.open(res, '_blank')})
    }
  

    const dashboardData = {
        name: "Stella Flores",
        username: "@stellaflores",
        linkname: "Account Setting",
        link: "/student/student-edit-profile",
        verified: false,
        outlinebutton: false,
        level: "Beginner",
    };
    return (
        <Fragment>
            <section className="pt-5 pb-5">
                <Container>
                    {/* User info */}
                    <ProfileCover dashboardData={dashboardData} />
                    <Card className="border-0" style={{ marginTop: "2%" }}>
                        <Card.Header>
                            <div className="mb-2 mb-lg-0 mt-1">
                                <h3 className="mb-0">Invoices</h3>
                                <p className="mb-0">You can find all of your order Invoices.</p>
                            </div>
                        </Card.Header>
                        <Card.Body className="p-0">
                            {/* Table */}
                            <div className="table-invoice border-0">
                                <Table
                                    hover
                                    responsive
                                    className="mb-0 text-nowrap table-centered "
                                >
                                    <thead className="table-light">
                                        <tr >
                                            <th style={{ backgroundColor: "#9785F7" }} scope="col">Order ID</th>
                                            <th style={{ backgroundColor: "#9785F7" }} scope="col">Date</th>
                                            <th style={{ backgroundColor: "#9785F7" }} scope="col">Amount</th>
                                            <th style={{ backgroundColor: "#9785F7" }} scope="col">Status</th>
                                            <th style={{ backgroundColor: "#9785F7" }} scope="col">Download</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoices?.map((item, index) => (
                                            <tr key={index}>

                                                <td>
                                                    <Link to={"/student/invoice_details/" + item._id}>
                                                        #{item.invoiceNumber}
                                                    </Link>
                                                </td>


                                                <td>
                                                    <Link to={"/student/invoice_details/" + item._id}>
                                                        {reformatSearchDateTime(item.createdAt)}
                                                    </Link>
                                                </td>


                                                <td>
                                                    <Link to={"/student/invoice_details/" + item._id}>
                                                        {item.total + item.currency}
                                                    </Link>
                                                </td>


                                                <td>
                                                    <Link to={"/student/invoice_details/" + item._id}>
                                                        <Badge bg={item.status === 'Unpaid' ? 'danger' : 'success'}>
                                                            {item.status}
                                                        </Badge>
                                                    </Link>
                                                </td>

                                                <td>

                                                    <Dropdown>
                                                        <Dropdown.Toggle className="fe fe-download" variant="primary">
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item>
                                                                <div style={{ display: "flex" }}>
                                                                    <Button
                                                                        onClick={() => handleDownloadInvoice(item._id, "en")}
                                                                        style={{ backgroundColor: "white", height: "50px", width: "70px", marginRight: "10px", backgroundImage: `url(${britishFlag})`, backgroundSize: "cover" }}
                                                                    >
                                                                        En
                                                                    </Button>
                                                                    <Button
                                                                        onClick={() => handleDownloadInvoice(item._id, "fr")}
                                                                        style={{ backgroundColor: "white", height: "50px", width: "70px", backgroundImage: `url(${frenchFlag})`, backgroundSize: "cover" }}
                                                                    >
                                                                        Fr
                                                                    </Button>
                                                                </div>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </Card.Body>
                    </Card>
                </Container>
            </section>
        </Fragment >
    );
};

export default InvoiceReader;
