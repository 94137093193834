// import node module libraries
import { Col, Row, Form, Button } from "react-bootstrap";

//translate component
import {  useTranslation } from 'react-i18next';

// import custom components
import { FormSelect } from "components/elements/form-select/FormSelect";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { sendMessageAction } from "actions/contact";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
const ContactForm = () => {
  const { t } = useTranslation();
  //   const contactReason = [
  //     { value: "Design", label: "Design" },
  //     { value: "Development", label: "Development" },
  //     { value: "Other", label: "Other" },
  //   ];
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const reason = searchParams.get("reason");
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [message, setMessage] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    if (reason == "teacher")
      setMessage({ ...message, subject: "Become Instructor" });
  }, [reason]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (
      message.first_name !== "" &&
      message.email !== "" &&
      message.last_name !== "" &&
      message.subject !== "" &&
      message.message !== ""
    ) {
      dispatch(sendMessageAction(message))
        .then((res) => {
          toast.success(res?.message, {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setMessage({
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          });
        })
        .catch((error) =>
          toast.error(error, {
            autoClose: 1000,
          })
        );
    } else {
      toast.error("Please fill  all required fields", { autoClose: 1000 });
    }
  };

  return (
    <div className="px-4 px-xl-20 py-8 py-lg-0">
      {/* form section */}
      <div id="form">
        <Form>
          <Row>
            {/* First Name */}
            <Col md={6} sm={12}>
              <Form.Group className="mb-3" controlId="formFirstName">
                <Form.Label>
                  {t("first_name")}<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("first_name")}
                  required
                  value={message.first_name}
                  onChange={(e) =>
                    setMessage({ ...message, first_name: e.target.value })
                  }
                />
              </Form.Group>
            </Col>

            {/* Last Name */}
            <Col md={6} sm={12}>
              <Form.Group className="mb-3" controlId="formLastName">
                <Form.Label>
                  {t("last_name")}<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("last_name")}
                  required
                  value={message.last_name}
                  onChange={(e) =>
                    setMessage({ ...message, last_name: e.target.value })
                  }
                />
              </Form.Group>
            </Col>

            {/* Email */}
            <Col md={6} sm={12}>
              <Form.Group className="mb-3" controlId="formEmail">
                <Form.Label>
                  {t("email")}<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder={t("email")}
                  required
                  value={message.email}
                  onChange={(e) =>
                    setMessage({ ...message, email: e.target.value })
                  }
                />
              </Form.Group>
            </Col>

            {/* Phone Number */}
            <Col md={6} sm={12}>
              <Form.Group className="mb-3" controlId="formFirstName">
                <Form.Label>
                  {t("phone_number")}<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("phone_number")}
                  required
                  value={message.phone}
                  onChange={(e) =>
                    setMessage({ ...message, phone: e.target.value })
                  }
                />
              </Form.Group>
            </Col>

            {/* Contact Reason */}
            <Col md={12} sm={12}>
              <Form.Group className="mb-3" controlId="formContactReason">
                <Form.Label>
                  {t("contact_reason")}<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("contact_reason")}
                  required
                  value={message.subject}
                  onChange={(e) =>
                    setMessage({ ...message, subject: e.target.value })
                  }
                />
              </Form.Group>
            </Col>

            {/* Messages */}
            <Col md={12} sm={12}>
              <Form.Group className="mb-3" controlId="formMessages">
                <Form.Label>{t("message")}</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder={t("message")}
                  rows={3}
                  value={message.message}
                  onChange={(e) =>
                    setMessage({ ...message, message: e.target.value })
                  }
                />
              </Form.Group>
            </Col>

            {/* button */}
            <Col md={12} sm={12}>
              <Button
                variant="primary"
                type="submit"
                disabled={loading}
                onClick={(e) => handleSendMessage(e)}
              >
                {t("btn_submit")}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};
export default ContactForm;
