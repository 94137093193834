import { v4 as uuid } from "uuid";

const NavbarHome = [
  {
    id: uuid(),
    menuitem: 'reading_pack',
    link: "/reading-packs",
    icon:"book",
   
  },


  {
    id: uuid(),
    menuitem: 'about_us',
    link: "/iread/about/",
    icon:"users",
   
  },
  {
    id: uuid(),
    icon:"message-square",
    menuitem: 'contact_us',
    link: "/contact-us",
  },
  // {
  //   id: uuid(),
  //   menuitem: "Quizs",
  //   link: "/quiz/quiz_list",
  // },
];

export default NavbarHome;
