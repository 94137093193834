// Section : Features
// Style : Three Columns Features Section

// import node module libraries
import { Card } from "react-bootstrap";
//translate component
import {  useTranslation } from 'react-i18next';

// import MDI icons
import Icon from "@mdi/react";

const FeatureTopIconCard = ({ item }) => {
  const { t } = useTranslation();
  return (
    <Card className="mb-4">
      <Card.Body className="p-5" style={{ height: "250px" }}>
        <div className="mb-3 ">
          <Icon path={item.icon} size={2} className="text-primary" />
        </div>
        <h3 className="mb-2">{t(item.title)}</h3>
        <p className="mb-0">{t(item.description)}</p>
      </Card.Body>
    </Card>
  );
};
export default FeatureTopIconCard;
