// import node module libraries
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, Container, Image, Form, Card, Button } from 'react-bootstrap';

// import custom components
import FeatureTopIconWithLink from 'components/marketing/common/features/FeatureTopIconWithLink';

// import media files
import ThreeDGirlSeeting from 'assets/images/svg/3d-girl-seeting.svg';

// import data files
import HelpCenterFeaturesData from 'data/marketing/help-center/HelpCenterFeaturesData';
//translate component
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AutocompleteGuide from 'components/marketing/instructor/AutoCompleteGuide';


const HeroGradientHeader = () => {
	const [selectedOption, setSelectedOption] = useState();
	const [selectedId, setSelectedId] = useState();
	useEffect(() => {
		if (selectedOption?.length > 0) {
			const selectedGuide = selectedOption[0];
			if (selectedGuide?.guides && selectedGuide?.guides?.length > 0) {
				const selectedGuideId = selectedGuide.guides[0]._id;
				setSelectedId(selectedGuideId);
			} else {
				console.error("Selected guide does not have a 'guides' array or it's empty.");
			}
		}
	}, [selectedOption]);
	const selectedLanguage = localStorage.getItem('selectedLanguage')
	const { t } = useTranslation();
	return (
		<Fragment>
			<section className="py-lg-1 py-10 ">
				<Container>
					<Row className="align-items-center justify-content-center">
						<Col md={6} xs={12}>
							<h1 className="fw-bold mb-0 display-3">{t("who_can_help")}</h1>
							<p className="mb-5 text-dark ">
								{t("have_question")}
							</p>
							<div className="pe-md-6">
								<AutocompleteGuide
									selectedOption={selectedOption}
									setSelectedOption={setSelectedOption}
								/>
							</div>
							{selectedId && selectedOption && (
								<span className=" mt-2 d-block">
									<Link to={`https://help-center.iread.tn/help-center/guide-single/${selectedId}&lng=${selectedLanguage}`} className="btn btn-primary" target="_blank">
										{t("more_details")}
									</Link>
								</span>
							)}
							<span className=" mt-2 d-block">
								{t("or_choose_category")}
							</span>
							
						</Col>
						<Col md={6} xs={10}>
							<div className="d-flex align-items-center justify-content-end">
								<Image
									src={ThreeDGirlSeeting}
									alt=""
									className="text-center img-fluid"
								/>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="mt-n8">
				<Container>
					<Card className="rounded-3 shadow-lg">
						<Row>
							{HelpCenterFeaturesData.map((item, index) => {
								return (
									<Col
										md={4}
										xs={12}
										className={index === 0 ? '' : 'border-start-md'}
										key={index}
									>
										<FeatureTopIconWithLink
											item={item}
											className={
												HelpCenterFeaturesData.length - 1 === index
													? ''
													: 'border-bottom'
											}
										/>
									</Col>
								);
							})}
						</Row>
					</Card>
				</Container>
			</section>
		</Fragment>
	);
};
export default HeroGradientHeader;
