// Centered aligned section heading
// title, subtitle with description in centered aligned

// import node module libraries
import { Col, Row } from 'react-bootstrap';
//translate component
import {  useTranslation } from 'react-i18next';



const SectionHeadingCenter = ({ title, subtitle, description, color }) => {
	const { t } = useTranslation();
	return (
		<Row >
			<Col lg={8} md={12} sm={12} >
				<span className=" mb-3 d-block text-uppercase fw-semi-bold ls-xl" style={{fontSize:"18px", marginTop:"3%", color:color}}>
					{t(subtitle)}
				</span>
				<h3 className="mb-2 display-4 fw-bold ">{t(title)}</h3>
				<p className="lead">{description}</p>
			</Col>
		</Row>
	);
};

export default SectionHeadingCenter;
