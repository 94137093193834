// import node module libraries
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
//translate component
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getQuizAnswerAction } from "actions/quizs";
import { useSelector } from "react-redux";
import ApexCharts from "components/elements/charts/ApexCharts";

const QuizFinish = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const session_id = searchParams.get("session_id");
  const book_id = searchParams.get("book_id");
  const quiz = searchParams.get("quiz_id");
  const user = searchParams.get("user_id");

  const { result } = useSelector((state) => state.quizs);
  useEffect(() => {
    dispatch(getQuizAnswerAction({ user, quiz }));
  }, [dispatch, quiz, user]);

  const maxScore = result?.max_score;
  const userScore = result?.score;
  const score = Math.round(result?.percentage);
  const userPassedQuiz = result?.success;
  const QuizResultChartSeries = [score];
  const QuizResultChartOptions = {
    colors: userPassedQuiz ? ["#38a169"] : ["#F1170D"],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: -2,
          size: "50%",
          background: userPassedQuiz ? "#d1f5ea" : "#FF9B88",
        },
        dataLabels: {
          name: { show: true, fontSize: "18px", fontWeight: 600, offsetY: 7 },
          value: { show: false },
        },
      },
    },
    labels: [score + "%"],
  };

  return (
    <Card>
      <Card.Body className="p-10 text-center">
        {userPassedQuiz ? (
          <div className="mb-4">
            <h2>{t("congratulations_you")}</h2>
            <p className="mb-0 px-lg-14">{t("you_have_succes")}</p>
          </div>
        ) : (
          <div className="mb-4">
            <h2>{t("sorry_you_failed")}</h2>
            <p className="mb-0 px-lg-14">{t("you_did_pass")}</p>
          </div>
        )}
        <div className="d-flex justify-content-center">
          <div className="resultChart">
            <ApexCharts
              options={QuizResultChartOptions}
              series={QuizResultChartSeries}
              type="radialBar"
              height={150}
            />
          </div>
        </div>
        <div className="mt-3">
          <span>
            {t("your_score")}{" "}
            <span className="text-dark">
              {" "}
              {t("is")} ({userScore} {t("points")}) {t("out_of")} ({maxScore}{" "}
              {t("points")})
            </span>
          </span>
          <br />
        </div>
        <div className="mt-5">
          <Button
            onClick={() =>
              navigate(
                "/instructor/quiz/" + book_id + "?session_id=" + session_id
              )
            }
            className="btn btn-primary"
          >
            {t("finish")}
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default QuizFinish;
