// import node module libraries
import { Col, Row } from "react-bootstrap";

//translate component
import {  useTranslation } from 'react-i18next';

const HeroContent = () => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col lg={{ span: 8, offset: 2 }} md={12} sm={12} className="mb-12">
        {/* caption */}
        <h1 className="display-2 fw-bold mb-3">
          {t("hi_there")} <span className="text-primary">{t("iread")}</span>
        </h1>
        {/* para  */}
        <p className="h2 mb-3 ">
        {t("crafting_the_ultimate")}
        </p>
        <p className="mb-0 h4 text-body lh-lg">
        {t("iread_offers")}
        </p>
      </Col>
    </Row>
  );
};
export default HeroContent;
