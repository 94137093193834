import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
//translate component
import {  useTranslation } from 'react-i18next';

 
const FollowSession = ({ show, onHide, handleFollowSession, checkPack, selectedEvent, ...rest }) => {
  const { is_authenticated, loading } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const renderModalContent = () => {
    if (!is_authenticated) {
      return (
        <p>
          {t("kindly_log_in")}
        </p>
      );
    }

    if (!checkPack) {
      return (
        <p>
          {t("your_pack_subscription")}
        </p>
      );
    }

    return (
      <p>
        {t("are_you_certain")}Are you certain you wish to register for this session?
      </p>
    );
  };

  return (
    <Modal show={show} onHide={onHide} {...rest}  centered>
      <Modal.Header closeButton className="border-bottom">
        <Modal.Title>{t("follow_session")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={(e) => handleFollowSession(e)}>
          <div className="mt-3">
            {renderModalContent()}

            {is_authenticated && checkPack === true && (
             <Button type="submit" variant="primary" id="add-new-event-btn">
             {loading ? (
               <Spinner animation="border" variant="light" className="me-2" size="sm" />
             ) : (
               <span>{t("confirm")}</span>
             )}
           </Button>
            )}

            {is_authenticated && checkPack === false && (
              // Display nothing for pending pack approval
              null
            )}

            {is_authenticated && !checkPack && (
              <Link to={"/courses/course-single/" + selectedEvent?.extendedProps?.pack_id}>
                <Button type="submit" variant="primary" id="add-new-event-btn">
                {t("follow_pack")}
                </Button>
              </Link>
            )}

            {!is_authenticated && (
              <Link to="/authentication/sign-in">
                <Button type="submit" variant="primary" id="add-new-event-btn">
                {t("login")}
                </Button>
              </Link>
            )}

            <Button className="ms-2" variant="danger" onClick={() => onHide()}>
            {t("btn_cancel")}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default FollowSession;
