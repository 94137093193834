// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import {
    Col,
    Row,
    Breadcrumb,
    Card,
    Button,
    Form,
    InputGroup,
    FormControl,
    ListGroup,
    Image,
    Badge,
    Table,
    Container
} from 'react-bootstrap';

// import custom components
import DotBadge from 'components/elements/bootstrap/DotBadge';

// import media files

import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getInvoiceAction } from 'actions/invoicing';
import { getStatusColor, reformatSearchDate } from 'helper/utils';
import ProfileCover from '../common/headers/ProfileCover';
const InvoiceReaderDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { invoice } = useSelector((state) => state.invoices)
    
    const { user } = useSelector((state) => state.auth)
    const [clientId, setClientId] = useState();
const currency = invoice?.currency;
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    useEffect(() => {
        dispatch(getInvoiceAction(id))
        setClientId(invoice.client)
    }, [id,dispatch]);

    const dashboardData = {
        name: "Stella Flores",
        username: "@stellaflores",
        linkname: "Account Setting",
        link: "/student/student-edit-profile",
        verified: false,
        outlinebutton: false,
        level: "Beginner",
    };

    return (
        <Fragment>
            <section className="pt-5 pb-5">
                <Container>
                    {/* User info */}
                    <ProfileCover dashboardData={dashboardData} />
                    {/*  List group  */}
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={() => navigate("/student/invoice/")} variant="primary" style={{ marginBottom: "15px", marginTop: "15px" }}>
                            Back to invoices list
                        </Button>
                    </div>
                    <Card className="mt-6 mt-lg-0 mb-4" style={{ marginTop: "100px", width: isMobile ? '110%' : '100%', marginLeft: isMobile ? '-5%' : '0' }}>
                        <Card.Header style={{ backgroundColor: "#9785f7", marginRigth: isMobile ? '15%' : '0' }} >
                            <h4 className="mb-0" style={{ fontSize: "40px", color: "white", fontWeight: "bold" }}>Receipt</h4>
                            <div className="d-flex align-items-center justify-content-end">
                                <span style={{ fontSize: "15px", color: "white" }}>No° : </span>
                                <h5 style={{ marginTop: "7px", marginLeft: "1%", color: "white" }}> {invoice?.invoiceNumber}</h5>
                            </div>
                        </Card.Header>
                        <Card.Body className="p-0">
                            {/*  List group  */}
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div style={{ marginLeft: isMobile ? '1%' : '0' }}>
                                            <span className="text-body">Bill to :</span>
                                            <h5>{user?.username}</h5>
                                            <h5>{user?.email}</h5>
                                            {/* <h5>{user?.phone_number}</h5> */}
                                            {/* <h5>{user?.adress}</h5> */}
                                        </div>
                                        <div>
                                            <span className="text-body">Status</span>
                                            <h5 >
                                                <DotBadge bg={invoice?.status === 'Paid' ? 'success' : 'Unpaid' ? 'danger' : 'warning'}>
                                                    <span
                                                        className={`badge bg-light-${getStatusColor(
                                                            invoice?.status
                                                        )} text-dark-${getStatusColor(invoice?.status)}`}
                                                    >
                                                        {invoice?.status}
                                                    </span>
                                                </DotBadge>
                                            </h5>


                                            <span className=" text-body">Created at </span>
                                            <h5 style={{ marginLeft: "2%", marginTop: "7px" }} >{reformatSearchDate(invoice?.createdAt)}</h5>
                                            <span className=" text-body">Due Date </span>
                                            <h5 style={{ marginLeft: "2%", marginTop: "7px" }} >{reformatSearchDate(invoice?.createdAt)}</h5>
                                            <span className=" text-body">Amount</span>
                                            <h5 style={{ marginLeft: "2%", marginTop: "7px" }} >{invoice?.total + currency}</h5>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item >
                                    <div className="mt-4 mb-4" style={{ marginLeft: isMobile ? '1%' : '0' }}>
                                        <Table striped hover>
                                            <thead >
                                                <tr>
                                                    <th style={{ backgroundColor: "#9785f7" }}>Item</th>
                                                    <th style={{ backgroundColor: "#9785f7" }}>Quantity</th>
                                                    <th style={{ backgroundColor: "#9785f7" }}>Price</th>
                                                    <th style={{ backgroundColor: "#9785f7" }}>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoice?.items?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.productId.title}</td>
                                                        <td>{item.quantity}</td>
                                                        <td>{item.productId.price + currency} </td>
                                                        <td>{(item.quantity * item.productId.price).toFixed(2) + currency}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item style={{ marginLeft: isMobile ? '0' : '35%' }}>
                                    <div className="mt-6 mt-lg-0 mb-4">
                                        <Table striped hover>
                                            <thead >
                                                <tr>
                                                    <th style={{ backgroundColor: "#9785f7" }}>Invoice Summary</th>
                                                    <th style={{ backgroundColor: "#9785f7" }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Subtotal: </td>
                                                    <td>{invoice?.subTotal + currency}</td>
                                                </tr>
                                                <tr>

                                                    <td>VAT({currency}): </td>
                                                    <td>{invoice.vat}%</td>
                                                </tr>
                                                <tr>

                                                    <td>Total </td>
                                                    <td>{invoice?.total + currency}</td>
                                                </tr>
                                                <tr>

                                                    <td>Paid </td>
                                                    <td> {invoice?.totalAmountReceived + currency}</td>
                                                </tr>
                                                {/* <tr>
                                                    <td> Rest</td>
                                                    <td style={{ fontWeight: "bold", fontSize: "20px" }}>{(invoice?.total - invoice?.totalAmountReceived) + currency}</td>
                                                </tr> */}

                                            </tbody>
                                        </Table>
                                    </div>
                                </ListGroup.Item>
                                {invoice?.paymentRecords && (
                                    <ListGroup.Item className="mt-6 mt-lg-0 mb-4">

                                        <div>
                                            {invoice.paymentRecords.map((paymentRecord, index) => (
                                                <div key={index}>
                                                    <h3 className="text-body"> Payment Records :</h3>
                                                    <h5>{`Amount Paid: ${paymentRecord.amountPaid} ${currency}`}</h5>
                                                    <h5>{`Date Paid: ${paymentRecord.datePaid}`}</h5>
                                                    <h5>{`Payment Method: ${paymentRecord.paymentMethod}`}</h5>
                                                    <h5>{`Note: ${paymentRecord.note}`}</h5>
                                                </div>
                                            ))}
                                        </div>

                                    </ListGroup.Item>
                                )}
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Container>
            </section>
        </Fragment >
    );
};

export default InvoiceReaderDetails;
