import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import palestineFlag from "assets/images/flag/palestine-flag.png";
import britishFlag from "assets/images/flag/british_flag.png";
import frenchFlag from "assets/images/flag/french_flag.png";
import { useLocation } from 'react-router-dom';

function LanguageSwitchPhone({ setSelectedLanguage }) {
  const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const ireadLng = searchParams.get("lng");
  const { i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  
  useEffect(() => {
    // Charger la langue actuelle depuis le localStorage lors du montage
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      changeLanguage(savedLanguage);
    }
  }, []);
  useEffect(() => {
    if (ireadLng !== null && ireadLng !== undefined) {
      i18n.changeLanguage(ireadLng);
    setSelectedOption(languageItems.find((language) => language.code === ireadLng));
    setIsOpen(false);
    localStorage.setItem('selectedLanguage', ireadLng);
    document.documentElement.dir = ireadLng === 'ar' ? 'rtl' : 'ltr';
    }
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedOption(languageItems.find((language) => language.code === lng));
    setIsOpen(false);
    localStorage.setItem('selectedLanguage', lng);
    setSelectedLanguage(lng);
    document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
  };


  const languageItems = [
    { code: 'en', flagSrc: britishFlag, alt: 'English' },
    { code: 'fr', flagSrc: frenchFlag, alt: 'Français' },
    { code: 'ar', flagSrc: palestineFlag, alt: 'العربية' },
    // Add more languages as needed
  ];

  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        userSelect: 'none',
        cursor: 'pointer',
      }}
    >
      <div
        style={{
          padding: '10px',
          fontSize: '14px',
          borderRadius: '5px',
          border: '1px solid gray', 
          backgroundColor: '#fff',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width:"160%",
          color:"black",
          fontWeight:"initial",
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedOption ? (
          <>
            <img
              src={selectedOption.flagSrc}
              alt={selectedOption.alt}
              style={{
                width: '20px',
                height: '20px',
                marginRight: '10px',
              }}
            />
            {selectedOption.alt}
          </>
        ) : (
            "Select Language"
        )}
      </div>
      {isOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            fontSize: '15px',
            alignItems:"center",
            left: 0,
            zIndex: 1,
            borderRadius: '5px',
            border: '1px solid #ccc',
            backgroundColor: '#fff',
            marginTop: '5px',
            width:"160%",
            marginTop:"-180px",
            marginLeft: i18n.language === 'ar' ? '-50px' : '0',
          }}
        >
          {languageItems.map((language, index) => (
            <div
              key={language.code}
              style={{
                padding: '10px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                borderBottom: index !== languageItems.length - 1 ? '1px solid #ccc' : 'none',
              }}
              onClick={() => {
                changeLanguage(language.code);
                setSelectedOption(language);
              }}
            >
              <img
                src={language.flagSrc}
                alt={language.alt}
                style={{
                  width: '20px',
                  height: '20px',
                  marginRight: '10px',
                  marginLeft: '10px',
                }}
              />
              {language.alt}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitchPhone;