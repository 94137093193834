// import node module libraries
import { Fragment, } from "react";
import { Col, Row, Card, Tab, Breadcrumb } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import sub components
import SessionList from "./SessionsTable";

const AvailableSessions = () => {
  return (
    <Fragment>
      <Tab.Container defaultActiveKey="grid">
   

        <Tab.Content>
          <Tab.Pane eventKey="grid" className="pb-4">
            <SessionList/>
          </Tab.Pane>
          <Tab.Pane eventKey="list" className="pb-4">
            <Card className="mb-5 ">
              <Card.Body className="p-0">
              </Card.Body>
            </Card>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
      <ToastContainer />
    </Fragment>
  );
};
export default AvailableSessions;
