import React, { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css'; 
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchAllGuideAction } from 'actions/auth';
const AutocompleteGuide = ({selectedOption, setSelectedOption}) => {
    const { t } = useTranslation();
	const dispatch = useDispatch();
	const selectedLanguage = localStorage.getItem('selectedLanguage')
	const { guides } = useSelector(state => state.books);
    useEffect(() => {
        dispatch(fetchAllGuideAction())
      }, [dispatch])

  const handleSelection = (selected) => {
    if(selected.length> 0){
    setSelectedOption(selected);
}
  };
  const getLabelKey = () => {
    switch (selectedLanguage) {
      case 'en':
        return 'en_title';
      case 'fr':
        return 'fr_title';
      case 'ar':
        return 'ar_title';
      default:
        return 'en_title';
    }
  };

  const options =guides||[]
  


  return (
    <div className="mb-3">
      <Typeahead
        id="autocomplete-example"
        labelKey={getLabelKey()}
        options={options}
        placeholder={t("enter_a_question")}
        selected={selectedOption}
        onChange={handleSelection}
      />
    </div>
  );
};

export default AutocompleteGuide;