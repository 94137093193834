// import node module libraries
import { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Image,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

// import media files
import Logo from "assets/images/brand/logo/logo.png";
import { signInGoogleAction, signUpAction } from "actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import PasswordStrengthMeter from "components/elements/passwordstrength/PasswordStrengthMeter";
import zxcvbn from "zxcvbn";
import { jwtDecode } from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";
//translate component
import {  useTranslation } from 'react-i18next';


const SignUp = () => {
  const { t } = useTranslation();
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    username: "",
    email: "",
    password: "",
  });
  const testPwd = zxcvbn(user.password);
  //google
  const navigate = useNavigate();
  const handleGoogleLogin = (data) => {
    let { email, name } = jwtDecode(data);
    dispatch(signInGoogleAction({ email, username: name }, navigate));
  };
  const [terms, setTerms] = useState(false);

  const handleSignUp = async (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    e.preventDefault();
    if (user.username !== "" && user.email !== "" && user.password !== "") {
      if (emailRegex.test(user.email)) {
        if (terms) {
          if (testPwd.score > 1) {
            dispatch(signUpAction(user));
            setUser({
              username: "",
              email: "",
              password: "",
            });
          } else {
            toast.error("Password is weak", {
              autoClose: 1000,
            });
          }
        } else {
          toast.error("Please accept the Terms of Use.", {
            autoClose: 1000,
          });
        }
      } else {
        toast.error("Email is not valid", { autoClose: 1000 });
      }
    } else {
      toast.error("Please fill  all required fields", { autoClose: 1000 });
    }
  };

  return (
    <Fragment>
      <Row className="align-items-center justify-content-center g-0 min-vh-100">
        <Col lg={5} md={5} className="py-8 py-xl-0">
          <Card>
            <Card.Body className="p-6">
              <div className="mb-4">
                <Link to="/">
                  <Image
                    src={Logo}
                    style={{ maxWidth: "120px" }}
                    className="mb-4"
                    alt=""
                  />
                </Link>

                <h1 className="mb-1 fw-bold">{t("sign_up")}</h1>
                <span>
                  {t("already_have_acc")}{" "}
                  <Link to="/authentication/sign-in" className="ms-1">
                    {t("sign_in")}
                  </Link>
                </span>
              </div>
              {/* Form */}
              <Form>
                <Row>
                  <Col lg={12} md={12} className="mb-3">
                    {/* User Name */}
                    <Form.Label>{t("user_name")}</Form.Label>
                    <Form.Control
                      type="text"
                      id="username"
                      placeholder={t("user_name")}
                      required
                      value={user.username}
                      onChange={(e) =>
                        setUser({ ...user, username: e.target.value })
                      }
                    />
                  </Col>
                  <Col lg={12} md={12} className="mb-3">
                    {/* email */}
                    <Form.Label>{t("email")} </Form.Label>
                    <Form.Control
                      type="email"
                      id="email"
                      placeholder={t("email_adress_here")}
                      required
                      isInvalid={false}
                      value={user.email}
                      isValid={false}
                      onChange={(e) =>
                        setUser({ ...user, email: e.target.value })
                      }
                    />
                  </Col>
                  <Col lg={12} md={12} className="mb-3">
                    {/* Password */}
                    <Form.Label>{t("password")} </Form.Label>
                    <Form.Control
                      type="password"
                      id="password"
                      placeholder="**************"
                      required
                      value={user.password}
                      onChange={(e) =>
                        setUser({ ...user, password: e.target.value })
                      }
                    />

                    <Row className="align-items-center g-0">
                      <Col sm={6}>
                        <span
                          data-bs-toggle="tooltip"
                          data-placement="right"
                          title=""
                        >
                          {t("password_strength")}
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-top">
                               {t("test_if_by_typing")}
                              </Tooltip>
                            }
                          >
                            <i className="fas fa-question-circle ms-1"></i>
                          </OverlayTrigger>
                        </span>
                      </Col>
                    </Row>
                    <PasswordStrengthMeter password={user.password} />
                  </Col>
                  <Col lg={12} md={12} className="mb-3">
                    {/* Checkbox */}
                    <Form.Check type="checkbox" id="check-api-checkbox">
                      <Form.Check.Input
                        type="checkbox"
                        checked={terms}
                        onChange={(e) => setTerms(e.target.checked)}
                      />
                      <Form.Check.Label>
                       {t("i_agree_to_the")}
                        <Link to="/terms-and-conditions" target="_blank">
                           {t("terms_of_service")}{" "}
                        </Link>{" "}
                        {t("and")}{" "}
                        <Link to="/privacy-policy" target="_blank">
                         {t("privacy_policy_v2")}
                        </Link>
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                  <Col lg={12} md={12} className="mb-0 d-grid gap-2">
                    {/* Button */}
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={loading}
                      onClick={(e) => handleSignUp(e)}
                    >
                      {loading ? (
                        <Spinner
                          animation="border"
                          variant="light"
                          className="me-2"
                          size="sm"
                        />
                      ) : (
                        t("sign_up")
                      )}
                    </Button>
                  </Col>
                </Row>
              </Form>
              <hr className="my-4" />
              <div
                className="mb-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span style={{ marginBottom: "10px" }}> {t("or")} </span>
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    handleGoogleLogin(credentialResponse.credential);
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                  text="continue_with"
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </Fragment>
  );
};

export default SignUp;
