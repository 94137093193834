const InvoiceData = [
	{
		_id:"23131",
		invoiceNumber: 1008,
		createdAt: '17 April 2020, 10:45pm',
		total: 39,
		currency:"TND",
		status: 'Unpaid',
		pdf: 'invoiceFile.pdf'
	},
	{
		invoiceNumber: 1007,
		createdAt: '17 April 2020, 10:45pm',
		total: 39,
		currency:"TND",
		status: 'Paid',
		pdf: 'invoiceFile.pdf'
	},
	{
		invoiceNumber: 1006,
		createdAt: '17 Feb 2020, 10:45pm',
		total: 39,
		currency:"TND",
		status: 'Paid',
		pdf: 'invoiceFile.pdf'
	},
	{
		invoiceNumber: 1005,
		createdAt: '17 Jan 2020, 10:45pm',
		total: 39,
		currency:"TND",
		status: 'Paid',
		pdf: 'invoiceFile.pdf'
	},
	{
		invoiceNumber: 1004,
		createdAt: '17 Dec 2019, 10:45pm',
		total: 39,
		currency:"TND",
		status: 'Paid',
		pdf: 'invoiceFile.pdf'
	},
	{
		invoiceNumber: 1003,
		createdAt: '17 Nov 2019, 10:45pm',
		total: 39,
		currency:"TND",
		status: 'Paid',
		pdf: 'invoiceFile.pdf'
	},
	{
		invoiceNumber: 1002,
		createdAt: '17 Oct 2019, 10:45pm',
		total: 39,
		currency:"TND",
		status: 'Paid',
		pdf: 'invoiceFile.pdf'
	},
	{
		invoiceNumber: 1001,
		createdAt: '17 Sept 2019, 10:45pm',
		total: 39,
		currency:"TND",
		status: 'Paid',
		pdf: 'invoiceFile.pdf'
	}
];

export default InvoiceData;
