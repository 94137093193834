// import node module libraries
import { Fragment, useEffect } from "react";

// import sub components
import HeroFormRight from "./HeroFormRight";

// import layouts

// import data files

import { useDispatch } from "react-redux";
import { fetchOneBook, getAboutBookAction } from "actions/books";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
//translate component
import {  useTranslation } from 'react-i18next';

 
const BookIntro = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { book, about_book } = useSelector((state) => state.books);

  useEffect(() => {
    if (id) {
      dispatch(fetchOneBook(id));
      dispatch(getAboutBookAction(id));
    }
  }, [dispatch, id]);

  return (
    <Fragment>
      <main>
        <HeroFormRight book={book} />

        <Container>
          <Row className="bg-white">
            <Col lg={12} md={12} sm={12}>
             {about_book &&(<h1 className="fw-bold mb-3">{t("introductionV2")}</h1>)} 
              {about_book?.map((e) => (
                <div style={{ textAlign: "justify" }}>
                  <h2 className="fw-bold">{e?.title}:</h2>
                  <p className="fs-4 mb-4" style={{ whiteSpace: "pre-line" }}>
                    {e?.desc}
                  </p>
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      </main>
    </Fragment>
  );
};
export default BookIntro;
