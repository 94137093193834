// import node module libraries
import { Fragment } from "react";
import { Col, Row } from "react-bootstrap";

// import sub components
import SectionHeading from "./SectionHeading";

const Stat = () => {
  const title = 'our_fundamental';
  const description = 'at_iread_we_stand';

  const counters = [
    {
      id: 1,
      title: "Learners",
      value: "20M",
    },
    {
      id: 2,
      title: "Instructors",
      value: "57K",
    },
    {
      id: 3,
      title: "Courses",
      value: "21K",
    },
    {
      id: 4,
      title: "Course enrollments",
      value: "380M",
    },
  ];
  return (
    <Fragment>
      <SectionHeading title={title} description={description} />
    </Fragment>
  );
};

export default Stat;
