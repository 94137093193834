import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web

import AppConfigReducer from "./AppConfigReducer";
import authReducer from "./AuthReducer";
import booksReducer from "./BooksReducer";
import packsReducer from "./PacksReducer";
import quizsReducer from "./QuizsReducer";
import resultReducer from "./ResultReducer";
import invoicesReducer from "./InvoiceReducer";
import shcoolsReducer from"./ShcoolsReducer"

// Combine your reducers using combineReducers
const rootReducer = combineReducers({
  appConfig: AppConfigReducer,
  auth: authReducer,
  packs: packsReducer,
  books: booksReducer,
  quizs: quizsReducer,
  result: resultReducer,
  invoices: invoicesReducer,
  shcools:shcoolsReducer
});

// Configuration for Redux Persist
const persistConfig = {
  key: "root", // The key to use for storing the data in storage
  storage, // The storage engine to use (localStorage by default for web)
  // You can also configure other options like blacklist, whitelist, and more.
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(thunk));
const persistor = persistStore(store);
const clearStorageOnLogout = () => {
  persistor.purge();
};
export { store, persistor, clearStorageOnLogout };
