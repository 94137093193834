// import node module libraries
import { Link } from 'react-router-dom';
import { Col, Row, Container } from 'react-bootstrap';
//translate component
import {  useTranslation } from 'react-i18next';

// import custom components
import Ratings from 'components/marketing/common/ratings/Ratings';

// import custom components
import TestimonialsSlider from 'components/marketing/common/testimonials/TestimonialsSlider3';

const WhatCustomersSay = () => {
	const { t } = useTranslation();
	return (
		<section className="bg-gray-200 pt-14 pb-16">
			<Container className="container">
				<Row className="mb-10">
					<Col lg={{ span: 10, offset: 1 }} xs={12}>
						<Row className="align-items-center">
							<Col md={6}>
								<div className="">
									<div className="mb-3">
										<span className="text-dark fw-semi-bold">4.5/5.0</span>{' '}
										<span className="text-warning">
											<Ratings rating={4.5} />
										</span>{' '}
										<span className="ms-2">({t("rating_number")})</span>
									</div>
									<h2 className="h1">{t("staff_feedback")}</h2>
									<p className="mb-0">
									{t("hear_from_teacher")}
									</p>
								</div>
							</Col>
							{/* <Col md={6} className="text-md-end mt-4 mt-md-0">
								<Link to="/reviews_page" className="btn btn-primary">
									View Reviews
								</Link>
							</Col> */}
						</Row>
					</Col>
				</Row>
				<Row>
					<Col md={12}>
						<div className="position-relative">
							{/*  Testimonial slider */}
							<TestimonialsSlider />
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default WhatCustomersSay;
