// import node module libraries
import React, { useState } from "react";
import {
  Col,
  Row,
  Form,
  Card,
  OverlayTrigger,
  Tooltip,
  Button,
  Spinner,
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

// import custom components
import PasswordStrengthMeter from "components/elements/passwordstrength/PasswordStrengthMeter";

// import profile layout wrapper
import ProfileLayoutWrap from "./ProfileLayoutWrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeEmailAction, changePasswordAction } from "actions/auth";
import SubsLayout from "../student/SubsLayout";
//translate component
import {  useTranslation } from 'react-i18next';

 
const Security = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.auth);

  const location = useLocation();

  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [currentpassword, setCurrentPassword] = useState("");
  const [currentpassword2, setCurrentPassword2] = useState("");
  const [new_email, setNew_email] = useState();

  const handleChangeEmail = (e) => {
    e.preventDefault();
    dispatch(changeEmailAction({ new_email, password: currentpassword })).then(
      () => {
        setNew_email("");
        setCurrentPassword("");
      }
    );
  };
  const handleChangePassword = (e) => {
    e.preventDefault();
    if (password === confirmpassword) {
      dispatch(
        changePasswordAction({
          old_password: currentpassword2,
          new_password: password,
        })
      ).then(() => {
        setCurrentPassword2("");
        setPassword("");
        setConfirmPassword("");
      });
    } else {
      toast.error("Password doesn't match", { autoClose: 1000 });
    }
  };
  return (
    <SubsLayout pathpara={location.pathname}>
      <Card className="border-0">
        <Card.Header>
          <div className="mb-3 mb-lg-0">
            <h3 className="mb-0">{t("security")}</h3>
            <p className="mb-0">
            {t("edit_your_account")}
            </p>
          </div>
        </Card.Header>
        <Card.Body>
          <h4 className="mb-0">{t("email_adress")}</h4>
          <p>
          {t("your_current_email")}{" "}
            <span className="text-success">{user?.email}</span>
          </p>
          <Form>
            <Row>
              <Col lg={6} md={12} sm={12} className="mb-3">
                <Form.Group>
                  <Form.Label htmlFor="email">{t("new_email_adress")}</Form.Label>
                  <Form.Control
                    type="email"
                    id="email"
                    required
                    onChange={(e) => setNew_email(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="currentpassword">
                    {t("current_password")}
                  </Form.Label>
                  <Form.Control
                    type="password"
                    id="currentpassword"
                    value={currentpassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button
                  type="submit"
                  className="btn btn-primary mt-2"
                  onClick={(e) => handleChangeEmail(e)}
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner
                      animation="border"
                      variant="light"
                      className="me-2"
                      size="sm"
                    />
                  ) : (
                    t("update_details")
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
          <hr className="my-5" />
          <div>
            <h4 className="mb-0">{t("change_password")}</h4>
            <p>
              {t("we_will_email")}
            </p>
            {/* Form */}
            <Form>
              <Row>
                <Col lg={6} md={12} sm={12}>
                  {/* Current password */}

                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="currentpassword">
                      {t("current_password")}
                    </Form.Label>
                    <Form.Control
                      type="password"
                      id="currentpassword"
                      value={currentpassword2}
                      onChange={(e) => setCurrentPassword2(e.target.value)}
                      required
                    />
                  </Form.Group>

                  {/* New password */}
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="newpassword">{t("new_password")}</Form.Label>
                    <Form.Control
                      type="password"
                      id="newpassword"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Row className="align-items-center g-0">
                    <Col sm={6}>
                      <span
                        data-bs-toggle="tooltip"
                        data-placement="right"
                        title=""
                      >
                       {t("password_strength")}
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-top">
                            {t("test_if_by_typing")}
                            </Tooltip>
                          }
                        >
                          <i className="fas fa-question-circle ms-1"></i>
                        </OverlayTrigger>
                      </span>
                    </Col>
                  </Row>
                  <PasswordStrengthMeter password={password} />

                  {/* Confirm new password */}
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="confirmpassword">
                     {t("confirm_new_password")}
                    </Form.Label>
                    <Form.Control
                      type="password"
                      id="confirmpassword"
                      value={confirmpassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </Form.Group>
                  {/* Button */}
                  <Button
                    type="submit"
                    className="btn btn-primary"
                    onClick={(e) => handleChangePassword(e)}
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner
                        animation="border"
                        variant="light"
                        className="me-2"
                        size="sm"
                      />
                    ) : (
                      t("save_password")
                    )}
                  </Button>
                  <div className="col-6"></div>
                </Col>
                <Col lg={12} md={12} sm={12} className="mt-4">
                  <p className="mb-0">
                  {t("cant_remenber_your")}{" "}
                    <Link to="#">{t("reset_your_password")}</Link>
                  </p>
                </Col>
              </Row>
            </Form>
          </div>
        </Card.Body>
      </Card>
      <ToastContainer />
    </SubsLayout>
  );
};

export default Security;
