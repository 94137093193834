// import node module libraries
import { Fragment } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { Image, Card, ListGroup } from "react-bootstrap";

// import custom components
import LevelIcon from "components/marketing/common/miscellaneous/LevelIcon";

// import utility file

const PackCard = ({ item, free, extraclass, link }) => {
  /** Used in Course Index, Course Category, Course Filter Page, Student Dashboard etc...  */
  const GridView = () => {
    const navigate = useNavigate();
    return (
      <Card
        className={`mb-4 card-hover ${extraclass}`}
        onClick={() => navigate(`/courses/course-single/${item.id}`)}
      >
        <Image
          src={item.img}
          alt={item?.title}
          className="card-img-top rounded-top-md"
          style={{ height: "300px" }}
        />

        {/* Card body  */}
        <Card.Body>
          <h3 className="h4 mb-2 text-truncate-line-2 ">
            <Link to={link} className="text-inherit">
              {item?.title}
            </Link>
          </h3>
          <ListGroup as="ul" bsPrefix="list-inline" className="mb-3">
            <ListGroup.Item as="li" bsPrefix="list-inline-item">
              <i className="fas fa-book me-1"></i>
              {item.book_number} books
            </ListGroup.Item>
            <ListGroup.Item as="li" bsPrefix="list-inline-item">
              <LevelIcon level={"Advance"} />
              {item?.level}
            </ListGroup.Item>
          </ListGroup>

          <div
            className={`lh-1 mt-3 ${
              free ||
              item?.price === undefined ||
              item?.price <= 0 ||
              item?.discount === undefined
                ? "d-none"
                : ""
            }`}
          >
            <span className="text-dark fw-bold">
              {item?.price - item?.discount} Tnd
            </span>{" "}
            <del className="fs-6 text-muted">{item?.price} Tnd</del>
          </div>
        </Card.Body>
      </Card>
    );
  };

  return (
    <Fragment>
      <GridView />
    </Fragment>
  );
};

// Specifies the default values for props
PackCard.defaultProps = {
  free: false,
  viewby: "grid",
  showprogressbar: false,
  extraclass: "",
  link: "#",
};

// Typechecking With PropTypes
PackCard.propTypes = {
  item: PropTypes.object.isRequired,
  free: PropTypes.bool,
  viewby: PropTypes.string,
  showprogressbar: PropTypes.bool,
  extraclass: PropTypes.string,
  link: PropTypes.string,
};

export default PackCard;
