// import node module libraries
import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Container, Nav, Navbar } from "react-bootstrap";

// import custom components
import ProfileCover from "components/marketing/common/headers/ProfileCover";

// import routes file
import {
  DashboardMenu,
  AccountSettingsMenu,
} from "routes/marketing/InstructorDashboard";

// import media files
import Avatar1 from "assets/images/avatar/avatar-1.jpg";

const ProfileDashboard = (props) => {
  const location = useLocation();

  const dashboardData = {
    avatar: Avatar1,
    name: "Jenny Wilson",
    username: "@Jennywilson",
    linkname: "Create New Course",
    link: "/marketing/instructor/add-new-course/",
  };

  return (
    <Fragment>
      <section className="pt-5 pb-5">
        <Container>
          {/* User info */}
          <ProfileCover dashboardData={dashboardData} />

          {/* Content */}
          <Row className="mt-0 mt-md-4">
            <Col lg={12} md={8} sm={12}>
              {props.children}
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};
export default ProfileDashboard;
