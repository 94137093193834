import { getSessionById, registerSession } from "api";
import { toast } from "react-toastify";
import { GET_SESSION, LOADING } from "./constants";

export const followSession =
  (session, navigate, handleCloseEventOffcanvas) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });

      const data = await registerSession(session.publicId);
      // await dispatch({ type: PING });
      toast.success(data.data.message, {
        onClose: () => {
          handleCloseEventOffcanvas();
          window.location.reload();
        },
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      // dispatch({ type: GET_ONE_BOOK, payload: data?.data });

      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
  export const fetchSessionAction = (id) => async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
  
      const {data} = await getSessionById(id);
      dispatch({ type: GET_SESSION, payload: data.session });
  
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) {
        console.log(error.response.data.message);
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
