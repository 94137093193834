// import node module libraries
import React, { Fragment, useEffect, useState } from "react";
import { Form, Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Table,
  Dropdown,
  Image,
  Badge,
  Container,
  ListGroup,
  Accordion,
  useAccordionButton,
  Button,
  Spinner,
  Modal,
} from "react-bootstrap";
import {
  Dot,
  Flag,
  FlagFill,
  Search as SearchIcon,
} from "react-bootstrap-icons";
// import custom components
import StatRightBadge from "components/marketing/common/stats/StatRightBadge";
import ApexCharts from "components/elements/charts/ApexCharts";

// import data files
import BestSellingCoursesData from "data/marketing/BestSellingCoursesData";
import {
  EarningsChartSeries,
  EarningsChartOptions,
  OrderColumnChartSeries,
  OrderColumnChartOptions,
} from "data/charts/ChartData";
import BlogArticlesList from "data/blog/blogArticlesData";
// import profile layout wrapper
import ProfileLayout from "./ProfileLayout";
import ProfileDashboard from "./ProfileDashboard";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  fetchAllResultsFromOneReaderAction,
  fetchUserDetailsAction,
} from "actions/quizs";
import SectionHeadingCenter from "../common/section-headings/SectionHeadingCenter";
import CourseDescriptionCard from "../landings/course-lead/CourseDescriptionCard";
import { CourseDescriptionList } from "data/marketing/LeadCourseData";
import CourseDescSettingCard from "../landings/course-lead/CourseDescSettingCard";
import HeroGradientHeader from "../iread-help-center/help-center/HeroGradientHeader";
import BlogCard from "../blog/BlogCard";
import {
  createAccountAction,
  getAccountsAction,
  selectAccountAction,
} from "actions/auth";
import { useTranslation } from "react-i18next";
import { cefrData } from "data/cefr";
import CefrCard from "../common/cards/CefrCard";
import { Avatar } from "components/elements/bootstrap/Avatar";
import FlagImage from "assets/images/avatar/51291.png";
import schoolImage from "assets/images/png/school.png";
const ReaderDashboard = () => {
  const { t } = useTranslation();
  const title = "Ensure a Seamless Experience";
  const subtitle = "account_setup_reminder";
  const description = `Complete your account setup by confirming email and customizing preferences for an optimized journey on our platform. Don't miss out on personalized benefits!`;
  const dispatch = useDispatch();
  const school_id = localStorage.getItem("school_id");
  const { resultsReader, userDetails } = useSelector((state) => state.quizs);
  const {
    user,
    loading,
    profile: userProfile,
    accounts,
    followed_pack_list,
    followed_sessions,
    is_authenticated,
  } = useSelector((state) => state.auth);
  const user_id = user.quiz_id;
  // const user_id = "6597d979f32d372eec374e6b";
  useEffect(() => {
    dispatch(fetchUserDetailsAction(user_id));
    dispatch(fetchAllResultsFromOneReaderAction(user_id));
  }, [dispatch, user_id]);
  const quizTitles =
    resultsReader?.filter((e) => e.quiz)?.map((quiz) => quiz?.quiz?.title) ||
    [];
  const percentages =
    resultsReader?.filter((e) => e.quiz)?.map((quiz) => quiz?.percentage) || [];
  /* Calcule le total score for this reader */
  const totalMaxScore = Math.round(
    (userDetails?.totalScore / userDetails?.totalPercentage) * 100
  );

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn-icon btn btn-ghost btn-sm rounded-circle"
    >
      {children}
    </Link>
  ));
  useEffect(() => {
    if (user) {
      dispatch(getAccountsAction());
    }
  }, [dispatch, user]);

  const [newAccount, setNewAccont] = useState({
    username: "",
    password: "",
  });
  const [showSchools, setShowSchools] = useState([false]);
  const handleCreateAccount = (e) => {
    e.preventDefault();
    dispatch(createAccountAction(newAccount, setNewAccont));
  };
  const handleCloseSchools = () => {
    localStorage.setItem("school_id", user.schools[0]?.id);
    window.location.reload();
  };
  const ContextAwareToggle = ({ children, eventKey, callback }) => {
    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );
    return (
      <Fragment>
        <Link
          to="#"
          onClick={decoratedOnClick}
          className="d-flex align-items-center text-inherit text-decoration-none  mb-0"
          data-bs-toggle="collapse"
          style={{ marginTop: "3%" }}
          aria-controls={`collapse-${eventKey}`} // Use unique IDs for aria-controls
        >
          <Col style={{ position: "relative" }}>
            <Card
              style={{
                padding: "3% 0",
                borderLeft: `10px solid ${children.color}`,
                borderRadius: "8px  8px 0 0",
              }}
            >
              <Card.Body>
                <div className="mb-3 mb-lg-0">
                  <h3
                    className="mb-0 text-uppercase"
                    style={{ color: children.color }}
                  >
                    {children.title}
                  </h3>
                  <p className="mb-0">{children.desc}</p>
                </div>
              </Card.Body>
            </Card>
            <span
              className="chevron-arrow ms-5 "
              style={{ position: "absolute", right: "50%", bottom: 5 }}
            >
              <i
                className="fe fe-chevron-down "
                style={{ fontSize: "25px ", color: `${children.color}` }}
              ></i>
            </span>
          </Col>
        </Link>
      </Fragment>
    );
  };
  // accounts

  const handleSlecetAccount = (formdata) => {
    dispatch(selectAccountAction(formdata));
  };
  const handleCloseAccount = () => localStorage.setItem("account", user);
  const account = localStorage.getItem("account");
  const levelData = [
    { level: 1, desc: "Beginner", status: "finished" },
    { level: 2, desc: "Intermediate", status: "finished" },
    { level: 3, desc: "Advanced", status: "finished" },
    { level: 4, desc: "Expert", status: "finished" },
    { level: 5, desc: "Master", status: "current" },
    { level: 6, desc: "Guru", status: "locked" },
    { level: 7, desc: "Legend", status: "locked" },
  ];
  useEffect(() => {
    if (
      !user?.schools?.map((e) => e.id).includes(Number(school_id)) &&
      is_authenticated &&
      user?.schools?.length > 1 &&
      account
    ) {
      setShowSchools(true);
    } else {
      setShowSchools(false);
    }
  }, [user, school_id]);

  return (
    <ProfileDashboard>
      <Modal
        show={!account && is_authenticated && accounts?.length > 1}
        onHide={handleCloseAccount}
        size="md"
      >
        <Modal.Body>
          <Table className="text-nowrap">
            <tbody>
              {accounts?.map((account, index) => (
                <tr
                  style={{ cursor: "pointer", marginTop: "15px" }}
                  key={index}
                  onClick={() =>
                    handleSlecetAccount({
                      username: account.username,
                      email: account.email,
                    })
                  }
                >
                  <td>
                    <Avatar
                      size="md"
                      type="image"
                      src={account.img}
                      className="rounded-circle"
                      alt="G K"
                    />
                  </td>
                  <th scope="row">{account.username}</th>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      <Modal onHide={handleCloseSchools} size="md" show={showSchools}>
        <Modal.Body>
          <Table className="text-nowrap">
            <tbody>
              {user?.schools?.map((school, index) => (
                <tr
                  style={{ cursor: "pointer", marginTop: "15px" }}
                  key={index}
                  onClick={() => {
                    localStorage.setItem("school_id", school.id);
                    window.location.reload();
                  }}
                >
                  <td>
                    <Avatar
                      size="md"
                      type="image"
                      src={schoolImage}
                      className="rounded-circle"
                      alt="G K"
                    />
                  </td>
                  <th scope="row">{school.name}</th>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      {/* Page Content section */}
      {/* <Row>
				<Col lg={4} md={12} sm={12} className="mb-4 mb-lg-0">
					<StatRightBadge
						title="My Position in the Rankings"
						 subtitle={"You are ranked " + userDetails?.rank + " out of all 900 readers."} 
						// value="467"
                        value={userDetails?.rank}
						
					/>
				</Col>
				<Col lg={4} md={12} sm={12} className="mb-4 mb-lg-0">
					<StatRightBadge
						title="My Performance Score"
						subtitle={"Score: " + userDetails?.totalScore + " out of " + totalMaxScore }
						// value="12"
                         value={userDetails?.totalScore}
						
					/>
				</Col>
				<Col lg={4} md={12} sm={12} className="mb-4 mb-lg-0">
					<StatRightBadge
						title="My Performance Percentage"
						 subtitle="My Overall Achievement Rate"
						// value="50%"
                        value={userDetails?.totalPercentage + '%'}
						
					/>
				</Col>
			</Row> */}

      {/* <Card className="my-4">
				<Card.Header>
					<h3 className="h4 mb-0">QUIZ Progress</h3>
				</Card.Header>
				<Card.Body>
					<ApexCharts
                        options={{...OrderColumnChartOptions,xaxis:{...OrderColumnChartOptions.xaxis,categories:quizTitles} }}
						series={[{ data: percentages, ...OrderColumnChartSeries }]}
						height={300}
						type="bar"
					/>
				</Card.Body>
			</Card> */}

      <Card className="my-4">
        {/* <Card.Header style={{ borderLeft: "10px solid #0EA5E9" ,borderRadius: "0 0 0 8px"}}>
				
				</Card.Header> */}
        <Card.Body
          style={{ borderLeft: "10px solid #0EA5E9", borderRadius: "8px" }}
        >
          <Row>
            <SectionHeadingCenter color={"#0EA5E9"} subtitle={subtitle} />

            <Col lg={6} sm={12}>
              <CourseDescriptionCard item={CourseDescriptionList[0]} />
            </Col>
            <Col lg={6} sm={12}>
              <CourseDescSettingCard item={CourseDescriptionList[1]} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Accordion style={{ marginBottom: "3%" }}>
        <ContextAwareToggle eventKey="3">
          {{
            title: t("accounts"),
            desc: t("accounts_managment"),
            color: "#F8C709",
          }}
        </ContextAwareToggle>
        <Accordion.Collapse eventKey="3">
          <Card className="border-0" style={{ borderRadius: "0 0 8px 8px" }}>
            <Card.Body
              style={{
                borderLeft: "10px solid #F8C709",
                borderRadius: "0 0 0 8px",
              }}
            >
              <Table className="text-nowrap">
                <tbody>
                  {accounts?.map((account, index) => (
                    <tr
                      style={{ cursor: "pointer", marginTop: "15px" }}
                      key={index}
                    >
                      <td>
                        <Avatar
                          size="md"
                          type="image"
                          src={account?.img}
                          className="rounded-circle"
                          alt="G K"
                        />
                      </td>
                      <th scope="row">{account?.username}</th>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {accounts?.length < 3 && (
                <form>
                  <div className="mb-3">
                    {/* User name */}
                    <label htmlFor="username">{t("user_name")}</label>
                    <input
                      type="text"
                      id="username"
                      className="form-control"
                      placeholder={t("user_name")}
                      required
                      onChange={(e) =>
                        setNewAccont({
                          ...newAccount,
                          username: e.target.value,
                        })
                      }
                      value={newAccount.username}
                    />
                  </div>

                  <div className="mb-3">
                    {/* Password */}
                    <label htmlFor="password">{t("password")}</label>
                    <input
                      type="password"
                      id="password"
                      className="form-control"
                      placeholder={t("password")}
                      required
                      onChange={(e) =>
                        setNewAccont({
                          ...newAccount,
                          password: e.target.value,
                        })
                      }
                      value={newAccount.password}
                    />
                  </div>

                  {/* Button */}
                  <div className="mb-3">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={(e) => handleCreateAccount(e)}
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner
                          animation="border"
                          variant="light"
                          className="me-2"
                          size="sm"
                        />
                      ) : (
                        t("add_account")
                      )}
                    </button>
                  </div>
                </form>
              )}
            </Card.Body>
          </Card>
        </Accordion.Collapse>
        <ContextAwareToggle eventKey="4">
          {{ title: t("cefr"), desc: t("cefr_desc"), color: "#2D851D" }}
        </ContextAwareToggle>
        <Accordion.Collapse eventKey="4">
          <Card className="border-0" style={{ borderRadius: "0 0 8px 8px" }}>
            <Card.Body
              style={{
                borderLeft: "10px solid #2D851D",
                borderRadius: "0 0 0 8px",
              }}
            >
              <Row>
                {cefrData.map((item, index) => (
                  <Col key={index} md={6} sm={12}>
                    <CefrCard item={item} />
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        </Accordion.Collapse>
      </Accordion>
      {/* <Col className="gy-6" style={{ position: 'relative', marginBottom: "5%", marginLeft: "5%" }}>
				<Row>
					<Col md={12} className="text-center">
						<img src={FlagImage} alt="Flag" style={{ width: '50px',marginLeft:"34px",marginTop:"-70px" }} />
					</Col>
					{cefrData.map((item, index) => (
						<Col key={index} md={6} sm={4}
							style={{
								marginTop: index === 0 ? "3%"
									: index === 1 ? "18%"
										: index === 2 ? "0%"
											: index === 3 ? "17%"
												: index === 4 ? "0%"
													: index === 5 ? "17%"
														: index === 6 ? "0%"
															: "0"
							}}
						> 
							<CefrCard item={item} />
						</Col>
					))}
				</Row>
				<div
					style={{
						position: 'absolute',
						top: 0,
						bottom: 0,
						left: '50%',
						borderLeft: '2px dashed #2D851D',
						content: '""',
						zIndex: 1,
					}}
				></div>
			</Col> */}
      <main
        style={{
          borderLeft: "10px solid #ff9720",
          borderRadius: "8px",
          marginBottom: "3%",
        }}
      >
        {/*  Page header  */}
        <section className="pt-3 pb-9 bg-white" style={{ paddingLeft: "2%" }}>
          <div>
            {/* <h5 className=" display-6 fw-bold">Introductory Sessions:</h5>   */}
            {/* <h3 style={{color:"#ff9720"}} className=" display-3 fw-bold text-uppercase">Dive into the IRead Vibe!</h3>     
									<p className=" lead">
									Explore IRead's essence in interactive sessions. Connect, learn, and embrace community synergy.
									</p> */}
            <SectionHeadingCenter
              color={"#ff9720"}
              subtitle={"dive_into_the"}
            />
          </div>
        </section>
        {/*  Content */}
        <section className="pb-2 bg-white">
          <Container>
            <Row>
              <Col lg={8} md={7} sm={12}>
                <Row>
                  {BlogArticlesList.slice(0, 2).map((item, index) => (
                    <Col lg={6} md={12} sm={12} key={index} className="d-flex">
                      <BlogCard item={item} />
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col lg={4} md={5} sm={!2} className="mt-5 mt-md-0">
                {/*  card */}
                <Card className="mb-2 border ">
                  {/*  card body */}
                  <Card.Body className="p-4">
                    <h3>{t("recent_posts")} </h3>
                    <div className="mt-3">
                      <ListGroup
                        as="ul"
                        className=" mb-0"
                        bsPrefix="list-unstyled"
                      >
                        {BlogArticlesList.slice(0, 2).map((item, index) => (
                          <ListGroup.Item
                            as="li"
                            className="mb-3"
                            bsPrefix=" "
                            key={index}
                          >
                            <h4 className="lh-lg">
                              <Link
                                to={`/blog/article-single/${item.id}`}
                                className="text-inherit"
                              >
                                {t(item.title)}
                              </Link>
                            </h4>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </main>

      {/* Pack List */}
      {/* <Card className="mt-4">
				<Card.Header>
					<h3 className="mb-0 h4">My Packs List</h3>
				</Card.Header>
				<Card.Body className="p-0 ">
					<Table hover responsive className="mb-0 text-nowrap table-centered">
						<thead className="table-light">
							<tr>
								<th scope="col" className="border-0">
									PACKS
								</th>
								<th scope="col" className="border-0">
									BOOKS NUMBER
								</th>
								<th scope="col" className="border-0">
									LEVEL
								</th>
							</tr>
						</thead>
						<tbody>
							{followed_pack_list?.map((item, index) => {
								return (
									<tr key={index}>
										<td className="align-middle border-top-0 ">
											<Link to="#">
												<div className="d-lg-flex align-items-center">
													<Image
														src={item?.img}
														alt=""
														className="rounded img-4by3-lg"
													/>
													<h5 className="mb-0 ms-lg-3 mt-lg-0 mt-2 text-primary-hover">
														{item?.title}
													</h5>
												</div>
											</Link>
										</td>
										<td className="align-middle border-top-0">{item?.book_number}</td>
										<td className="align-middle border-top-0">
											{item?.level}{' '}
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Card.Body>
			</Card> */}
      {/* Session List */}
      {/* <Card className="mt-4">
				<Card.Header>
					<h3 className="mb-0 h4">My Books List</h3>
				</Card.Header>
				<Card.Body className="p-0 ">
					<Table hover responsive className="mb-0 text-nowrap table-centered">
						<thead className="table-light">
							<tr>
								<th scope="col" className="border-0">
									BOOKS
								</th>
								<th scope="col" className="border-0">
									SESSION TITLE
								</th>
								<th scope="col" className="border-0">
									APPROVED
								</th>
							</tr>
						</thead>
						<tbody>
							{followed_sessions?.map((item, index) => {
								return (
									<tr key={index}>
										<td className="align-middle border-top-0 ">
											<Link to="#">
												<div className="d-lg-flex align-items-center">
													<Image
														src={item?.book_img}
														alt=""
														className="rounded img-4by3-lg"
													/>
													<h5 className="mb-0 ms-lg-3 mt-lg-0 mt-2 text-primary-hover">
														{item?.book_title}
													</h5>
												</div>
											</Link>
										</td>
										<td className="align-middle border-top-0">{item?.session_name}</td>
										<td className="align-middle border-top-0">
                                        <td>
											<Badge bg={item?.approved ? 'success' : 'danger'}>
												{item?.approved ? 'Yes' : 'No'}
											</Badge>
										</td>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Card.Body>
			</Card> */}
      <Card
        className="my-1"
        style={{
          borderLeft: "10px solid #754FFE",
          borderRadius: "8px",
          marginBottom: "2%",
        }}
      >
        <Card.Header>
          <HeroGradientHeader />
        </Card.Header>
      </Card>

      {/* end of Page Content section*/}
    </ProfileDashboard>
  );
};
export default ReaderDashboard;
