import {
    GET_RESULT
  } from "../actions/constants";
  
  const resultReducer = (state = { result: {success:false,score:0, percent:0, max_score:0 } }, action) => {
    switch (action.type) {
      case GET_RESULT:
        return { ...state, result: action?.payload };
      default:
        return state;
    }
  };
  
  export default resultReducer;
  