// import node module libraries
import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Image, Navbar, Nav, Container, Offcanvas } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

// import sub layout components
import NavDropdownMain from "layouts/marketing/navbars/NavDropdownMain";
import QuickMenu from "layouts/QuickMenu";

// import media files
import Logo from "assets/images/brand/logo/logo.png";

// import data files

import NavbarHome from "routes/marketing/NavbarHome";

import QuickMenuV2 from "layouts/QuickMenuV2";
import LanguageSwitcher from "LanguageSwitcher";
//translate component
import { useTranslation } from "react-i18next";
import LanguageSwitchPhone from "LanguageSwitchPhone";

const NavbarReader = ({ headerstyle, login, setSelectedLanguage }) => {
  const { t } = useTranslation();
  const [showSidebar, setShowSidebar] = useState(false);
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const [expandedMenu, setExpandedMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //azeazeaz
  return (
    <Fragment>
      <Navbar
        onToggle={(collapsed) => setExpandedMenu(collapsed)}
        expanded={expandedMenu}
        expand="lg"
        className="navbar p-2 navbar-default py-2"
      >
        <Container fluid className="px-0 ps-2">
          {isMobile && (
            <Navbar.Brand as={Link} to="/">
              <Image src={Logo} style={{ maxWidth: "80px" }} alt="" />
            </Navbar.Brand>
          )}
          <div
            className={`navbar-nav navbar-right-wrap ms-auto d-lg-none nav-top-wrap ${
              login ? (isDesktop || isLaptop ? "d-none" : "d-flex") : "d-none"
            }`}
            style={{ marginRight: "28%" }}
          >
            <QuickMenu />
          </div>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setShowSidebar(!showSidebar)}
          >
            <span className="icon-bar top-bar mt-0"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </Navbar.Toggle>
          <Offcanvas
            show={showSidebar}
            onHide={() => setShowSidebar(false)}
            style={{ width: "80%", borderRight: "1px solid #dee2e6" }}
            placement="start"
          >
            <Offcanvas.Header
              closeButton
              style={{ heigth: "20px", marginLeft: "10px" }}
            >
              <Offcanvas.Title style={{ color: "black" }}>Menu</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body
              style={{ backgroundColor: "rgba(255,255,255,0.5)" }}
            >
              <Nav>
                {NavbarHome.map((item, index) => {
                  if (item.children === undefined) {
                    return (
                      <Nav.Link
                        key={index}
                        as={Link}
                        to={item.link}
                        onClick={() => setShowSidebar(false)}
                        style={{
                          borderBottom: "1px solid #ccc",
                          marginBottom: "8px",
                        }}
                      >
                        <i
                          className={`nav-icon fe fe-${item.icon} me-2`}
                          style={{ marginLeft: "10px" }}
                        ></i>
                        {t(item.menuitem)}
                      </Nav.Link>
                    );
                  } else {
                    return (
                      <NavDropdownMain
                        item={item}
                        key={index}
                        onClick={(value) => setExpandedMenu(value)}
                      />
                    );
                  }
                })}
                {/* <DocumentMenu /> */}
              </Nav>
              {/*   <Form className="mt-3 mt-lg-0 ms-lg-3 d-flex align-items-center">
              <span className="position-absolute ps-3 search-icon">
                <i className="fe fe-search"></i>
              </span>
              <AutoComplete />
              <Form.Control
                type="Search"
                id="formSearch"
                className="ps-6"
                placeholder="Search Stories"
              /> 
            </Form>*/}
              {/* Right side quick / shortcut menu  */}

              <Nav
                className="navbar-nav navbar-right-wrap ms-auto d-flex nav-top-wrap"
                style={{ marginTop: "15px" }}
              >
                {/* {!login && <DarkLightMode className="mt-2 me-2" />} */}
                <span className={`ms-auto mt-1  ${login ? "d-none" : ""}`}>
                  <Nav.Link
                    as={Link}
                    to="/authentication/sign-in"
                    bsPrefix="btn"
                    className="btn btn-white shadow-sm me-2"
                  >
                    {t("sign_in")}
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/authentication/sign-up"
                    bsPrefix="btn"
                    className="btn btn-primary shadow-sm"
                  >
                    {t("sign_up")}
                  </Nav.Link>
                </span>

                <span
                  className={`${
                    login
                      ? isDesktop || isLaptop
                        ? "d-flex"
                        : "d-none"
                      : "d-none"
                  }`}
                >
                  {/* <QuickMenu /> */}
                </span>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "40%",
                    zIndex: 1000,
                  }}
                >
                  <LanguageSwitchPhone
                    setSelectedLanguage={setSelectedLanguage}
                  />
                </div>
              </Nav>
              {/* end of right side quick / shortcut menu  */}
            </Offcanvas.Body>
          </Offcanvas>
          {!isMobile && (
            <Navbar.Collapse
              id="basic-navbar-nav"
              style={{ position: "relative", direction: "rtl" }}
            >
              <Navbar.Brand as={Link} to="/">
                <Image
                  src={Logo}
                  style={{
                    maxWidth: "80px",
                    marginRight: "-10px",
                    marginTop: "-10px",
                    marginLeft: "20px",
                  }}
                  alt=""
                />
              </Navbar.Brand>
              <Nav>
                {NavbarHome.map((item, index) => {
                  if (item.children === undefined) {
                    return (
                      <Nav.Link
                        key={index}
                        as={Link}
                        to={item.link}
                        onClick={() => setExpandedMenu(false)}
                        style={{
                          fontSize: "16px",
                          fontWeight: 700,
                          marginLeft: "10px",
                        }}
                      >
                        {t(item.menuitem)}
                      </Nav.Link>
                    );
                  } else {
                    return (
                      <NavDropdownMain
                        item={item}
                        key={index}
                        onClick={(value) => setExpandedMenu(value)}
                      />
                    );
                  }
                })}
                {/* <DocumentMenu /> */}
              </Nav>
              {/* Search Form */}
              {/*   <Form className="mt-3 mt-lg-0 ms-lg-3 d-flex align-items-center">
              <span className="position-absolute ps-3 search-icon">
                <i className="fe fe-search"></i>
              </span>
              <AutoComplete />
               
            </Form>*/}

              {/* Right side quick / shortcut menu  */}
              <Nav className="navbar-nav navbar-right-wrap ms-auto d-flex nav-top-wrap">
                {/* {!login && <DarkLightMode className="mt-2 me-2" />} */}
                <span
                  className={`ms-auto mt-0  ${login ? "d-none" : ""}`}
                  style={{
                    position: "absolute",
                    top: "-15%",
                    left: "0%",
                    zIndex: 1000,
                  }}
                >
                  <Nav.Link bsPrefix="btn" style={{ marginLeft: "10px" }}>
                    <LanguageSwitcher
                      setSelectedLanguage={setSelectedLanguage}
                    />
                  </Nav.Link>
                  <Nav.Link
                    style={{ marginLeft: "10px" }}
                    as={Link}
                    to="/authentication/sign-in"
                    bsPrefix="btn"
                    className="btn btn-white shadow-sm me-2"
                  >
                    {t("sign_in")}
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/authentication/sign-up"
                    bsPrefix="btn"
                    className="btn btn-primary shadow-sm"
                  >
                    {t("sign_up")}
                  </Nav.Link>
                </span>

                <span
                  className={`${
                    login
                      ? isDesktop || isLaptop
                        ? "d-flex"
                        : "d-none"
                      : "d-none"
                  }`}
                  style={{
                    position: "absolute",
                    top: "0%",
                    left: "0%",
                    zIndex: 1000,
                  }}
                >
                  <LanguageSwitcher setSelectedLanguage={setSelectedLanguage} />
                  <QuickMenuV2 />
                </span>
              </Nav>
              {/* end of right side quick / shortcut menu  */}
            </Navbar.Collapse>
          )}
        </Container>
      </Navbar>
    </Fragment>
  );
};

// Specifies the default values for props
NavbarReader.defaultProps = {
  headerstyle: "navbar-default",
  login: false,
};

// Typechecking With PropTypes
NavbarReader.propTypes = {
  headerstyle: PropTypes.string,
  login: PropTypes.bool,
};

export default NavbarReader;
