// import node module libraries
import React, { useEffect, useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {toast, ToastContainer } from "react-toastify";
import { useDispatch } from 'react-redux';
import BookLayout from '../instructor/BookLayout';
import { useSelector } from 'react-redux';
import { fetchSessionAction } from 'actions/sessions';
import { HourglassSplit } from 'react-bootstrap-icons';

const StartOnlineSession = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const location = useLocation();
    const { user } = useSelector((state) => state.auth);
    const { session } = useSelector((state) => state.packs);
    const searchParams = new URLSearchParams(location.search);
    const sessionID = searchParams.get("session_id");
    const handleRetry = () => {
        window.location.reload();
    };
    const handleJoin = () => {
        if(session?.meet_link !== null){
            navigate("/student/online_session/video_call/"+sessionID);
        }  else {
            toast.error(
                <>
                    Try Later
                    <button onClick={handleRetry} style={{ marginLeft: '10px' }}>
                        Reload Page
                    </button>
                </>,
                {
                    autoClose: false,
                
                }
            );
        }
    }
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 767);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    useEffect(() => {
        dispatch(fetchSessionAction(sessionID))
    }, [dispatch, sessionID])
    const [remainingTime, setRemainingTime] = useState(
        Math.max(0, new Date(session.start_date).getTime() - new Date().getTime())
    );

    const updateRemainingTime = () => {
        setRemainingTime(Math.max(0, remainingTime - 1000));
    };

    useEffect(() => {
        const intervalId = setInterval(updateRemainingTime, 1000);

        return () => clearInterval(intervalId);
    }, [remainingTime]);

    const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
    const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

    const renderTwoDigits = (value) => {
        return value < 10 ? `0${value}` : value;
    };
    const formattedDays = renderTwoDigits(days);
    const formattedHours = renderTwoDigits(hours);
    const formattedMinutes = renderTwoDigits(minutes);
    const formattedSeconds = renderTwoDigits(seconds);

    const renderButtons = () => {
        if (remainingTime > 0) {
            return (
                <Button disabled style={{ width: isMobile ? "100%" : "30%", marginLeft: isMobile ? "-28%" : "0", backgroundColor: "#06104D" }}  >
                    <HourglassSplit size={"15px"} style={{
                        marginLeft: "-5%", 
                        marginRight: "5%", 
                        color: " white", 
                        marginTop: "-2px",
                    }} />
                    {formattedDays} : {formattedHours} : {formattedMinutes} : {formattedSeconds}
                </Button>
            );
        } else {
            return (
                <Button style={{ width: "30%" }} onClick={handleJoin}>
                    Join
                </Button>
            );
        }
    };
    return (
        <BookLayout>
            <Card className="border-0">
                <Card.Header>
                    <h4>Start Online Session</h4>
                    {remainingTime > 0 && (
                        <p>Your online session will have started: {session.start_date}</p>
                    )}
                </Card.Header>
                <Card.Body className="p-0 mt-7 mb-8">
                    <div style={{ marginLeft: "35%" }}>
                        {renderButtons()}
                    </div>
                </Card.Body>
            </Card>
            <ToastContainer />
        </BookLayout>
        
    );
};

export default StartOnlineSession;
