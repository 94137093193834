import {
  fetchNotifications,
  fetchNotificationsByCat,
  fetchOneNotification,
  fetchUserNotification,
  updateNotification,
} from "api";
import {
  GET_ALL_NOTIFICATION,
  GET_USER_NOTIFICATION,
  LOADING,
  NEW_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from "./constants";

export const fetchUserNotificationAction = (id, cat) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data: allNoti } = await fetchNotifications();

    const { data } = await fetchUserNotification(id);

    if (data.notifications.length > 0) {
      const notiData = await Promise.all(
        data.notifications.map(async (e) => {
          const { data: noti } = await fetchOneNotification(e.notification_id);

          if (noti) {
            return noti;
          }
        })
      );
      if (cat) {
        const { data: catNoti } = await fetchNotificationsByCat(cat);
        dispatch({
          type: GET_USER_NOTIFICATION,
          payload: [...allNoti.data, ...notiData.reverse(), ...catNoti.data],
        });
      }
      dispatch({
        type: GET_USER_NOTIFICATION,
        payload: [...allNoti.data, ...notiData.reverse()],
      });
    } else {
      if (cat) {
    
        const { data: catNoti } = await fetchNotificationsByCat(cat);

        dispatch({
          type: GET_USER_NOTIFICATION,
          payload: [...allNoti.data, ...catNoti.data],
        });
      }else{
        dispatch({
          type: GET_USER_NOTIFICATION,
          payload: [...allNoti.data],
        });
      }
    
    }

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
  }
};

export const fetchAllNotificationAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await fetchNotifications();

    dispatch({ type: GET_ALL_NOTIFICATION, payload: data.data });

    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
  }
};

export const updateNotificationAction = (noti, id) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });

    const { data } = await updateNotification(noti, id);

    dispatch({ type: UPDATE_NOTIFICATION, payload: data });
    dispatch({ type: LOADING, payload: false });
  } catch (error) {
    console.log(error);
    dispatch({ type: LOADING, payload: false });
    if (error?.response?.data?.message) {
      console.log(error.response.data.message);
    }
  }
};
export const newNotificationAction = (noti) => async (dispatch) => {
  try {
    dispatch({ type: NEW_NOTIFICATION, payload: noti });
  } catch (error) {
    dispatch({ type: LOADING, payload: false });
  }
};
