// Section : Header
// Style : H2 title with description
//translate component
import {  useTranslation } from 'react-i18next';
// import node module libraries
import { Col, Row } from 'react-bootstrap';

const SectionHeading = ({ title, description }) => {
	const { t } = useTranslation();
	return (
		<Row dir="ltr">
			<Col md={6} sm={12} className="offset-right-md-6 mb-6">
				<h2 className="display-4 mb-3 fw-bold">{t(title)}</h2>
				<p className="lead">{t(description)}</p>
			</Col>
		</Row>
	);
};

export default SectionHeading;
