// import node module libraries
import { ProgressBar } from 'react-bootstrap';
//translate component
import {  useTranslation } from 'react-i18next';

 
const QuizProgress = (props) => {
	const { t } = useTranslation();
	let progress = (props.currentQuestion / props.totalQuestion) * 100;
	return (
		<div className="mt-3">
			<div className="d-flex justify-content-between">
				<span>{t("exam_progress")}</span>
				<span>
					{' '}
					{t("question")} {props.currentQuestion} {t("out_of")} {props.totalQuestion}
				</span>
			</div>
			<div className="mt-2">
				<ProgressBar style={{ height: '6px' }}>
					<ProgressBar
						now={progress}
						style={{ width: progress + '%' }}
						variant="success"
					/>
				</ProgressBar>
			</div>
		</div>
	);
};
export default QuizProgress;
