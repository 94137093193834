// import node module libraries
import { submitAnswerAction } from "actions/quizs";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
//translate component
import { useTranslation } from "react-i18next";
const Pagination = ({
  nPages,
  currentPage,
  setCurrentPage,
  selectedAnswer,
  currentQuestionId,
  setSelectedAnswer,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const session_id = searchParams.get("session_id");
  const book_id = searchParams.get("book_id");
  const quizId = searchParams.get("quiz_id");
  const user_id = searchParams.get("user_id");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const linkToResult = `/quiz/quiz-finish?session_id=${session_id}&book_id=${book_id}&quiz_id=${quizId}&user_id=${user_id}`
  const { user, loading } = useSelector((state) => state.auth);
  // const user_id = user.quiz_id;
  const [showPopUp, setShowPopUp] = useState(false);
  const handleSubmitQuiz = async () => {
    const requestBody = {
      answer: selectedAnswer,
      question_id: currentQuestionId,
      user_id: user_id,
      quiz_id: quizId,
    };

    try {
      dispatch(submitAnswerAction({ requestBody, setSelectedAnswer, nextPage, setShowPopUp,book_id,session_id }));


    } catch (error) {
      console.error("Error submitting answer:", error);
    }
  };
  const handleFinishQuiz = async () => {
    const requestBody = {
      answer: selectedAnswer,
      question_id: currentQuestionId,
      user_id: user_id,
      quiz_id: quizId,
      last: true,
    };

    try {
      dispatch(submitAnswerAction({ requestBody, setSelectedAnswer, linkToResult, navigate, setShowPopUp }));
    } catch (error) {
      console.error("Error submitting answer:", error);
    }
  };
  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  const handleClosePopUp = () => {
    setShowPopUp(false);
    navigate("/instructor/quiz/" + book_id + "?session_id=" + session_id);
  };
  return (
    <div>
      <h5>
        {" "}
        <span style={{ color: "red" }}>{t("notice")} </span> {t("if_you_dont")}
      </h5>

      <div
        className={`d-flex justify-content-${currentPage > 1 ? "between" : "end"
          }`}
        style={{ marginTop: "20px" }}
      >
        {currentPage > 1 && (
          <Button variant="secondary" onClick={prevPage}>
            <i className="fe fe-arrow-left"></i> {t("previous")}
          </Button>
        )}
        {currentPage === nPages ? (
          <Link
            className="btn btn-primary"

            onClick={() => handleFinishQuiz()}
          >
            {t("finish")}
          </Link>
        ) : (
          <Button
            variant="primary"
            disabled={loading}
            onClick={() => handleSubmitQuiz()}
          >
            {" "}
            {t("next")} <i className="fe fe-arrow-right"></i>
          </Button>
        )}
      </div>
      <Modal show={showPopUp} onHide={() => setShowPopUp(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <h3>Time's Up!</h3>
          <p className="text-secondary">Sorry, your time for this quiz has expired.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePopUp}>
          Return to Quiz List
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Pagination;
