import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const storedLanguage = localStorage.getItem("selectedLanguage");
const defaultLanguage = storedLanguage || "en";
const resources = {
  en: {
    translation: {
      trusted_platform: "Trusted education platform",
      discover_the_magic: "Discover the Magic of Reading with IRead!",
      welcome_to_iread:
        "Welcome to IRead, your new destination for an incredible reading adventure! Dive into a world where stories come alive, tailored just for you. Whether you're a curious kid, an eager teenager, or an adult seeking literary thrills, we have something special in store. Choose from a range of books that match your English level, and embark on a journey that sparks imagination and knowledge. Engage in interactive reading sessions, connect with fellow readers, and put your comprehension to the test with our fun quizzes. Join us today and unlock the joy of reading in a whole new way with IRead!",
      btn_join_now: "Join Now",
      staff_feedback: "What our staff say",
      rating_number: "Based on 3265 ratings",
      hear_from_teacher:
        "Listen to the opinions of teachers, trainers, and leaders in the field of English education on how Iread empowers them to deliver new and enhanced online learning experiences.",
      experience_iread:
        "Experience IRead: Elevate your reading journey with tailored book packs. Engage in interactive discussions, master comprehension with quizzes, and join a thriving reader's community.",
      reading_pack: "Reading Packs",
      all: "All",
      teenagers: "Teenagers",
      kids: "Kids",
      adults: "Adults",
      content: "Contents",
      description: "Descriptions",
      faq: "FAQ",
      enrolled: "Enrolled",
      related_packs: "Related Packs",
      btn_follow_pack: "Follow This Pack",
      pending_pack: "Pending",
      approved_pack: "Owned",
      what_includes: "What’s included",
      hours: "8 hours",
      certificate: "Certificate",
      books: "4 Books",
      year_access: "1 year access",
      the_best_reader:
        "The best reader is guaranteed to win a scholarship for an English language course in the UK.",
      cookie_text:
        "We use cookies to enhance your browsing experience and analyze our traffic. By clicking *I Agree*, you consent to our use of cookies.",
      privacy_policy: "Privacy Policy",
      btn_i_agree: "I Agree",
      hi_there: "Hi there, we’re ",
      iread: "IRead",
      crafting_the_ultimate:
        "Crafting the ultimate interactive reading platform for all ages, catering to readers, learners, educators, and the literary community.",
      iread_offers:
        "IRead offers polished and coherent story layouts, empowering you to curate stunning literary experiences. With feature-rich elements and thoughtfully designed pages, IRead aids in crafting exceptional reading platforms and content.",
      our_fundamental: "Our Fundamental Principles",
      at_iread_we_stand:
        "At IRead, we stand as a premier worldwide hub for reading and knowledge-sharing, linking countless individuals to the essential skills required for lifelong success.",
      our_important: "Our Important Beliefs",
      here_at_iread:
        "Here at IRead, our important beliefs guide us. We help everyone see what's right and wrong, promoting understanding and progress.",
      bringing_learning: "Bringing Learning Within Reach",
      connecting_with_storie:
        "Connecting with stories and knowledge, fueling curiosity. Join us to embark on a journey through the world of words.",
      learn_and_grow: "Learn and Grow Together",
      embark_ona_journey:
        "Embark on a journey of discovery, as we explore stories, share knowledge, and nurture growth as a community.",
      making_reading: "Making Reading Available to Everyone",
      bringing_engaging:
        "Bringing engaging stories closer; sparking curiosity and learning. Join us to explore the fascinating world of words.",
      meet_our_team: "Meet Our Team",
      interested_in_collaborating:
        "Interested in collaborating with skilled professionals and contributing to a tool embraced by familiar companies? Join the IRead team and influence the future of reading experiences.",
      btn_openings: "Openings",
      become_part_of_team:
        "Become Part of the IRead Team & Shape the Future of Reading",
      if_youre_enthusuastic:
        "If you're enthusiastic and ready to dive in, we're excited to connect. We prioritize employee growth and well-being.",
      btn_joinus: "Join us!",
      connect_with_us: "Connect with Us:",
      complete_the_form:
        "Complete the form on the right, and our team will promptly get in touch. We look forward to hearing from you!",
      your_solution_hub:
        "Your Solution Hub: Discover answers to your questions in our support portal before contacting us directly. We're here to assist!",
      first_name: "First Name",
      last_name: "Last Name",
      email: "Email",
      phone_number: "Phone Number",
      contact_reason: "Contact Reason",
      message: "Message",
      btn_submit: "Submit",
      author: "Author : ",
      calendar: "Calendar",
      shareable_certificate: "Shareable Certificate",
      flexible_deadlines: "Flexible Deadlines",
      online_courses: "100% Online Courses",
      approx_24_hours: "Approx 24 hours",
      testimonials: "TESTIMONIALS",
      what_our_teacher: "What our Teachers are saying",
      start_your_reading:
        "Start Your Reading Journey and Win a Special Trip to London!",
      hello_book_lovers:
        "Hello, book lovers! Join IRead for an exciting adventure in stories. We have easy English books for you to enjoy. The more you read, the better your chance to win a special trip to London to learn English! Be the best reader, and the trip could be yours. Reading is fun, and on IRead, it can take you on a fantastic journey to one of the coolest cities in the world. Ready to start? Let's read, learn, and maybe pack our bags for London!",
      kindly_log_in:
        "Kindly log in to your account in order to register for this session.",
      your_pack_subscription:
        "Your pack subscription is pending approval. Please wait for your subscription to be approved.",
      are_you_certain: "Are you certain you wish to register for this session?",
      confirm: "Confirm",
      follow_session: "Follow session",
      follow_pack: "Follow Pack",
      login: "Login",
      btn_cancel: "Cancel",
      company: "Company",
      about: "About",
      contact: "Contact",
      support: "Support",
      become_instructor: "Become Instructor",
      get_in_touch: "Get in touch",
      all_rigth_reserved: "© 2023 IRead, Inc. All Rights Reserved",
      terms_of_use: "Terms of Use",
      next_session: "Next Session >",
      prev_session: "< Prev Session ",
      alrt_last_session: "this is the last sessions",
      english_teacher: "English Teacher",
      as_a_seasoned:
        "As a seasoned English teacher, IRead has rejuvenated my approach to language education. It's a dynamic tool for nurturing English skills through the power of storytelling.",
      ive_seen_various:
        "I've seen various teaching methods, but IRead combines the best of both worlds: English instruction and the joy of reading. It's a valuable addition to our school's curriculum.",
      educational_coordinator: "Educational Coordinator",
      our_school_repuatation:
        "Our school's reputation in English education is well-deserved, and IRead reinforces that reputation. It's a holistic approach to language learning that keeps students engaged and enthusiastic.",
      iread_project_man: "IRead Project Manager",
      iread_embodies:
        "IRead embodies our commitment to innovative education. It's a revolutionary approach to teaching English through stories, and our students are thriving with this method.",
      school_director: "School Director",
      our_educators_have:
        "Our educators have always aimed for excellence, and IRead complements our vision. It's a powerful tool that encourages students to explore the English language through captivating narratives.",
      head_of_english: "Head of English Department",
      ive_led_our:
        "I've led our educational team for years, and IRead is the kind of innovative solution we've been waiting for. It enhances our English program by making reading an integral part of language development.",
      hr_manager: "HR Manager",
      as_hr_manager:
        "As an HR manager, IRead is a valuable addition to our language learning programs. It's a versatile tool that enhances our employees' language skills while fostering a love for reading. This not only benefits our workforce but also contributes to a more culturally diverse and linguistically capable organization.",
      page: "page",
      reading_pack: "Reading Packs",
      about_us: "About us",
      contact_us: "Contact us",
      my_pack: "My Subscriptions",
      my_liberary: "My Library",
      edit_profil: "Edit Profile",
      account_setting: "Account Settings",
      security: "Security",
      sign_out: "Sign Out",
      sign_up: "Sign Up",
      sign_in: "Sign In",
      back_to_pack: "Back to my packs",
      introduction: "Introduction",
      quiz: "Quiz",
      back_my_libary: "Back to my library",
      introductionV2: "Introduction:",
      start_quiz: "Start Quiz",
      finished: "Finished",
      result: "Result",
      release_date: "Release date",
      welcome_to_quiz: "Welcome to Quiz",
      start_your_quiz: "Start Your Quiz",
      notice: "NOTICE :",
      if_you_dont: "If you don't have the answer, press on 'Next Button'",
      finish: "Finish",
      next: "Next",
      exam_progress: "Exam Progress:",
      question: "Question",
      out_of: "out of",
      congratulations_you: "🎉 Congratulations. You passed!",
      you_have_succes:
        "You have successfully completed the quiz. Now you can click on 'Finish'  and go back to your quiz page.",
      sorry_you_failed: "😔 Sorry. You failed.",
      you_did_pass: "You did not pass the quiz. Please review your answers.",
      is: "is",
      your_score: "Your Score:",
      points: "points",
      out_of: "out of",
      congratulations:
        "Congratulations, you have completed the quiz. Your answers are being validated, please wait⌛",
      you_doing_good:
        "You doing a good joob, you will receive a notification when your result is validated.",
      my_dashboard: "My Dashboard",
      check_result:
        "You have successfully completed the quiz. Now you can click on 'Finish' and check your result.",
      good_joob: "👌 GOOD JOOB. You finished this quiz",
      your_quiz_list: "Your Quizs List",
      choise_your_quiz: "Choise your quiz and get ready to succeed.",
      currently_there_are:
        "Currently, there are no available quizzes for you at this time.",
      dont_have_acc: " Don’t have an account?",
      email_adress_here: "Email address here",
      password: "Password",
      forget_pass: "Forgot your password?",
      or: "Or",
      privacy_policy_v2: " Privacy Policy.",
      and: "and",
      terms_of_service: "Terms of Service",
      i_agree_to_the: " I agree to the",
      test_if_by_typing:
        " Test it by typing a password in the field below. To reach full strength, use at least 6 characters, a capital letter and a digit, e.g. 'Test01'",
      password_strength: "Password strength",
      user_name: "User Name",
      already_have_acc: "Already have an account?",
      very_weak: "Very Weak",
      weak: "Weak",
      fear: "Fear",
      good: "Good",
      strong: "Strong",
      please_follow_on: "List of Sessions",
      profile_details: "Profile Details",
      you_have_full:
        "You have full control to manage your own account setting.",
      your_avatar: "Your avatar",
      no_bigger_than: "no bigger than 800px wide and tall.",
      personal_details: "Personal Details",
      edit_your_personal: "Edit your personal information and address.",
      phone: "Phone",
      birthday: "Birthday",
      date_of_birthday: "Date of Birth",
      adress_line_1: "Address Line 1",
      adress_line_2: "Address Line 2",
      country: "Country",
      state: "State",
      update_profil: "Update Profile",
      update_btn: "Update",
      delete_btn: "Delete",
      edit_your_account:
        "Edit your account settings and change your password here.",
      email_adress: "Email Address",
      your_current_email: "Your current email address is",
      new_email_adress: "New email address",
      current_password: "Current Password",
      update_details: "Update Details",
      change_password: "Change Password",
      we_will_email:
        "We will send to you a confirmation email when changing your password, so please expect that email after submitting.",
      new_password: "New Password",
      confirm_new_password: "Confirm New Password",
      save_password: "Save Password",
      cant_remenber_your: "Can't remember your current password?",
      reset_your_password: "Reset your password via email",
      previous: "Previous",
      help_support: "Submit Ticket",
      faqV2: "FAQ’s",
      help_center: "Help Center",
      my_result: "My Result",
      accounts: "Accounts",
      accounts_managment: "Accounts managment",
      add_account: "Add New Account",
      no_notification: " No new notifications at the moment.",
      notifications: "Notifications",
      address_line_2: "Adress Line 2",
      address_line_1: "Adress Line 1",
      who_can_help: "How can we help you?",
      have_question: "Have questions? Search through our Help Center",
      enter_a_question: "Enter a question, topic or keyword",
      or_choose_category:
        "... or choose a category to quickly find the help you need",
      guide_resources: "Guides & Resources",
      faq_desc:
        "FAQ, short for frequently asked questions, is a list of commonly asked questions and answers about a specific topic.",
      guide_resou_desc:
        "UI Style Guides are a design & development tool that brings cohesion to a digital product user interface & experience",
      view_faq: "View FAQ",
      browse_guide: "Browse Guides",
      support_desc:
        "The good news is that youre not alone, and youre in the right place. Contact us for more detailed support.",
      submit_req: "Submit a Request",
      submit_description:
        "From innovation to the hearts of our dear readers,rely on our expertise for unwavering support. Submit a Ticket, and rest assured, we will promptly address your needs.",
      cefr: "How are English proficiency levels categorized?",
      cefr_desc:
        " Our method for assessing English skills is aligned with the Common European Framework of Reference for Languages (CEFR). Consequently, your assessment outcome will correspond to one of the seven globally recognized proficiency levels.",
      a0_title: "Beginner",
      a0_subtitle: "Initial Foundations",
      a0_desc:
        "Beginning to recognize basic phrases and words in spoken and written form, with minimal ability to respond or write",
      a1_title: "Elementary",
      a1_subtitle: "Basic Understanding and Communication",
      a1_desc:
        "Can comprehend and use familiar everyday expressions, engage in simple conversations, write basic phrases, and understand simple written material.",
      a2_title: "Pre-Intermediate",
      a2_subtitle: "Developing Language Skills",
      a2_desc:
        "Capable of understanding routine conversations, expressing simple thoughts verbally and in writing, and grasping the main points in short, clear texts.",
      b1_title: "Intermediate",
      b1_subtitle: "Expanding Language Proficiency",
      b1_desc:
        "Able to handle travel-related language situations, describe experiences and events, write simple texts on familiar topics, and understand the main points of standard input.",
      b2_title: "Upper-Intermediate",
      b2_subtitle: "Advanced Language Use",
      b2_desc:
        "Can interact with native speakers with some fluency, produce detailed texts, understand the main ideas of complex text, and clearly articulate a wide range of subjects.",
      c1_title: "Advanced",
      c1_subtitle: "Highly Proficient in Language Use",
      c1_desc:
        "Capable of producing well-structured, detailed texts, understanding lengthy and complex material, and communicating fluently and spontaneously without much obvious searching for expressions.",
      c2_title: "Mastery",
      c2_subtitle: "Near-Native Mastery",
      c2_desc:
        "Shows ability to effortlessly understand practically everything heard or read, summarize information from different spoken and written sources, and express themselves spontaneously, very fluently, and precisely, differentiating finer shades of meaning even in more complex situations.",
      account_setup_reminder: "Account Setup Reminder",
      take_charge_of:
        "Take charge of your account settings with control. Elevate your experience through seamless and personalized management options.",
      change_email: "Change Email +",
      change_passewo: "Change Password +",
      view_chapter: "View Profile Details +",
      dive_into_the: "Dive into the IRead Vibe!",
      recent_posts: "Recent Posts :",
      enjoy_learn_more: "Enjoy, Learn More: Embrace the Power of iRead.",
      hello_to_all:
        "Hello to all of you! I am Amine Lamine, the Founder of Iread. I am very happy to welcome each one of you to our big family...",
      the_founder_of: "The Founder of Iread",
      january_18: "January 18, 2024",
      amine_lamine: "Amine Lamine",
      the_magic_of_reading:
        "The Magic of Reading: Why Books are Your Best Friends",
      today_want_share:
        "Today, I want to share with you something very special – the magic of reading. I’m Amine Lamine, the Founder of Iread...",
      my_dashbor: "My Dashboard",
      more_details: "More Details",
      continue_quiz: "Keep taking quiz",
      my_invoices: "My Invoices",
    },
  },
  fr: {
    translation: {
      trusted_platform: "Une plateforme éducative de confiance",
      discover_the_magic:
        "Plongez dans l'univers fascinant de la lecture avec IRead !",
      welcome_to_iread:
        "Bienvenue sur IRead, votre nouvelle destination pour une incroyable aventure de lecture ! Plongez dans un monde où les histoires prennent vie, spécialement conçues pour vous. Que vous soyez un enfant curieux, un adolescent avide ou un adulte en quête de frissons littéraires, nous avons quelque chose d'exceptionnel à vous offrir. Choisissez parmi une gamme de livres adaptés à votre niveau d'anglais et embarquez pour un voyage qui stimulera votre imagination et vos connaissances. Participez à des sessions de lecture interactives, connectez-vous avec d'autres lecteurs et mettez votre compréhension à l'épreuve avec nos quiz amusants. Rejoignez-nous dès aujourd'hui et débloquez la joie de la lecture d'une toute nouvelle manière avec IRead !",
      btn_join_now: "Rejoignez-nous sur iread.",
      staff_feedback: "Les propos de notre équipe",
      rating_number: "Basé sur 3265 évaluations",
      hear_from_teacher:
        "Écoutez les avis des enseignants, formateurs et leaders dans le domaine de l'enseignement de l'anglais sur la manière dont Iread les habilite à fournir des expériences d'apprentissage en ligne nouvelles et améliorées.",
      experience_iread:
        "Découvrez IRead : Élevez votre parcours de lecture avec des sélections de livres adaptées. Participez à des discussions interactives, maîtrisez la compréhension avec des quiz et Rejoignez une communauté de lecteurs florissante.",
      reading_pack: "Packs de lecture",
      all: "Tout",
      teenagers: "Adolescents",
      kids: "Enfants",
      adults: "Adultes",
      content: "Contenu",
      description: "Descriptions",
      faq: "FAQ",
      enrolled: "Inscrit",
      related_packs: "Packs associés",
      btn_follow_pack: "Suivre ce pack",
      pending_pack: "En attente",
      approved_pack: "Possédé",
      what_includes: "C'est quoi inclus",
      hours: "8 heures",
      certificate: "Certificat",
      books: "Livres",
      year_access: "accès d'un an",
      the_best_reader:
        "Si vous êtes le meilleur lecteur, vous aurez la chance de remporter une bourse pour suivre un cours de langue anglaise au Royaume-Uni..",
      cookie_text:
        "Nous utilisons des cookies pour améliorer votre expérience de navigation et analyser notre trafic. En cliquant sur *J'accepte*, vous consentez à notre utilisation des cookies.",
      privacy_policy: "Politique de confidentialité",
      btn_i_agree: "J'accepte",
      hi_there: "Salut",
      iread: "IRead",
      crafting_the_ultimate:
        "Concevoir la plateforme interactive ultime de lecture pour tous les âges, répondant aux besoins des lecteurs, des apprenants, des éducateurs et de la communauté littéraire.",
      iread_offers:
        "La plateforme IRead propose des mises en page d'histoires polies et cohérentes., Offrant la possibilité de concevoir des expériences littéraires étonnantes. Avec des éléments riches en fonctionnalités et des pages soigneusement conçues, IRead aide à créer des plates-formes de lecture exceptionnelles et un contenu de qualité.",
      our_fundamental: "Nos Principes Fondamentaux",
      at_iread_we_stand:
        "Chez IRead, Nous sommes un centre mondial de premier choix pour la lecture et le partage de connaissances, reliant d'innombrables individus aux compétences essentielles nécessaires pour réussir toute leur vie.",
      our_important: "Nos Croyances Importantes",
      here_at_iread:
        "Ici chez IRead, nos croyances importantes nous guident. Nous aidons tout le monde à voir ce qui est juste et faux, favorisant la compréhension et le progrès.",
      bringing_learning: "Rendre l'Apprentissage Accessible",
      connecting_with_storie:
        "Se connecter avec des histoires et des connaissances, nourrir la curiosité. Rejoignez-nous pour entreprendre un voyage à travers le monde des mots.",
      learn_and_grow: "Apprendre et Grandir Ensemble",
      embark_ona_journey:
        "Lancez-vous dans un voyage de découverte, explorons des histoires, partageons des connaissances et favorisons la croissance en tant que communauté.",
      making_reading: "Rendre la Lecture Accessible à Tous",
      bringing_engaging:
        "Rapprocher des histoires captivantes; susciter la curiosité et l'apprentissage. Rejoignez-nous pour explorer le monde fascinant des mots.",
      meet_our_team: "Rencontrez Notre Équipe",
      interested_in_collaborating:
        "Intéressé par la collaboration avec des professionnels qualifiés et contribuer à un outil adopté par des entreprises renommées? Rejoignez l'équipe IRead et influencez l'avenir des expériences de lecture.",
      btn_openings: "Postes Vacants",
      become_part_of_team:
        "Faites Partie de l'Équipe IRead et Façonnez l'Avenir de la Lecture",
      if_youre_enthusuastic:
        "Si vous êtes enthousiaste et prêt à plonger, nous sommes ravis de vous connecter. Nous priorisons la croissance et le bien-être des employés.",
      btn_joinus: "Rejoignez-nous !",
      connect_with_us: "Connectez-vous avec Nous :",
      complete_the_form:
        "Remplissez le formulaire à droite, et notre équipe vous contactera rapidement. Nous attendons avec impatience d'avoir de vos nouvelles !",
      your_solution_hub:
        "Votre Hub de Solutions :Consultez notre portail de support pour trouver des réponses à vos questions avant de nous contacter directement. Nous sommes là pour vous aider !",
      first_name: "Prénom ",
      last_name: "Nom ",
      email: "E-mail ",
      phone_number: "Num de Téléphone ",
      contact_reason: "Motif du Contact ",
      message: "Message ",
      btn_submit: "Soumettre",
      author: "Auteur : ",
      calendar: "Calendrier",
      shareable_certificate: "Certificat Partageable",
      flexible_deadlines: "Délais Flexibles",
      online_courses: "Cours 100% en Ligne",
      approx_24_hours: "Environ 24 heures",
      testimonials: "TÉMOIGNAGES",
      what_our_teacher: "Les enseignements que nous prodiguent nos professeurs",
      start_your_reading:
        "Commencez Votre Aventure de Lecture et Gagnez un Voyage Spécial à Londres !",
      hello_book_lovers:
        "Bonjour, amateurs de livres ! Rejoignez IRead pour une aventure passionnante à travers des histoires. Nous avons des livres en anglais faciles pour que vous puissiez profiter. Plus vous lisez, plus vous avez de chances de gagner un voyage spécial à Londres pour apprendre l'anglais ! Soyez le meilleur lecteur, et le voyage pourrait être à vous. La lecture est amusante, et sur IRead, elle peut vous emmener dans un voyage fantastique vers l'une des villes les plus cool du monde. Prêt à commencer ? Lisons, apprenons, et peut-être préparons nos bagages pour Londres !",
      kindly_log_in:
        "Veuillez vous connecter à votre compte pour vous inscrire à cette session.",
      your_pack_subscription:
        "Votre abonnement au pack est en attente d'approbation. Veuillez attendre que votre abonnement soit approuvé.",
      are_you_certain:
        "Êtes-vous certain de vouloir vous inscrire à cette session ?",
      confirm: "Confirmer",
      follow_session: "Suivre la Session",
      follow_pack: "Suivre le Pack",
      login: "Connexion",
      btn_cancel: "Annuler",
      company: "Entreprise",
      about: "À Propos",
      contact: "Contact",
      support: "Support",
      become_instructor: "Devenir Instructeur",
      get_in_touch: "Entrer en Contact",
      all_rigth_reserved: "© 2023 IRead, Inc. Tous droits réservés",
      terms_of_use: "Conditions d'Utilisation",
      next_session: "Session Suivante >",
      prev_session: "< Session Précédente ",
      alrt_last_session: "c'est la dernière session",
      english_teacher: "Professeur d'Anglais",
      as_a_seasoned:
        "En tant que professeur d'anglais chevronné, IRead a rajeuni mon approche de l'éducation linguistique. C'est un outil dynamique pour cultiver les compétences en anglais à travers le pouvoir de la narration.",
      ive_seen_various:
        "J'ai vu diverses méthodes d'enseignement, mais IRead combine le meilleur des deux mondes : l'instruction en anglais et la joie de la lecture. C'est une addition précieuse au programme de notre école.",
      educational_coordinator: "Coordinateur Éducatif",
      our_school_repuatation:
        "La réputation de notre école en matière d'éducation en anglais est bien méritée, et IRead renforce cette réputation. C'est une approche holistique de l'apprentissage de la langue qui garde les élèves engagés et enthousiastes.",
      iread_project_man: "Chef de Projet IRead",
      iread_embodies:
        "IRead incarne notre engagement envers l'éducation innovante. C'est une approche révolutionnaire pour enseigner l'anglais à travers des histoires, et nos étudiants prospèrent avec cette méthode.",
      school_director: "Directeur d'École",
      our_educators_have:
        "Nos éducateurs ont toujours visé l'excellence, et IRead complète notre vision. C'est un outil puissant qui encourage les étudiants à explorer la langue anglaise à travers des récits captivants.",
      head_of_english: "Chef du Département d'Anglais",
      ive_led_our:
        "J'ai dirigé notre équipe éducative pendant des années, et IRead est le genre de solution innovante que nous attendions. Il améliore notre programme d'anglais en faisant de la lecture une partie intégrante du développement linguistique.",
      hr_manager: "Gestionnaire RH",
      as_hr_manager:
        "En tant que gestionnaire RH, IRead enrichit nos programmes linguistiques, améliorant les compétences de nos employés tout en promouvant l'amour de la lecture. Cela bénéficie à notre main-d'œuvre, favorisant une organisation plus diversifiée et linguistiquement compétente.",
      page: "pages",
      reading_pack: "Packs de lecture",
      about_us: "À propos de nous",
      contact_us: "Contactez-nous",
      my_pack: "Mes abonnements",
      my_liberary: "Ma bibliothèque",
      edit_profil: "Modifier le Profil",
      account_setting: "Paramètres du compte",
      security: "Sécurité",
      sign_out: "Déconnexion",
      sign_up: "S'inscrire",
      sign_in: "Se connecter",
      back_to_pack: "Retour à mes packs",
      introduction: "Introduction",
      quiz: "Quiz",
      back_my_libary: "Retour à ma bibliothèque",
      introductionV2: "Introduction:",
      start_quiz: "Démarrer le Quiz",
      finished: "Terminé",
      result: "Résultat",
      release_date: "Date de Publication",
      welcome_to_quiz: "Bienvenue au Quiz",
      start_your_quiz: "Commencer Votre Quiz",
      notice: "AVIS :",
      if_you_dont:
        "Si vous n'avez pas la réponse, appuyez sur le bouton 'Suivant'",
      finish: "Terminer",
      next: "Suivant",
      exam_progress: "Progression de l'examen :",
      question: "Question",
      out_of: "sur",
      congratulations_you: "🎉 Félicitations. Vous avez réussi !",
      you_have_succes:
        "Vous avez réussi le quiz avec succès. Maintenant, vous pouvez cliquer sur 'Terminer' et revenir à votre page de quiz.",
      sorry_you_failed: "😔 Désolé. Vous avez échoué.",
      you_did_pass:
        "Vous n'avez pas réussi le quiz. Veuillez revoir vos réponses.",
      is: "C'est",
      your_score: "Votre score :",
      points: "points",
      congratulations:
        "Félicitations, vous avez terminé le quiz. Vos réponses sont en cours de validation, veuillez patienter⌛",
      you_doing_good:
        "Vous faites du bon travail, vous recevrez une notification lorsque votre résultat sera validé.",
      my_dashboard: "Mon Tableau de bord",
      check_result:
        "Vous avez réussi le quiz avec succès. Maintenant, vous pouvez cliquer sur 'Terminer' et vérifier votre résultat.",
      good_joob: "👌 BON TRAVAIL. Vous avez terminé ce quiz",
      your_quiz_list: "Votre Liste de Quiz",
      choise_your_quiz: "Choisissez votre quiz et préparez-vous à réussir.",
      currently_there_are:
        "Actuellement, il n'y a aucun quiz disponible pour vous en ce moment.",
      dont_have_acc: "Vous n'avez pas de compte?",
      email_adress_here: "Adresse e-mail ici",
      password: "Mot de passe",
      forget_pass: "Mot de passe oublié?",
      or: "Ou",
      privacy_policy_v2: " Politique de confidentialité.",
      and: "et",
      terms_of_service: "Conditions d'utilisation",
      i_agree_to_the: "J'accepte les",
      test_if_by_typing:
        " Testez-le en tapant un mot de passe dans le champ ci-dessous. Pour atteindre une force maximale, utilisez au moins 6 caractères, une lettre majuscule et un chiffre, par exemple 'Test01'",
      password_strength: "Force du mot de passe",
      user_name: "Nom d'utilisateur",
      already_have_acc: "Vous avez déjà un compte?",
      very_weak: "Très faible",
      weak: "Faible",
      fear: "Moyen",
      good: "Bon",
      strong: "Fort",
      please_follow_on: "Liste des sessions.",
      profile_details: "Détails du profil",
      you_have_full:
        "Vous avez un contrôle total pour gérer les paramètres de votre compte.",
      your_avatar: "Votre avatar",
      no_bigger_than: "pas plus grand que 800 pixels de large et de haut.",
      personal_details: "Détails personnels",
      edit_your_personal:
        "Modifiez vos informations personnelles et votre adresse.",
      phone: "Téléphone",
      birthday: "Anniversaire",
      date_of_birthday: "Date de naissance",
      adress_line_1: "Adresse ligne 1",
      adress_line_2: "Adresse ligne 2",
      country: "Pays",
      state: "État",
      update_profil: "Mettre à jour le profil",
      update_btn: "Mettre à jour",
      delete_btn: "Supprimer",
      edit_your_account:
        "Modifiez les paramètres de votre compte et changez votre mot de passe ici.",
      email_adress: "Adresse e-mail",
      your_current_email: "Votre adresse e-mail actuelle est",
      new_email_adress: "Nouvelle adresse e-mail",
      current_password: "Mot de passe actuel",
      update_details: "Mettre à jour les détails",
      change_password: "Changer le mot de passe",
      we_will_email:
        "Nous vous enverrons un e-mail de confirmation lors du changement de votre mot de passe, alors attendez-vous à cet e-mail après avoir soumis.",
      new_password: "Nouveau mot de passe",
      confirm_new_password: "Confirmer le nouveau mot de passe",
      save_password: "Enregistrer le mot de passe",
      cant_remenber_your:
        "Vous ne vous souvenez pas de votre mot de passe actuel ?",
      reset_your_password: "Réinitialisez votre mot de passe par e-mail",
      previous: "Précédent",
      help_support: "Soumettre un ticket",
      faqV2: "FAQ",
      help_center: "Centre d'aide",
      my_result: "Mon résultat",
      accounts: "Comptes",
      accounts_managment: "Gestion des comptes",
      add_account: "Ajouter un nouveau compte",
      no_notification: "Aucune nouvelle notification pour le moment.",
      notifications: "Notifications",
      address_line_2: "Ligne d'adresse 2",
      address_line_1: "Ligne d'adresse 1",
      who_can_help: "Comment pouvons-nous vous aider ?",
      have_question:
        "Vous avez des questions ? Recherchez dans notre Centre d'aide",
      enter_a_question: "Saisissez une question, un sujet ou un mot-clé",
      or_choose_category:
        "... ou choisissez une catégorie pour trouver rapidement l'aide dont vous avez besoin",
      guide_resources: "Guides et ressources",
      faq_desc:
        "FAQ, abréviation de foire aux questions, est une liste de questions fréquemment posées et de réponses sur un sujet spécifique.",
      guide_resou_desc:
        "Les guides de style d'interface utilisateur sont des outils de conception et de développement qui apportent de la cohésion à l'interface utilisateur et à l'expérience d'utilisation d'un produit numérique.",
      view_faq: "Voir la FAQ",
      browse_guide: "Parcourir les guides",
      support_desc:
        "La bonne nouvelle est que vous n'êtes pas seul, et vous êtes au bon endroit. Contactez-nous pour un support plus détaillé.",
      submit_req: "Soumettre une demande",
      submit_description:
        "De l’innovation au cœur de nos chers lecteurs, comptez sur notre expertise pour un soutien sans faille. Soumettez un ticket et soyez assuré que nous répondrons rapidement à vos besoins.",
      cefr: "Comment sont catégorisés les niveaux de compétence en anglais?",
      cefr_desc:
        " Notre méthode d'évaluation des compétences en anglais est alignée sur le Cadre européen commun de référence pour les langues (CECR). Par conséquent, le résultat de votre évaluation correspondra à l'un des sept niveaux de compétence mondialement reconnus.",
      a0_title: "Débutant",
      a0_subtitle: "Fondations Initiales",
      a0_desc:
        "Commence à reconnaître des phrases et des mots de base à l'oral et à l'écrit, avec une capacité minimale de réponse ou d'écriture.",
      a1_title: "Élémentaire",
      a1_subtitle: "Compréhension de Base et Communication",
      a1_desc:
        "Peut comprendre et utiliser des expressions familières de la vie quotidienne, participer à des conversations simples, rédiger des phrases de base et comprendre des documents écrits simples.",
      a2_title: "Pré-Intermédiaire",
      a2_subtitle: "Développement des Compétences Linguistiques",
      a2_desc:
        "Capable de comprendre des conversations courantes, d'exprimer des pensées simples à l'oral et à l'écrit, et de saisir les points principaux de textes courts et clairs.",
      b1_title: "Intermédiaire",
      b1_subtitle: "Expansion de la Maîtrise Linguistique",
      b1_desc:
        "Capable de gérer des situations linguistiques liées aux voyages, de décrire des expériences et des événements, de rédiger des textes simples sur des sujets familiers, et de comprendre les points principaux de l'input standard.",
      b2_title: "Avancé",
      b2_subtitle: "Utilisation Avancée de la Langue",
      b2_desc:
        "Peut interagir avec des locuteurs natifs avec une certaine fluidité, produire des textes détaillés, comprendre les idées principales de textes complexes, et articuler clairement un large éventail de sujets.",
      c1_title: "Avancé Supérieur",
      c1_subtitle: "Hautement Compétent dans l'Utilisation de la Langue",
      c1_desc:
        "Capable de produire des textes bien structurés et détaillés, de comprendre des matériaux longs et complexes, et de communiquer de manière fluide et spontanée sans rechercher trop évidemment des expressions.",
      c2_title: "Maîtrise",
      c2_subtitle: "Maîtrise Proche du Natif",
      c2_desc:
        "Montre la capacité de comprendre presque tout entendu ou lu, de résumer des informations provenant de différentes sources orales et écrites, et de s'exprimer de manière spontanée, très fluide et précise, différenciant des nuances plus fines de sens même dans des situations plus complexes.",
      account_setup_reminder: "Rappel de configuration de compte",
      take_charge_of:
        "Prenez en charge vos paramètres de compte avec contrôle. Élevez votre expérience grâce à des options de gestion fluides et personnalisées.",
      account_setup_reminder: "Rappel pour la configuration du compte",
      take_charge_of:
        "Prenez en charge vos paramètres de compte avec contrôle. Élevez votre expérience grâce à des options de gestion fluides et personnalisées.",
      change_email: "Changer l'adresse e-mail +",
      change_passewo: "Changer le mot de passe +",
      view_chapter: "Voir les détails du profil +",
      dive_into_the: "Plongez dans l'ambiance iRead!",
      recent_posts: "Articles récents :",
      enjoy_learn_more:
        "Profitez-en, apprenez davantage : Adoptez la puissance d'iRead.",
      hello_to_all:
        "Bonjour à tous ! Je suis Amine Lamine, le Fondateur d'iRead. Je suis très heureux de vous accueillir tous dans notre grande famille...",
      the_founder_of: "Le Fondateur d'iRead",
      january_18: "18 janvier 2024",
      amine_lamine: "Amine Lamine",
      the_magic_of_reading:
        "La magie de la lecture : pourquoi les livres sont vos meilleurs amis",
      today_want_share:
        "Aujourd'hui, je veux partager avec vous quelque chose de très spécial - la magie de la lecture. Je suis Amine Lamine, le Fondateur d'iRead...",
      my_dashbor: "Mon tableau de bord",
      more_details: "Plus de détails",
      continue_quiz: " Continuez à passer le quiz",
      my_invoices: "Mes factures",
    },
  },
  ar: {
    translation: {
      trusted_platform: "منصة تعليم موثوقة",
      discover_the_magic: "!اكتشف سحر القراءة مع آي ريد",
      welcome_to_iread:
        "مرحبًا بك في آي ريد، وجهتك الجديدة لمغامرة قراءة لا تصدق! اغمر نفسك في عالم حيث تتحول القصص إلى واقع، مصممة خصيصًا لك. سواء كنت طفلًا فضوليًا، مراهقًا حريصًا، أو بالغًا يبحث عن إثارة أدبية، لدينا شيء خاص في انتظارك. اختر من بين باقة من الكتب التي تتناسب مع مستوى اللغة الإنجليزية الخاص بك، وابدأ في رحلة تثير الخيال والمعرفة. شارك في حصص قراءة تفاعلية، تواصل مع زملائك القراء، وامتحن فهمك من خلال اختباراتنا الممتعة. انضم إلينا اليوم واكتشف فرح القراءة بطريقة جديدة تمامًا مع آي ريد!",
      btn_join_now: "انضم الآن",
      staff_feedback: "آراء موظفينا",
      rating_number: "بناءً على 3265 تقييمًا",
      hear_from_teacher:
        "استمع إلى آراء المعلمين والمدربين وقادة مجال تعليم اللغة الإنجليزية حول كيفية تمكينهم IRead لتقديم تجارب تعلم عبر الإنترنت جديدة ومحسنة.",
      experience_iread:
        "تجربة آي ريد: ارتق برحلتك في القراءة مع باقة الكتب المصممة خصيصًا. شارك في مناقشات تفاعلية، وتقنع بالفهم من خلال الاختبارات، وانضم إلى مجتمع القراء المزدهر.",
      reading_pack: "باقة القراءة",
      all: "الكل",
      teenagers: "المراهقين",
      kids: "الأطفال",
      adults: "الكبار",
      content: "المحتوى",
      description: "الوصف",
      faq: "الأسئلة الشائعة",
      enrolled: "مسجل",
      related_packs: "باقة ذات صلة",
      btn_follow_pack: "تابع هذه الباقة",
      pending_pack: "قيد الانتظار",
      approved_pack: "تمت الموافقة",
      what_includes: "ماذا يتضمن",
      hours: "8 ساعات",
      certificate: "شهادة",
      books: "4 كتب",
      year_access: "وصول لمدة عام",
      the_best_reader:
        "القارئ الأفضل مضمون للفوز بمنحة دراسية لدورة لغة إنجليزية في المملكة المتحدة.",
      cookie_text:
        "نحن نستخدم ملفات تعريف الارتباط لتعزيز تجربتك في التصفح وتحليل حركة مرورنا. من خلال النقر على *أوافق*, فإنك توافق على استخدامنا لملفات تعريف الارتباط.",
      privacy_policy: "سياسة الخصوصية",
      btn_i_agree: "أوافق",
      hi_there: "مرحبًا، نحن ",
      iread: "آي ريد",
      crafting_the_ultimate:
        "صنع منصة قراءة تفاعلية نهائية لجميع الأعمار، تلبي احتياجات القراء والمتعلمين والمربين والمجتمع الأدبي.",
      iread_offers:
        "تقدم آي ريد تخطيطًا وتنظيمًا للقصص مصقولًا، مما يمكنك من صياغة تجارب أدبية رائعة. باستخدام عناصر غنية بالميزات وصفحات مصممة بعناية، يساعد آي ريد في صياغة منصات قراءة استثنائية ومحتوى ممتاز.",
      our_fundamental: "مبادئنا الأساسية",
      at_iread_we_stand:
        "في آي ريد، نقف كمركز رئيسي عالميًا لتبادل المعرفة والربط بين العديد من الأفراد بالمهارات الأساسية المطلوبة للنجاح مدى الحياة.",
      our_important: "معتقداتنا الهامة",
      here_at_iread:
        "هنا في آي ريد، توجهاتنا الهامة توجهنا. نساعد الجميع على رؤية الصواب والخطأ، ونعزز التفاهم والتقدم.",
      bringing_learning: "جلب التعلم داخل متناول الجميع",
      connecting_with_storie:
        "الاتصال بالقصص والمعرفة، وتشجيع الفضول. انضم إلينا للانطلاق في رحلة عبر عالم الكلمات.",
      learn_and_grow: "تعلم ونمو معًا",
      embark_ona_journey:
        "انطلق في رحلة اكتشاف، حيث نستكشف القصص ونشارك المعرفة ونكن نموًا كمجتمع.",
      making_reading: "جعل القراءة متاحة للجميع",
      bringing_engaging:
        "جلب قصص جذابة بشكل أقرب؛ إشعال الفضول والتعلم. انضم إلينا لاستكشاف عالم الكلمات الرائع.",
      meet_our_team: "تعرف على فريقنا",
      interested_in_collaborating:
        "هل ترغب في التعاون مع محترفين ماهرين والمساهمة في أداة تعتمدها الشركات المعروفة؟ انضم إلى فريق آي ريد وأثر على مستقبل تجارب القراءة.",
      btn_openings: "الوظائف",
      become_part_of_team:
        "كن جزءًا من فريق آي ريد وشكل مستقبل التجربة القرائية",
      if_youre_enthusuastic:
        "إذا كنت متحمسًا وجاهزًا للانخراط، فنحن متحمسون للتواصل. نعطي الأولوية لنمو الموظفين ورفاهيتهم.",
      btn_joinus: "انضم إلينا!",
      connect_with_us: "تواصل معنا:",
      complete_the_form:
        "أكمل النموذج على اليمين، وسيقوم فريقنا بالتواصل معك بسرعة. نتطلع إلى سماع أخبارك!",
      your_solution_hub:
        "مركز الحلول الخاص بك: اكتشف إجابات أسئلتك في بوابة الدعم لدينا قبل الاتصال بنا مباشرة. نحن هنا للمساعدة!",
      first_name: "الاسم الأول",
      last_name: "اسم العائلة",
      email: "البريد الإلكتروني",
      phone_number: "رقم الهاتف",
      contact_reason: "سبب الاتصال",
      message: "الرسالة",
      btn_submit: "إرسال",
      author: "المؤلف: ",
      calendar: "التقويم",
      shareable_certificate: "شهادة قابلة للمشاركة",
      flexible_deadlines: "مواعيد مرنة",
      online_courses: "دورات عبر الإنترنت بنسبة 100%",
      approx_24_hours: "حوالي 24 ساعة",
      testimonials: "شهادات",
      what_our_teacher: "ما يقوله معلمونا",
      start_your_reading: "ابدأ رحلتك في القراءة واربح رحلة خاصة إلى لندن!",
      hello_book_lovers:
        "مرحبًا، عشاق الكتب! انضم إلى آي ريد لمغامرة مثيرة في عالم القصص. لدينا كتب إنجليزية سهلة لك للاستمتاع بها. كلما قرأت أكثر، زادت فرصك في الفوز برحلة خاصة إلى لندن لتعلم الإنجليزية! كن أفضل قارئ، ويمكن أن تكون الرحلة ملكك. القراءة ممتعة، وعلى آي ريد، يمكن أن تأخذك في رحلة رائعة إلى واحدة من أروع المدن في العالم. هل أنتم مستعدون للبداية؟ لنقرأ، ونتعلم، وربما نحزم أمتعتنا للندن!",
      kindly_log_in: "يرجى تسجيل الدخول إلى حسابك للتسجيل في هذه الحصة.",
      your_pack_subscription:
        "اشتراك باقتك قيد الموافقة. يرجى الانتظار حتى يتم الموافقة على اشتراكك.",
      are_you_certain: "هل أنت متأكد أنك ترغب في التسجيل في هذه الحصة؟",
      confirm: "تأكيد",
      follow_session: "متابعة الحصة",
      follow_pack: "متابعة الباقة",
      login: "تسجيل الدخول",
      btn_cancel: "إلغاء",
      company: "نبذة",
      about: "نبذة عنا",
      contact: "اتصل بنا",
      support: "مساعدة",
      become_instructor: "كن مدربًا",
      get_in_touch: "تواصل معنا",
      all_rigth_reserved: "© 2023 IRead، شركة. جميع الحقوق محفوظة",
      terms_of_use: "شروط الاستخدام",
      next_session: "الحصة التالية >",
      prev_session: "< الحصة السابقة",
      alrt_last_session: "هذه هي آخر الحصص",
      english_teacher: "مدرس اللغة الإنجليزية",
      as_a_seasoned:
        "كمدرس لغة إنجليزية ذو خبرة، أعادت لي IRead إحياء نهجي في تعلم اللغات. إنها أداة ديناميكية لتنمية مهارات اللغة الإنجليزية من خلال قوة القصص.",
      ive_seen_various:
        "رأيت طرق تدريس مختلفة، ولكن IRead تجمع بين أفضل العوالم: تعليم اللغة الإنجليزية وفرحة القراءة. إنها إضافة قيمة إلى منهج مدرستنا.",
      educational_coordinator: "منسق التعليم",
      our_school_repuatation:
        "سمعة مدرستنا في تعليم اللغة الإنجليزية مستحقة تمامًا، ويعزز IRead هذه السمعة. إنه نهج شامل لتعلم اللغات يحافظ على تشويق الطلاب وحماسهم.",
      iread_project_man: "مدير مشروع IRead",
      iread_embodies:
        "IRead تجسد التزامنا بالتعليم المبتكر. إنها نهج ثوري لتعليم اللغة الإنجليزية من خلال القصص، وطلابنا يزدهرون بهذا الأسلوب.",
      school_director: "مدير المدرسة",
      our_educators_have:
        "لطالما سعى معلمونا إلى التفوق، و IRead يكمل رؤيتنا. إنه أداة قوية تشجع الطلاب على استكشاف اللغة الإنجليزية من خلال سرد جذاب.",
      head_of_english: "رئيس قسم اللغة الإنجليزية",
      ive_led_our:
        "لقد قدمت فريق التعليم الخاص بنا لسنوات، و IRead هو الحلا المبتكر الذي كنا ننتظره. إنه يعزز برنامجنا الإنجليزي من خلال جعل القراءة جزءًا أساسيًا من تطوير اللغة.",
      hr_manager: "مدير الموارد البشرية",
      as_hr_manager:
        "بصفتي مدير الموارد البشرية، يعتبر IRead إضافة قيمة لبرامج تعلم اللغات لدينا. إنه أداة متعددة الاستخدامات تعزز مهارات لغة موظفينا بينما تعزز حب القراءة. وهذا لا ينفع فقط فريق العمل لدينا ولكنه يسهم أيضًا في تحقيق منظمة أكثر تنوعًا ثقافيًا ولغويًا.",
      page: "صفحة",
      reading_pack: "باقة القراءة",
      about_us: "معلومات عنا",
      contact_us: "اتصل بنا",
      my_pack: "اشتراكاتي",
      my_liberary: "مكتبتي",
      edit_profil: "تعديل الملف الشخصي",
      account_setting: "إعدادت الحساب",
      security: "الأمان",
      sign_out: "تسجيل الخروج",
      sign_up: "التسجيل",
      sign_in: "تسجيل الدخول",
      back_to_pack: "العودة إلى باقتي",
      introduction: "مقدمة",
      quiz: "اختبار",
      back_my_libary: "العودة إلى مكتبتي",
      introductionV2: "المقدمة:",
      start_quiz: "بدء الاختبار",
      finished: "انتهى",
      result: "النتيجة",
      release_date: "تاريخ الإصدار",
      welcome_to_quiz: "مرحبًا بك في الاختبار",
      start_your_quiz: "ابدأ اختبارك",
      notice: "ملاحظة:",
      if_you_dont: "إذا لم تكن لديك الإجابة، اضغط على 'زر التالي'",
      finish: "إنهاء",
      next: "التالي",
      exam_progress: "تقدم الاختبار:",
      question: "سؤال",
      out_of: "من",
      congratulations_you: "🎉 مبروك. لقد نجحت!",
      you_have_succes:
        "لقد أكملت بنجاح الاختبار. الآن يمكنك النقر على 'إنهاء' والعودة إلى صفحة الاختبار الخاصة بك.",
      sorry_you_failed: "😔 معذرة. لم تنجح.",
      you_did_pass: "لم تنجح في الاختبار. يرجى مراجعة إجاباتك.",
      is: "هو",
      your_score: "نقاطك:",
      points: "نقاط",
      out_of: "من",
      congratulations:
        "تهانينا، لقد أكملت الاختبار. يتم التحقق من إجاباتك، يرجى الانتظار⌛",
      you_doing_good:
        "أنت تقوم بعمل جيد، ستتلقى إشعارًا عندما يتم التحقق من نتيجتك.",
      my_dashboard: "لوحة التحكم الخاصة بي",
      check_result:
        "لقد أكملت الاختبار بنجاح. يمكنك الآن النقر على 'إنهاء' والتحقق من نتيجتك.",
      good_joob: "👌 عمل جيد. لقد انتهيت من هذا الاختبار",
      your_quiz_list: "قائمة اختباراتك",
      choise_your_quiz: "اختر اختبارك واستعد لتحقيق النجاح.",
      currently_there_are: "حاليًا، لا توجد اختبارات متاحة لك في هذا الوقت.",
      dont_have_acc: "ليس لديك حساب؟",
      email_adress_here: "عنوان البريد الإلكتروني هنا",
      password: "كلمة المرور",
      forget_pass: "هل نسيت كلمة المرور؟",
      or: "أو",
      privacy_policy_v2: " سياسة الخصوصية.",
      and: "و",
      terms_of_service: "شروط الخدمة",
      i_agree_to_the: "أنا أوافق على",
      test_if_by_typing:
        "جربها بكتابة كلمة مرور في الحقل أدناه. للوصول إلى قوة كاملة ، استخدم ما لا يقل عن 6 أحرف وحرف كبير ورقم ، على سبيل المثال 'Test01'",
      password_strength: "قوة كلمة المرور",
      user_name: "اسم المستخدم",
      already_have_acc: "هل لديك بالفعل حساب؟",
      very_weak: "ضعيف جداً",
      weak: "ضعيف",
      fear: "متوسط",
      good: "جيد",
      strong: "قوي",
      please_follow_on: "قائمة الحصص",
      profile_details: "تفاصيل الملف الشخصي",
      you_have_full: "لديك التحكم الكامل لإدارة إعدادات حسابك.",
      your_avatar: "صورتك الرمزية",
      no_bigger_than: "لا يزيد حجمها عن 800 بكسل عريضة وطويلة.",
      personal_details: "تفاصيل شخصية",
      edit_your_personal: "حرر معلوماتك الشخصية وعنوانك.",
      phone: "الهاتف",
      birthday: "عيد الميلاد",
      date_of_birthday: "تاريخ الميلاد",
      adress_line_1: "العنوان الأول",
      adress_line_2: "العنوان الثاني",
      country: "البلد",
      state: "الولاية",
      update_profil: "تحديث الملف الشخصي",
      update_btn: "تحديث",
      delete_btn: "حذف",
      edit_your_account: "حرر إعدادات حسابك وغيِّر كلمة المرور هنا.",
      email_adress: "عنوان البريد الإلكتروني",
      your_current_email: "عنوان بريدك الإلكتروني الحالي هو",
      new_email_adress: "عنوان البريد الإلكتروني الجديد",
      current_password: "كلمة المرور الحالية",
      update_details: "تحديث التفاصيل",
      change_password: "تغيير كلمة المرور",
      we_will_email:
        "سنرسل لك بريدًا إلكترونيًا تأكيديًا عند تغيير كلمة المرور الخاصة بك، لذا يرجى توقع هذا البريد بعد الإرسال.",
      new_password: "كلمة المرور الجديدة",
      confirm_new_password: "تأكيد كلمة المرور الجديدة",
      save_password: "حفظ كلمة المرور",
      cant_remenber_your: "لا تستطيع تذكر كلمة مرورك الحالية؟",
      reset_your_password: "إعادة تعيين كلمة المرور عبر البريد الإلكتروني",
      previous: "السابق",
      help_support: "تقديم تذكرة",
      faqV2: "الأسئلة الشائعة",
      help_center: "مركز المساعدة",
      my_result: "نتيجتي",
      accounts: "الحسابات",
      accounts_managment: "إدارة الحسابات",
      add_account: "إضافة حساب جديد",
      no_notification: " لا توجد إشعارات جديدة في الوقت الراهن.",
      notifications: "إشعارات",
      address_line_2: "خط عنوان 2",
      address_line_1: "خط عنوان 1",
      who_can_help: "كيف يمكننا مساعدتك؟",
      have_question: "هل لديك أسئلة؟ ابحث في مركز المساعدة لدينا",
      enter_a_question: "أدخل سؤالًا أو موضوعًا أو كلمة مفتاحية",
      or_choose_category:
        "... أو اختر فئة للعثور بسرعة على المساعدة التي تحتاجها",
      guide_resources: "الدلائل والموارد",
      faq_desc:
        "FAQ هو اختصار للأسئلة المتداولة بشكل متكرر، وهو قائمة بالأسئلة التي يتم طرحها والإجابات عليها بشكل شائع حول موضوع معين.",
      guide_resou_desc:
        "دلائل أنماط واجهة المستخدم هي أداة تصميم وتطوير تجلب التماسك إلى واجهة المستخدم وتجربة المستخدم لمنتج رقمي.",
      view_faq: "عرض الأسئلة الشائعة",
      browse_guide: "تصفح الدلائل",
      support_desc:
        "الخبر السار هو أنك لست وحدك، وأنك في المكان الصحيح. اتصل بنا للحصول على دعم مفصل.",
      submit_req: "تقديم طلب",
      submit_description:
        "من الابتكار إلى قلوب قرائنا الأعزاء، اعتمد على خبرتنا للحصول على دعم لا يتزعزع. أرسل تذكرة، وتأكد من أننا سنلبي احتياجاتك على الفور.",
      cefr: "كيف يتم تصنيف مستويات إلمام اللغة الإنجليزية؟",
      cefr_desc:
        "طريقتنا في تقييم مهارات اللغة الإنجليزية متماشية مع الإطار الأوروبي المشترك للمرجعية للغات (CEFR). وبناءً على ذلك، سيتناسب نتيجة التقييم الخاص بك مع أحد المستويات السبع المعترف بها على مستوى العالم. ",
      a0_title: "مبتدئ",
      a0_subtitle: "الأسس الأولية",
      a0_desc:
        "البداية في التعرف على العبارات والكلمات الأساسية باللغة الشفهية والمكتوبة، مع القدرة الدنيا على الرد أو الكتابة.",
      a1_title: "ابتدائي",
      a1_subtitle: "فهم أساسي والتواصل",
      a1_desc:
        "يمكنه فهم واستخدام التعابير اليومية المألوفة، المشاركة في محادثات بسيطة، كتابة عبارات أساسية، وفهم المواد المكتوبة البسيطة.",
      a2_title: "قبل المتوسط",
      a2_subtitle: "تطوير مهارات اللغة",
      a2_desc:
        "قادر على فهم المحادثات الروتينية، والتعبير عن الأفكار ببساطة شفهيًا وكتابيًا، وفهم النقاط الرئيسية في نصوص قصيرة وواضحة.",
      b1_title: "متوسط",
      b1_subtitle: "توسيع إتقان اللغة",
      b1_desc:
        "قادر على التعامل مع حالات اللغة المتعلقة بالسفر، ووصف التجارب والأحداث، وكتابة نصوص بسيطة عن مواضيع مألوفة، وفهم النقاط الرئيسية للمدخلات القياسية.",
      b2_title: "فوق المتوسط",
      b2_subtitle: "استخدام متقدم للغة",
      b2_desc:
        "يمكنه التفاعل مع الناطقين الأصليين ببعض الطلاقة، وإنتاج نصوص مفصلة، وفهم الأفكار الرئيسية للنصوص المعقدة، والتعبير بوضوح عن مجموعة واسعة من المواضيع.",
      c1_title: "متقدم",
      c1_subtitle: "إتقان عالي في استخدام اللغة",
      c1_desc:
        "قادر على إنتاج نصوص جيدة التنظيم ومفصلة، وفهم المواد الطويلة والمعقدة، والتواصل بطلاقة وبشكل فوري دون البحث واضح عن التعابير.",
      c2_title: "إتقان",
      c2_subtitle: "إتقان قريب من اللغة الأم",
      c2_desc:
        "تظهر القدرة على فهم ما يقرب من كل ما يُسمع أو يُقرأ، وتلخيص المعلومات من مصادر مختلفة شفهيةً ومكتوبة، والتعبير عن الذات بطريقة فورية وبشكل سلس ودقيق للغاية، مميزة بين تداول فاصل للمعاني حتى في السياقات الأكثر تعقيدًا",
      account_setup_reminder: "تذكير بإعداد الحساب",
      account_setup_reminder: "تذكير بإعداد الحساب",
      take_charge_of:
        "تولي التحكم في إعدادات حسابك بسيطرة. ارتق بتجربتك من خلال خيارات إدارة سلسة وشخصية.",
      change_email: "تغيير البريد الإلكتروني +",
      change_passewo: "تغيير كلمة المرور +",
      view_chapter: "عرض  الملف الشخصي +",
      dive_into_the: "انغمس في جو iRead!",
      recent_posts: "المشاركات الأخيرة:",
      enjoy_learn_more: "استمتع، تعلم المزيد: اعتنق قوة iRead.",
      hello_to_all:
        "مرحبًا بالجميع! أنا أمين لمين، مؤسس iRead. أنا سعيد جدًا بترحيب كل واحد منكم في عائلتنا الكبيرة...",
      the_founder_of: "مؤسس iRead",
      january_18: "18 يناير 2024",
      amine_lamine: "أمين لمين",
      the_magic_of_reading: "سحر القراءة: لماذا الكتب هي أفضل أصدقائك",
      today_want_share:
        "اليوم، أريد مشاركة شيء خاص جدًا معكم - سحر القراءة. أنا أمين لمين، مؤسس iRead...",
      my_dashbor: "لوحة القيادة الخاصة بي",
      more_details: "المزيد من التفاصيل",
      continue_quiz: " استمر في حل الاختبار",
      my_invoices: "فواتيري",
    },
  },
};
i18n.use(initReactI18next).init({
  resources,
  lng: defaultLanguage,
  fallbackLng: "en", // fallback language
  interpolation: {
    escapeValue: false, // React already escapes values
  },
});

export default i18n;
