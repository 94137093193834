// import node module libraries
import { Fragment } from "react";
// import { CourseFAQs } from "data/marketing/CourseIndexData";

const FAQTab = ({ faq }) => {
  return (
    <Fragment>
      <h3 className="mb-3">Pack - Frequently Asked Questions</h3>
      {faq?.map((item, index) => (
        <div className="mb-4" key={index}>
          <h4>{item.title}</h4>
          <p>{item.desc}</p>
        </div>
      ))}
    </Fragment>
  );
};
export default FAQTab;
