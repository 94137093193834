import {
  CLEAR_SELECTED,
  GET_ALL_PAKCS,
  GET_BOOKS_IN_PACK,
  GET_PACK_DETAILS,
  GET_SESSION,
  SELECT_PACK,
} from "../actions/constants";

const packsReducer = (
  state = { packs: [], pack: {}, books: [], selected: null, session:{} },
  action
) => {
  switch (action.type) {
    case GET_ALL_PAKCS:
      return { ...state, packs: action?.payload.packs };
    case GET_PACK_DETAILS:
      return { ...state, pack: action?.payload };
    case GET_BOOKS_IN_PACK:
      return { ...state, books: action?.payload.books_in_pack };
    case SELECT_PACK:
      return { ...state, selected: action?.payload };
    case CLEAR_SELECTED:
      return { ...state, selected: null };
      case GET_SESSION:
      return { ...state, session: action?.payload };
    default:
      return state;
  }
};

export default packsReducer;
