// import node module libraries
import React, { Fragment } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

// import custom components
import NavbarDefault from "layouts/marketing/navbars/NavbarDefault";
import FooterWithLinks from "layouts/marketing/footers/FooterWithLinks";

function TermsAndConditions() {
  return (
    <Fragment>
      <main>
        {/* pageheader */}
        <section className="py-10 bg-white">
          <Container>
            <Row className="justify-content-center">
              <Col lg={8} md={12} sm={12}>
                {/* text center */}
                <div className="text-center">
                  <h1 className="display-3 fw-bold">Terms of Service:</h1>
                  <p className="lead px-8">
                    Welcome to IRead . By accessing or using the Service, you
                    agree to be bound by these Terms of Service:
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* content */}
        <section className="pb-10 bg-white">
          <Container>
            <Row className="justify-content-center">
              <Col lg={8} md={12} sm={12}>
                {/* date */}
                <div className="py-3 mb-5 border-bottom">
                  <h3>
                    Effective date:{" "}
                    <span className="text-primary">23rd of March, 2023</span>
                  </h3>
                </div>
                {/* heading */}
                <h1 className="fw-bold mb-3">Acceptance of Terms:</h1>
                <p className="fs-4 mb-4">
                  By using the Service, you acknowledge that you have read,
                  understood, and agree to these Terms of Service.
                </p>

                {/* <p className="mb-1 fs-4">
									<span className="text-dark fw-semi-bold">Email: </span>
									hello@geeksui.com
								</p>

								<p className="mb-1 fs-4">
									<span className="text-dark fw-semi-bold">Address: </span>52,
									Komal Villas, Mansarovar Vadodara - 374321
								</p> */}

                {/* para */}

                {/* para */}
                <div className="mt-5">
                  <h2 className="fw-bold">Content Usage:</h2>
                  <p className="fs-4">
                    The stories, articles, and materials provided by the Service
                    are for educational and entertainment purposes only. You may
                    not reproduce, distribute, modify, or create derivative
                    works based on the content without explicit permission.
                  </p>
                  <h2 className="fw-bold">Use of the Service:</h2>
                  <p className="fs-4">
                    The Service is intended for personal, non-commercial use.
                    You agree not to use the Service for any illegal,
                    unauthorized, or prohibited purposes.
                  </p>
                  <h2 className="fw-bold">User Conduct:</h2>
                  <p className="fs-4">
                    You agree to use the Service responsibly and adhere to all
                    applicable laws and regulations. You must not engage in
                    disruptive or harmful behavior while using the Service.
                  </p>
                  {/* list */}
                </div>
                {/* para */}
                <div className="mt-5">
                  <h2 className="fw-bold">Intellectual Property:</h2>
                  <p className="fs-4">
                    All content, trademarks, logos, and intellectual property
                    associated with the Service are the property of IRead and
                    its licensors. You may not use or reproduce them without
                    permission.
                  </p>

                  <h2 className="fw-bold">Disclaimer of Warranties:</h2>
                  <p className="fs-4">
                    The Service is provided "as is" without warranties of any
                    kind. IRead does not guarantee the accuracy, completeness,
                    or reliability of the content.
                  </p>
                  <h2 className="fw-bold"> Limitation of Liability:</h2>
                  <p className="fs-4">
                    IRead shall not be liable for any damages, losses, or
                    liabilities arising out of your use of the Service.
                  </p>
                  <h2 className="fw-bold"> Termination:</h2>
                  <p className="fs-4">
                    IRead reserves the right to terminate or suspend your access
                    to the Service at its discretion.
                  </p>

                  <h2 className="fw-bold"> Changes to Terms:</h2>
                  <p className="fs-4">
                    IRead reserves the right to update or modify these Terms of
                    Service at any time. Continued use of the Service
                    constitutes acceptance of the updated terms.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </Fragment>
  );
}

export default TermsAndConditions;
