// import node module libraries
import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import BookLayout from "./BookLayout";

import BookIntro from "../landings/course-lead/BookIntro";

const Dashboard = () => {
  return (
    <BookLayout>
      <Container>
        <BookIntro/>
      </Container>
    </BookLayout>
  );
};
export default Dashboard;
