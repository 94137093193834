import { settings } from "AppConfig";

const initialState = {
  version: settings.app.version,
  skin: settings.theme.skin,
};

const AppConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_SKIN":
      return {
        ...state,
        skin: action.payload.skin,
      };
    case "REFRESH":
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default AppConfigReducer;
