// import node module libraries
import React, { Fragment, useEffect, useState } from 'react';
import { Card, Table, Badge, Container } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';

// import data files
import InvoiceData from 'data/marketing/InvoiceData';

// import profile layout wrapper
import ProfileLayout from 'components/marketing/student/ProfileLayout';
import { BookwithResultAction, getResultInSessionAction } from 'actions/quizs';
import { useDispatch } from 'react-redux';
import BookLayout from '../instructor/BookLayout';
import { useSelector } from 'react-redux';

const ResultDetails = () => {
	const dispatch = useDispatch();
	const [filteredItemData, setFilteredItemData] = useState(null);
	const { id } = useParams();
	const { resultsForOneReader } = useSelector((state) => state.quizs);
	const { user } = useSelector((state) => state.auth);
	const user_id = user?.quiz_id;
	useEffect(() => {
		dispatch(
			BookwithResultAction(user_id)
		);
	}, [dispatch, user_id]);
	useEffect(() => {
		const filteredData = resultsForOneReader?.filter((item) => item?.book_id === parseInt(id, 10));
		if (filteredData?.length > 0) {
			setFilteredItemData(filteredData[0]);
		}
	}, [resultsForOneReader, id]);
	return (
		<BookLayout>
			<Card className="border-0">
				<Card.Header>
					<div className="mb-3 mb-lg-0">
						<Table responsive className="table-centered">
							<tbody>
								<tr>
									<td>
										<div className="d-flex align-items-center">
											<Link to="">
												{' '}
												<img
													src={filteredItemData?.book_img}
													alt=""
													className="rounded img-4by3-lg"
												/>
											</Link>
											<div className="ms-3">
												<h4 className="mb-2">
													<Link
														to=""
														className="text-inherit"
													>
														{filteredItemData?.book_name}
													</Link>
												</h4>

												{filteredItemData?.score !== 0 &&
													filteredItemData?.max_score !== 0 &&
													filteredItemData?.percentage !== 0 &&
													filteredItemData?.score != null &&
													filteredItemData?.max_score != null &&
													filteredItemData?.percentage != null && (
														<div>
															<span>
																<span className="me-2 align-middle">
																	<i className="fe fe-user"></i>
																</span>
																Total Score :{" "}
																<span className="text-primary">
																	{filteredItemData?.score}/
																	<span style={{ color: "#64788B" }}>{filteredItemData?.max_score}</span>,{" "}
																</span>
															</span>
															<span className="ms-2">
																<span className="me-2 align-middle">
																	<i className="fe fe-pie-chart"></i>
																</span>
																Total Percentage :{" "}
																<span className="text-primary">{filteredItemData?.percentage} %</span>
															</span>
														</div>
													)}
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</Table>
					</div>
				</Card.Header>
				<Card.Body className="p-0">
					{/* Table */}
					<div className="table-invoice border-0">
						<Table
							hover
							responsive
							className="mb-0 text-nowrap table-centered "
						>
							<thead className="table-light">
								<tr>
									<th scope="col">Quiz Title</th>
									<th scope="col">Score</th>
									<th scope="col">Max Score</th>
									<th scope="col">Percentage</th>
									<th scope="col">Success</th>
								</tr>
							</thead>
							<tbody>
								{filteredItemData?.quizData?.map((item, index) => (
									<tr key={index}>
										<td>{item?.quiz_title}</td>
										<td>{item?.score}</td>
										<td>{item?.max_score}</td>
										<td>{item?.percentage} %</td>
										<td>
											<Badge bg={item?.success ? 'success' : 'danger'}>
												{item?.success ? 'Yes' : 'No'}
											</Badge>
										</td>
									</tr>
								))}
							</tbody>
						</Table>
					</div>
				</Card.Body>
			</Card>
		</BookLayout>
	);
};

export default ResultDetails;
