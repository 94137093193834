import { GET_INVOICE_BY_CLIENT, GET_INVOICE_NUMBER, GET_ONE_INVOICE } from "../actions/constants";
  
  const invoicesReducer = (
    state = { invoices: [], invoice: {}, invoiceNumber:{} },
    action
  ) => {
    switch (action.type) {
      case GET_INVOICE_BY_CLIENT:
        return { ...state, invoices: action?.payload};
      case GET_ONE_INVOICE:
        return { ...state, invoice: action?.payload };
        case GET_INVOICE_NUMBER:
          return { ...state, invoiceNumber: action?.payload };
      default:
        return state;
    }
  };
  
  export default invoicesReducer;
  