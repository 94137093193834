// import node module libraries
import { useEffect, useState } from 'react';
import { Form, Link, useLocation } from 'react-router-dom';

import { Card, Image, Modal, Button, ListGroup, Badge, Row, Col, FormSelect, Spinner } from 'react-bootstrap';


// Import required data
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changeImageActions, createAccountAction, getAccountsAction, getProfileActions, setProfileActions } from 'actions/auth';
import CloudinaryUploadWidget from 'components/marketing/account-settings/CloudinaryUploadWidget';
import { FlatPickr } from 'components/elements/flat-pickr/FlatPickr';

const CourseDescriptionCard = ({ item }) => {

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const {
		user,
		loading,
		profile: userProfile,
		accounts
	} = useSelector((state) => state.auth);
	const [image, setImage] = useState(user?.img);
	const [newAccount, setNewAccont] = useState({
		username: "",
		password: ""
	})
	const [profile, setProfile] = useState({
		address_1: "",
		address_2: "",
		birth_day: "",
		country: "",
		first_name: "",
		last_name: "",
		phone: "",
		state: "",
	});
	useEffect(() => {
		if (user) {
			dispatch(getAccountsAction())
		}
	}, [dispatch, user])


	const handleCreateAccount = (e) => {
		e.preventDefault();
		dispatch(createAccountAction(newAccount, setNewAccont))
	}
	const pathInfo = useLocation();
	const account = pathInfo.pathname.substring(21, 11);
	const countrylist = [
		{ value: "empty", label: "Select country" },
		{ value: "Tunisia", label: "Tunisia" },
	];

	const statelist = [
		{ value: "Gabes", label: "Gabes" },
		{ value: "Sfax", label: "Sfax" },
		{ value: "Tunis", label: "Tunis" },
	];
	useEffect(() => {
		if (user) {
			setImage(user.img);
			dispatch(getProfileActions());
		}
	}, [user]);

	useEffect(() => {
		if (userProfile?.user_id)
			setProfile({
				address_1: userProfile.address_1,
				address_2: userProfile.address_2,
				birth_day: userProfile.birth_day,
				country: userProfile.country,
				first_name: userProfile.first_name,
				last_name: userProfile.last_name,
				phone: userProfile.phone,
				state: userProfile.state,
			});
	}, [userProfile]);

	const handleChangeProfile = (e) => {
		e.preventDefault();
		const newDate = new Date(profile.birth_day);
		newDate.setDate(newDate.getDate() + 1);
		dispatch(setProfileActions({ ...profile, birth_day: newDate }));
		dispatch(changeImageActions({ img: image }));
	};
	const [modalShow, setModalShow] = useState(false);

	return (
		<Card className="mb-4">

			{/*  Card body  */}
			<Card.Body className="p-6">
				<div className="d-md-flex mb-4">
					<div className="mb-3 mb-md-0">
						<Image
							src={item.icon}
							alt=""
							className=" bg-primary icon-shape icon-xxl rounded-circle"
						/>
					</div>
					{/*  Content  */}
					<div className="ms-md-4">
						<h2 className="fw-bold mb-1">
							{t(item.title)}
							{/* <Badge bg="warning" className="ms-2">
								{item.badge}
							</Badge> */}
						</h2>
					</div>
				</div>
				<p className="mb-4 fs-4">{t(item.shortdescription)}</p>
				<Button
					style={{ color:"#0EA5E9"}}
					href="#"
					bsPrefix="btn-link"
					onClick={() => setModalShow(true)}
				>
					<u>{t("view_chapter")} </u>
				</Button>

				<Modal
					show={modalShow}
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
					onHide={() => setModalShow(false)}
				>
					<Modal.Header closeButton>
						<Modal.Title id="contained-modal-title-vcenter">
							<div className="d-md-flex my-2">
								<div className="mb-3 mb-md-0">
									<Image
										src={item.icon}
										alt=""
										className=" bg-primary icon-shape icon-xxl rounded-circle"
									/>
								</div>
								{/*  Content  */}
								<div className="ms-md-4">
									<h2 className="fw-bold mb-1">
										{t(item.title)}
										{/* <Badge bg="warning" className="ms-2">
										{item.badge}
									</Badge> */}
									</h2>
									<p className="text-uppercase fs-6 fw-semi-bold mb-0">
										<span className="text-dark">{t(item.shortdescription)}</span>{' '}
										{/* <span className="ms-3">{item.lessons} Lessons</span>{' '}
									<span className="ms-3">{item.duration}</span> */}
									</p>
								</div>
							</div>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="d-lg-flex align-items-center justify-content-between">
							<div className="d-flex align-items-center mb-4 mb-lg-0">
								<Image
									src={image}
									id="img-uploaded"
									className="avatar-xl rounded-circle"
									alt=""
								/>
								<div className="ms-3">
									<h4 className="mb-0">{t("your_avatar")}</h4>
									<p className="mb-0">PNG or JPG {t("no_bigger_than")}</p>
								</div>
							</div>

							<CloudinaryUploadWidget setImage={setImage} />
						</div>

						<hr className="my-5" />

						<div>
							<h4 className="mb-0">{t("personal_details")}</h4>
							<p className="mb-4">{t("edit_your_personal")}</p>

							{/* Form */}

							<Row>
								{/* First name */}
								<Col md={6} sm={12} className="mb-3">
									<div className="mb-3">
										<label htmlFor="firstName" className="form-label">{t("first_name")}</label>
										<input
											type="text"
											className="form-control"
											id="firstName"
											placeholder={t("first_name")}
											required
											onChange={(e) => setProfile({ ...profile, first_name: e.target.value })}
											value={profile.first_name}
										/>
									</div>
								</Col>

								{/* Last name */}
								<Col md={6} sm={12} className="mb-3">
									<div className="mb-3">
										<label htmlFor="lastName" className="form-label">{t("last_name")}</label>
										<input
											type="text"
											className="form-control"
											id="lastName"
											placeholder={t("last_name")}
											required
											onChange={(e) => setProfile({ ...profile, last_name: e.target.value })}
											value={profile.last_name}
										/>
									</div>
								</Col>

								{/* Phone */}
								<Col md={6} sm={12} className="mb-3">
									<div className="mb-3">
										<label htmlFor="phone" className="form-label">{t("phone")}</label>
										<input
											type="text"
											className="form-control"
											id="phone"
											placeholder={t("phone")}
											required
											onChange={(e) => setProfile({ ...profile, phone: e.target.value })}
											value={profile.phone}
										/>
									</div>
								</Col>

								{/* Birthday */}
								<Col md={6} sm={12} className="mb-3">
									<div className="mb-3">
										<label htmlFor="birthday" className="form-label">{t("birthday")}</label>
										<input
											type="text"
											className="form-control"
											id="birthday"
											placeholder={t("date_of_birthday")}
											required
											onChange={(e) => setProfile({ ...profile, birth_day: e.target.value })}
											value={profile.birth_day}
										/>
									</div>
								</Col>

								{/* Address Line 1 */}
								<Col md={6} sm={12} className="mb-3">
									<div className="mb-3">
										<label htmlFor="address1" className="form-label">{t("address_line_1")}</label>
										<input
											type="text"
											className="form-control"
											id="address1"
											placeholder={t("address_line_1")}
											required
											onChange={(e) => setProfile({ ...profile, address_1: e.target.value })}
											value={profile.address_1}
										/>
									</div>
								</Col>

								{/* Address Line 2 */}
								<Col md={6} sm={12} className="mb-3">
									<div className="mb-3">
										<label htmlFor="address2" className="form-label">{t("address_line_2")}</label>
										<input
											type="text"
											className="form-control"
											id="address2"
											placeholder={t("address_line_2")}
											required
											onChange={(e) => setProfile({ ...profile, address_2: e.target.value })}
											value={profile.address_2}
										/>
									</div>
								</Col>

								{/* Country */}
								<Col md={6} sm={12} className="mb-3">
									<div className="mb-3">
										<label htmlFor="country" className="form-label">{t("country")}</label>
										<select
											className="form-control"
											id="country"
											onChange={(e) => setProfile({ ...profile, country: e.target.value })}
											value={profile.country}
										>
											{countrylist.map((country) => (
												<option key={country.value} value={country.value}>
													{country.label}
												</option>
											))}
										</select>
									</div>
								</Col>

								{/* State */}
								<Col md={6} sm={12} className="mb-3">
									<div className="mb-3">
										<label htmlFor="state" className="form-label">{t("state")}</label>
										<select
											className="form-control"
											id="state"
											onChange={(e) => setProfile({ ...profile, state: e.target.value })}
											value={profile.state}
										>
											{statelist.map((state) => (
												<option key={state.value} value={state.value}>
													{state.label}
												</option>
											))}
										</select>
									</div>
								</Col>

								{/* Button */}
								<Col sm={12} md={12}>
									<button
										className="btn btn-primary"
										type="submit"
										onClick={(e) => handleChangeProfile(e)}
										disabled={loading}
									>
										{loading ? (
											<Spinner animation="border" variant="light" className="me-2" size="sm" />
										) : (
											t("update_profil")
										)}
									</button>
								</Col>
							</Row>


						</div>
					</Modal.Body>

				</Modal>
			</Card.Body>
		</Card>
	);
};



export default CourseDescriptionCard;
