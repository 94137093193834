// import node module libraries
import { Container } from "react-bootstrap";

import CalendarBook from "components/dashboard/calendar/CalendarBook";

const CourseDescriptionSection = ({ sessions, pastSessions }) => {
  return (
    <section className="py-8 py-lg-6 bg-gray-200">
      <Container>
        <CalendarBook sessions={sessions} pastSessions={pastSessions} />
      </Container>
    </section>
  );
};
export default CourseDescriptionSection;
