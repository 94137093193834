// import node module libraries
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination
} from 'react-table';
import { Link, useNavigate } from 'react-router-dom';
import {
	Image,
	Row,
	Col,
	Table,
	Button,
	Modal,
    Card,
    Spinner
} from 'react-bootstrap';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import ProfileLayout from './ProfileLayout';
import { fetchOneBook } from 'actions/books';
import { unFollowedBookAction } from 'actions/auth';
import { followSession } from 'actions/sessions';

const SessionList = () => {
	const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [selectedSession, setSelectedSession] = useState(null);
    const handleCloseEventOffcanvas = () => setShowModal(false);
    const { book } = useSelector((state) => state?.books);
    const { is_authenticated, unflollwedBooks,loading } = useSelector(
        (state) => state.auth
      );
  useEffect(() => {
    if (is_authenticated) dispatch(unFollowedBookAction());
  }, [dispatch, is_authenticated]);

//   useEffect(() => {
//     if (unflollwedBooks) {
//       if (unflollwedBooks.length > 0) {
//         if (unflollwedBooks[0].book_id !== undefined)
//           dispatch(fetchOneBook(unflollwedBooks[0]?.book_id)); 
//       }
//     }
//   }, [dispatch]);
  useEffect(() => {
          dispatch(fetchOneBook(34)); 
  }, [dispatch]);

const booke = [
    {
      "title": "Événement A",
      "sessions": [
        {
          "title": "Session A1",
          "start": "2023-02-01",
          "end":"2023-02-02"
        },
        {
          "title": "Session A2",
          "start": "2023-02-02",
          "end":"2023-02-02"
        },
        {
            "title": "Session B1",
            "start": "2023-03-01",
            "end":"2023-02-02"
          },
      ]
    },
   
  ];
  const allSessions = booke.flatMap(e => e.sessions);


const handleFollowSession = (selectedSession) => {

    dispatch(followSession(selectedSession, navigate, handleCloseEventOffcanvas));
  };

	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
			className="btn-icon btn btn-ghost btn-sm rounded-circle"
		>
			{children}
		</Link>
	));



	const columns = useMemo(
		() => [
			{
				accessor: 'title',
				Header: 'Session Title',
				Cell: ({ value, row }) => {
                    const handleCellClick = () => {
                        setSelectedSession(row.original)
                        setShowModal(true);
                      };
					return (
						<div className="d-flex align-items-center">
							<h5 className="mb-0"  onClick={handleCellClick} >{value}</h5>
						</div>
					);
				}
			},
			{  
				accessor: 'start',
				Header: 'Start Date',
				Cell: ({ value }) => {
					return value;
				}
			},
            {  
				accessor: 'end',
				Header: 'End Date',
				Cell: ({ value }) => {
					return value;
				}
			},
		],
		[]
	);
	const data = useMemo(() => allSessions, []);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		gotoPage,
		pageCount,
		prepareRow,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 10,
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useFilters,
		useGlobalFilter,
		usePagination
	);

	const { pageIndex, globalFilter } = state;

	return (
        <ProfileLayout>
		      <Card className="border-0">
        <Card.Header>
          <div className="mb-3 mb-lg-0">
            <h3 className="mb-0">My Sessions</h3>
            <p className="mb-0">My available sessions list .</p>
          </div>
        </Card.Header>
        {book.length === 0 ? (
            <div className="text-center mt-3" style={{ fontSize: '1.2rem', color: '#555' }}>
              <p>There are currently no sessions available for your story.</p>
              </div>
          ) : (
            <>
        <Card.Body>
          <Row>
            <Col lg={9} md={7} sm={12} className="mb-lg-0 mb-2">
              <GlobalFilter
                filter={globalFilter}
                setFilter={setGlobalFilter}
                placeholder="Search Your Session"
              />
            </Col>
            {/* <Col lg={3} md={5} sm={12}>
              <FormSelect options={sortby} placeholder="Sort by" />
            </Col> */}
          </Row>
        </Card.Body>
        <Card.Body className="p-0 pb-5">
          
    
          <Row>
            <Col lg={12} md={12} sm={12}>
              <Table
                hover
                responsive
                {...getTableProps()}
                className="text-nowrap table-centered"
              >
                <thead className="table-light">
                  {headerGroups?.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup?.headers?.map((column) => (
                        <th {...column?.getHeaderProps()}>
                          {column?.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page?.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row?.getRowProps()}>
                        {row?.cells?.map((cell) => {
                          return (
                            <td {...cell?.getCellProps()}>
                              {cell?.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
			<Modal show={showModal} onHide={() => setShowModal(false)} centered>
				<Modal.Header closeButton>
					<Modal.Title>Follow Session</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				<h5>Are you certain you wish to register for this session?</h5>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShowModal(false)}>
						Cancel
					</Button>
					<Button variant= "success" onClick={() => handleFollowSession(selectedSession)}>
                    {loading ? (
              <Spinner
                animation="border"
                variant="light"
                className="me-2"
                size="sm"
              />
            ) : (
              " Confirm"
            )}
					</Button>
				</Modal.Footer>
			</Modal>
			{/* Pagination @ Footer */}
			<Pagination
				previousPage={previousPage}
				pageCount={pageCount}
				pageIndex={pageIndex}
				gotoPage={gotoPage}
				nextPage={nextPage}
			/>
		     
             </Card.Body>
        </>
          )}
      </Card>
        </ProfileLayout>
	);
};

export default SessionList;
